/**
 * @author Filippos A. Grapsas <fgrapsas@dga.gr>
 *
 * Created at 19/03/2022
 */

interface String {
  isEmpty: () => boolean;
}

String.prototype.isEmpty = function (): boolean {
  return this === '';
}
