<div
  class="app-container"
  fxFlex 
  fxLayout="column" 
  fxLayoutAlign="start stretch"
>

  <!-- <div class="sidebar-container" *ngIf="withSidenav" fxFlexOrder="1" fxFlex="0 0 14.375rem" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <app-sidebar></app-sidebar>
  </div> -->

  <!-- <div
    class="off-sidebar-container"
    fxFlexOrder="2"
    fxFlex
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >

    <div class="header-container" *ngIf="withToolbar" fxFlexOrder="1" fxFlex="0 0 3.656rem" fxLayout="column" fxLayoutAlign="start stretch">
      <app-header></app-header>
    </div>

    <div class="component-container" fxFlexOrder="2" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
      <router-outlet></router-outlet>
    </div>

  </div> -->

  <router-outlet></router-outlet>

  <!-- <app-website-widget></app-website-widget> -->

</div>