/**
 * @author Filippos A. Grapsas <fgrapsas@dga.gr>
 *
 * Created at 12/03/2022
 */

// Angular
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
// Third party
import { tap } from 'rxjs/operators';
// App lib
import { Logger } from './../../lib/logger/logger';
// App core
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutInterceptorService implements HttpInterceptor{
  private readonly log: Logger = Logger.make(
    '/core/login/AutoLogoutInterceptorService'
  );

  public constructor(
    private router: Router,
    private loginService: LoginService,
  ) {
    this.log.sub('constructor()').debug();
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    const log: Logger = this.log.sub('intercept()').debug({request});

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event.type === HttpEventType.Response) {
        log.sub('response').debug({event});
        return;
      }
    }, (response: HttpErrorResponse) => {
      const sublog: Logger = log.sub('rejected').debug({response});

      if (response.status === 401) {
        sublog.debug('Autologout');
        this.loginService.logout();
        this.router.navigate(['/login']);
      }
    }));
  }
}
