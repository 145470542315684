/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/03/08
 */

export class WebsiteWidgetModel {
  public from: number;
  public to: number;
  public date: string;
  public time: string;
  public passengers: number;
  public luggages: number;

  public passport?: string;
  public name: string;
  public surname: string;
  public email: string;
  public mobile: string;
  public childSeats?: number;
  public special_instructions?: string;

  public advance_payment_method: string;

  // public constructor() {
  //   this.id = -1;
  //   this.branch_id = -1;
  //   this.name = '';
  //   this.licence_plate = '';
  //   this.passengers = 0;
  //   this.available = 0;
  // }
}

export class PostTransferRequestBody {
  public from: number;
  public to: number;
  public date: string;
  public time: string;
  public passengers: number;
  public luggages: number;
  public passport?: string;
  public name: string;
  public surname: string;
  public email: string;
  public mobile: string;
  public childSeats?: number;
  public special_instructions?: string;
  public advance_payment_method: string;

  // public constructor() {
  //   this.name = '';
  //   this.licence_plate = '';
  //   this.passengers = 0;
  //   this.availability = false;
  // }
}

export class GetCostCalculationData {
  public from_latitude: number;
  public from_longitude: number;
  public to_latitude: number;
  public to_longitude: number;
  public passengers: number;
  public luggages: number;
  public datetime: string;
}