<mat-form-field class="full-width mat-form-field-black-border" appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput type="text">
</mat-form-field>

<mat-form-field class="full-width mat-form-field-black-border" appearance="outline">
    <mat-label>Surname</mat-label>
    <input matInput type="text">
</mat-form-field>


<mat-form-field class="full-width mat-form-field-black-border" appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput type="email">
</mat-form-field>


<mat-form-field class="full-width mat-form-field-black-border" appearance="outline">
    <mat-label>Phone</mat-label>
    <input matInput type="tel">
</mat-form-field>