/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/01/20
 */

// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Third party
import { plainToInstance, instanceToInstance } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
// App core
import { Company, Branch, UserInfo } from './user-info.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private serviceName: string = `LoginService`;
  private userInfo: UserInfo | null = null;

  public constructor(private http: HttpClient) {
    const functionName: string = `constructor`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/`);
  }

  public getDeviceTokenRequest(data: {email: string, password: string}): Observable<any> {
    const functionName: string = `getDeviceTokenRequest`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ data`, data);

    return this.http.get<any>(
      `${environment.apiUrl}/api/v0/widget-user/device-token?name=angular`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'Application/json',
          'Authorization': 'Basic ' + btoa(`${data.email}:${data.password}`),
        }),
      }
    );
  }

  public hasAuth(): boolean {
    const functionName: string = `hasAuth`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/`);

    return this.getDeviceToken() ? true : false;
  }

  public setDeviceToken(token: string): void {
    const functionName: string = `setDeviceToken`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ token`, token);

    console.log('### 3')
    localStorage.setItem('token', token);
    console.log('### 4')
  }

  public getAuthHeader(): string {
    const token: string | null = this.getDeviceToken();
    const header: string = `Bearer ${token}`;

    console.log('### 5')

    return header;
  }

  public getUserInfoRequest(): Observable<UserInfo> {
    const functionName: string = `getUserInfoRequest`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/`);

    return this.http.get<any>(
      `${environment.apiUrl}/api/v0/staff/web/user/info`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'Application/json',
          'Authorization': this.getAuthHeader(),
        }),
      }
    ).pipe(map((response: any) => {
      console.log(`${logPath}/ http - resolved - response`, response);

      return response;
    }), map((response: any) => {
      console.log(`${logPath}/ http - resolved - prepare instance`, response);

      const userInfo: UserInfo = plainToInstance(UserInfo, response.info, {
         excludeExtraneousValues: true,
       });
      console.log(`${logPath}/ userInfo`, userInfo);

      return userInfo;
    }), map((userInfo: UserInfo) => {
      console.log(`${logPath}/ @here - http - resolved - fill companyId`, userInfo);

      userInfo.companies.forEach((company: Company) => {
        company.branches.forEach((branch: Branch) => {
          branch.companyId = company.id;
        });
      });

      console.log(`${logPath}/ userInfo`, userInfo);

      return userInfo;
    })
      // ,map ((userInfo: UserInfo) => {
      //   // const log2: Logger = log.sub('http - resolved - permissionsByName')
      //   //   .debug({userInfo: userInfo})
      //   // ;

      //   // userInfo.permissionsByName = {};
      //   // userInfo.permissions?.forEach((permission: Permission) => {
      //   //   userInfo.permissionsByName[permission.name] = true;
      //   // });
      //   // userInfo.rolesByName = {};
      //   // userInfo.roles?.forEach((role: Role) => {
      //   //   userInfo.rolesByName[role.name] = true;
      //   // });

      //   // log2.debug({userInfo});
      //   return userInfo;
      // })
    );
  }

  public getUserInfo(): UserInfo | null {
    const functionName: string = `getUserInfo`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ userInfo`, this.userInfo);

    return JSON.parse(JSON.stringify(this.userInfo));
  }

  public setUserInfo(userInfo: UserInfo | null): void {
    const functionName: string = `setUserInfo`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ userInfo`, userInfo);

    this.userInfo = JSON.parse(JSON.stringify(userInfo));
  }

  public logout(): void {
    const functionName: string = `logout`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/`);

    localStorage.clear();
    sessionStorage.clear();
    this.setUserInfo(null);
  }

  private getDeviceToken(): string | null {
    const functionName: string = `getDeviceToken`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/`);

    return localStorage.getItem('token');
  }
}
