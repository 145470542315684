/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/01/23
 */

// Angular
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
// App
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from './../../material.module';
import { LoginDialogComponent } from './login-dialogs/login-dialog.component';
import { LoadingModule } from "../../core/loading/loading.module";
// App pages
import { LoginComponent } from './login.component';

@NgModule({
  declarations: [
    LoginComponent,
    LoginDialogComponent
  ],
  imports: [
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    MatDialogModule,
    LoadingModule
  ],
  providers: [],
  exports: [],
})
export class LoginModule { }