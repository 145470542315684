export const environment = {
  production: true,
  apiUrl: 'https://booking.dev.dgac.gr/',
  sentry: {
    enabled: true,
    dsn: 'https://c02a131e906545c5a04a21251c2d521b@o66098.ingest.sentry.io/6179941',
  },
  pusher: {
    // stage
    key: `1566871478fab3bdc565`
  },
  firebase: {
    apiKey: "AIzaSyDhfobJGNS2-qm2snDErunWPhOvgjW53n8",
    authDomain: "rnbs-apps.firebaseapp.com",
    projectId: "rnbs-apps",
    storageBucket: "rnbs-apps.appspot.com",
    messagingSenderId: "305460764995",
    appId: "1:305460764995:web:4e7ee3f96f7437936f4ff6"
  },
  // firebase: {
  //   apiKey: "AIzaSyB1wxkcAejlGZ4oDEHh05ZIR-TqsfCRe9s",
  //   authDomain: "harmonia-app-25903.firebaseapp.com",
  //   projectId: "harmonia-app-25903",
  //   storageBucket: "harmonia-app-25903.appspot.com",
  //   messagingSenderId: "629647002203",
  //   appId: "1:629647002203:web:c95fd7456038a4e5c40d7e"
  // },
  // firebase: {
  //   apiKey: "AIzaSyC8OQpkVRDiWt-V2dV_Ncq_FAFbQp6tFLQ",
  //   authDomain: "harmonia-demo.firebaseapp.com",
  //   projectId: "harmonia-demo",
  //   storageBucket: "harmonia-demo.appspot.com",
  //   messagingSenderId: "121788558456",
  //   appId: "1:121788558456:web:4a17a14afaf948a371918d"
  // }
};
