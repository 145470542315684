/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2022/12/08
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireMessaging } from 'angularfire2/messaging';
import * as moment from 'moment';
import 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs'
import { AudioService } from './audio.service';
import { environment as env } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { NotificationRedirectService } from './notification-redirect.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  private serviceName: string = `MessagingService`;
  currentMessage = new BehaviorSubject(null);
  firebaseTokenSubject = new Subject<string>();

  constructor(
    private angularFireMessaging: AngularFireMessaging, 
    private audioService: AudioService,
    private httpClient: HttpClient,
    private loginService: LoginService,
    private router: Router,
    private notificationRedirectService: NotificationRedirectService,
    private ngZone: NgZone
  ) {
    const functionName: string = `constructor`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/`);

    this.angularFireMessaging.messaging.subscribe(
      (_messaging: any) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  public requestPermission() {
    const functionName: string = `requestPermission`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/`);

    this.angularFireMessaging.requestToken.subscribe(
      (token: any) => {
        console.log(`${logPath}/ @fire firebase device token: `, token);
        window.localStorage.setItem(`firebaseToken`, `${token}`);

        this.postFirebaseToken().subscribe((response) => {
          console.log(`${logPath}/ @fire postFirebaseToken() response is: `, response);
        });
      },
      (error: any) => {
        console.error('Unable to get permission to notify.', error);
      }
    );
  }

  public receiveMessage() {
    const functionName: string = `receiveMessage`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ @fire`);

    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        // this.currentMessage.next(payload);
        console.log(`${logPath}/ @fire new FCM received: `, payload);
        const notificationTime: any = moment();
        console.log(`${logPath}/ @fire timestamp of FCM received: `, notificationTime);

        // this.audioService.playNotificationSound();

        const time: string = moment.tz(payload.data.created_at, `Europe/Athens`).format('HH:mm:ss - DD/MM/YY');

        if (!payload.notification) {
          const notification = new Notification(
            `${payload.data.title}`, 
            {
              body: `${payload.data.customer_name}\n${time}`, 
              icon: `/../assets/img/logo/iconic_logo1.jpg`, 
              requireInteraction: true
            }
          );
          console.log(`${logPath}/ @fire data of FCM received: `, notification.data);

          if (payload.data.type)
          console.log(`@fire payload.data.type: `, payload.data.type)

          notification.onclose = ((event) => {
            event.preventDefault();
            console.log(`@fire onClose`);
            console.log(`@fire payload.data.type: `, payload.data.type);
            this.notificationRedirectService.onNotificationClick(payload.data.type);
          })

          notification.onclick = ((event) => {
            event.preventDefault();
            console.log(`@fire onClick`);
            console.log(`@fire payload.data.type: `, payload.data.type);
            this.notificationRedirectService.onNotificationClick(payload.data.type);
          })

          console.log(`${logPath}/ @fire notification (title & body): `, notification.title, notification.body);
        } else {
          const notification = new Notification(
            `${payload.notification.title}`, 
            {
              icon: `/../assets/img/logo/iconic_logo1.jpg`, 
              requireInteraction: true
            }
          );
          console.log(`${logPath}/ @fire data of FCM received: `, notification.data);
          if (payload.data.type) {
            console.log(`@fire payload.data.type: `, payload.data.type)
          }
          console.log(`${logPath}/ @fire notification (title & body): `, notification.title, notification.body);
        }

      }
    )
  }

  public retrieveToken(): void {
    const functionName: string = `retrieveToken`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/`);

    let _retrievedToken: string | null = null; 
    this.angularFireMessaging.getToken.subscribe(
      (token: any) => {
        _retrievedToken = token;
        console.log(`${logPath}/ @fire firebaseToken retrieved: `, _retrievedToken);
        this.deleteToken(_retrievedToken);
      },
      (error: any) => {
        console.log(`${logPath}/ @fire unable to retrieve firebaseToken - error ${error}`);
      }
    );
  }

  public deleteToken(_token: string | null) {
    const functionName: string = `deleteToken`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ @fire _token: `, _token);

    if (_token !== null) {
      this.angularFireMessaging.deleteToken(_token).subscribe((response) => {
        window.localStorage.removeItem('firebaseToken');
        console.log(`${logPath}/ @fire deleteToken() response: `, response);
      })
    } else {
      console.log(`${logPath}/ @fire firebaseToken is null: `, _token);
    }
  }

  private postFirebaseToken() {
    const functionName: string = `postFirebaseToken`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/ @fire`);

    const _token: string | null = window.localStorage.getItem('firebaseToken');

    return this.httpClient.post<any>(
      `${env.apiUrl}/api/v0/web/staff/user/notification-token`,
      {
        'token': _token
      },
      {
        headers: new HttpHeaders({
          'Authorization': this.loginService.getAuthHeader(),
        })
      }
    )
  }

}
