/**
 * @author Filippos A. Grapsas <fgrapsas@dga.gr>
 *
 * Created at 03/02/2022
 */

// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// App lib
import { Logger } from './../../lib/logger/logger';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  public header: string = '';
  public body: string = '';
  public positive: string | null = 'Yes';
  public negative: string | null = 'No';

  private readonly log: Logger = Logger.make('/shared/confirm-dialog/ConfirmDialogComponent');

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      header?: string,
      body: string,
      positive?: string | null,
      negative?: string | null,
    },
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {
    this.log.sub('constructor()').debug();
  }

  public ngOnInit(): void {
    this.log.sub('ngOnInit()').debug();

    this.header = this.data?.header ?? 'Confirm';
    this.body = this.data?.body ?? 'Are you sure?';
    this.positive = this.data?.positive === undefined
      ? this.positive
      : this.data?.positive
    ;
    this.negative = this.data?.negative === undefined
      ? this.negative
      : this.data?.negative
    ;
  }

  public close(): void {
    this.log.sub('close()').debug();
    this.dialogRef.close();
  }
}
