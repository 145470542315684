/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/01/20
 */

// Third party
import { Expose } from 'class-transformer';

export class UserInfo {
  @Expose() public name: string;
  @Expose() public mobile: string;
  @Expose() public email: string;
  @Expose() public userable: {first_name: string, last_name: string};
  @Expose() public role: string;
  @Expose() public companies: Company[];
}

export class Company {
  @Expose() public id: number;
  @Expose() public name: string;
  @Expose() public branches: any[];
}

export class Branch {
  @Expose() public id: number;
  @Expose() public name: string;
  @Expose() public companyId?: number;
}