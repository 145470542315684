/**
 * @author Filippos A. Grapsas <public001@grapsas.com>
 * Created at 18/09/2021
 */

/* tslint:disable: no-console */
export class Logger {
  protected ns: string;

  public static make(ns: string): Logger {
    return new this(ns);
  }

  public constructor(ns: string) {
    this.ns = ns;
  }

  public sub(ns: string): Logger {
    return Logger.make(`${this.ns}.${ns}`);
  }

  public getNs(): string {
    return this.ns;
  }

  public debug(...args: any[]): Logger {
    console.debug(this.ns, ...args);
    return this;
  }

  public info(...args: any[]): Logger {
    console.info(this.ns, ...args);
    return this;
  }

  public warn(...args: any[]): Logger {
    console.warn(this.ns, ...args);
    return this;
  }

  public error(...args: any[]): Logger {
    console.error(this.ns, ...args);
    return this;
  }
}
