/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/01/19
 */

// Angular
import { NgModule } from '@angular/core';
import { ApplicationPipesModule } from 'src/app/shared/application-pipes/application-pipes.module';
// App
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material.module';
import { HotelSwitcherModule } from './../hotel-switcher/hotel-switcher.module';
// import { NotificationsModule } from "../../pages/notifications/notifications.module";
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    SharedModule,
    ApplicationPipesModule,
    HotelSwitcherModule,
    // NotificationsModule,
    MaterialModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }