/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/03/15
 */

// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// App
import { CanDeactivateGuard } from './can-deactivate-guard.service';
import { LoginComponent } from './core/login/login.component';
import { LoadingComponent } from './core/loading/loading.component';
import { LoadingGuardService } from './core/loading/loading-guard.service';
import { UnderContructionComponent } from './pages/under-contruction/under-contruction.component';
import { WebsiteWidgetComponent } from './pages/website-widget/website-widget.component';
import { WidgetDisplayComponent } from './pages/test/widget-display.component';

const routes: Routes = [
  // LOGIN
  // {path: 'login', component: LoginComponent},
  // {path: 'login/:mode/:verification_code/:email', component: LoginComponent},

  // WEBSITE-WIDGET
  // {path: 'website-widget', component: WebsiteWidgetComponent},
  // {path: 'website-widget/:slag', component: WebsiteWidgetComponent},
  {path: ':slag', component: WebsiteWidgetComponent},
  // {path: 'website-widget/:branchId/:supplierId', component: WebsiteWidgetComponent},

  // LOADING
  // {path: 'loading', component: LoadingComponent },

  // {path: '', canActivate:[LoadingGuardService], children: [
  //   {path: '', redirectTo: 'website-widget', pathMatch: 'full'},
  //   // UNDER CONSTRUCTION
  //   {path: 'under-construction', component: UnderContructionComponent},
  //   // WEBSITE-WIDGET
  //   // {path: 'website-widget', component: WebsiteWidgetComponent},
  //   // WIDGET-DISPLAY
  //   {path: 'widget-display', component: WidgetDisplayComponent},
  // ]},
  // { path: '**', redirectTo: '' },
  { path: '**', redirectTo: ''},
];

@NgModule({
  imports: [
      RouterModule.forRoot(routes, {useHash: true}),
    ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }