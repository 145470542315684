/**
 * @author Filippos A. Grapsas <fgrapsas@dga.gr>
 *
 * Created at 15/03/2022
 */

// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
// App core
import { HotelSwitcherComponent } from './hotel-switcher.component';

@NgModule({
  declarations: [
    HotelSwitcherComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule,
  ],
  exports: [
    HotelSwitcherComponent,
  ],
})
export class HotelSwitcherModule { }
