import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material.module';
import { WidgetDisplayComponent } from '../test/widget-display.component';
// import { LoginDialogComponent } from 'src/app/core/login/login-dialogs/login-dialog.component';



@NgModule({
  declarations: [
    WidgetDisplayComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    // LoginDialogComponent
  ]
})
export class WidgetDisplayModule { }