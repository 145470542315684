/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/01/18
 */

// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// App
import { UnderContructionComponent } from './under-contruction.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  declarations: 
  [
    UnderContructionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
  ],
  exports: 
  [
    UnderContructionComponent
  ]
})
export class UnderContructionModule { }
