/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/03/15
 */

// Angular
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AsyncPipe, DatePipe } from '@angular/common';
// Third-party
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import * as Sentry from "@sentry/angular";
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
// App
import './lib/prototypes/string';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { environment } from '../environments/environment';
import { AutoLogoutInterceptorService } from './core/login/auto-logout-interceptor.service';
import { LoginModule } from './core/login/login.module';
import { LoadingModule } from './core/loading/loading.module';
import { MessagingService } from './core/services/messaging.service';
import { UnderContructionModule } from './pages/under-contruction/under-contruction.module';
// Firebase imports
import { AngularFireMessagingModule } from 'angularfire2/messaging';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireModule } from 'angularfire2';
import { WidgetDisplayModule } from './pages/test/widget-display.module';
import { WebsiteWidgetModule } from './pages/website-widget/website-widget.module';
import { NgxStripeModule } from 'ngx-stripe';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    // App
    SharedModule,
    CoreModule,
    LoginModule,
    UnderContructionModule,
    // Third-party
    LottieModule.forRoot({ player: () => player }),
    LoadingModule,
    InfiniteScrollModule,
    FlexLayoutModule,
    WidgetDisplayModule,
    WebsiteWidgetModule,
    // NgxStripeModule,
    NgxStripeModule.forRoot('pi_3MwLvPGaS4DL199T1FcKN2DY_secret_9i82a3Q7wdaFUvGdOuZXmqJmY'),
    // GoogleMapsModule
    // NgRx
    // StoreModule.forRoot(appReducer.appReducer),
    //   StoreDevtoolsModule.instrument({
    //     maxAge: 50,
    //     logOnly: environment.production,
    //     autoPause: true
    // }),
    // EffectsModule.forRoot([
    // ]),
    // StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    // Firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireMessagingModule,
  ],
  providers: [
    MessagingService,
    AsyncPipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AutoLogoutInterceptorService,
      multi: true
    },
    DatePipe,
  ],
  bootstrap: [
    AppComponent]
})
export class AppModule { }
