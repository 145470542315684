/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/01/19
 */

// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationPipesModule } from './application-pipes/application-pipes.module';
import { FormsModule } from '@angular/forms';
// Third-party
import { ImageCropperModule } from 'ngx-image-cropper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DateAdapter } from '@angular/material/core';
// App
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MaterialModule } from './../material.module';
import { ImageCropperDialogComponent } from './image-cropper-dialog/image-cropper-dialog.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { CustomDateAdapter } from './custom-date.adapter';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ImageCropperDialogComponent,
    ErrorModalComponent,
  ],
  imports: [
    CommonModule,
    ApplicationPipesModule,
    FormsModule,
    MaterialModule,
    FlexLayoutModule,
    ImageCropperModule,
  ],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter}
  ],
  exports: [
    CommonModule,
    ApplicationPipesModule,
    FormsModule,
    ErrorModalComponent,
    FlexLayoutModule,
  ]
})
export class SharedModule { }