import { Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject, Subscription, from, of } from 'rxjs';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import * as moment from 'moment';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { WebsiteWidgetService } from './services/website-widget.service';
import { GoogleMap } from '@angular/google-maps';
import { GetCostCalculationData, PostTransferRequestBody } from './models/website-widget.model';
import { ObjectHelper } from 'src/app/lib/object-helper.class';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/core/login/login.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatRadioChange } from '@angular/material/radio';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageHelper } from 'src/app/lib/image-helper.class';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { switchMap, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatTabChangeEvent } from '@angular/material/tabs';

interface autoComplete {
  icon: string;
  title: string;
  address: string;
  latitude: number,
  longitude: number
}

@Component({
  selector: 'app-website-widget',
  templateUrl: './website-widget.component.html',
  styleUrls: ['./website-widget.component.css']
})

export class WebsiteWidgetComponent implements OnInit, OnDestroy {
  private componentName: string = `WebsiteWidgetComponent`;
  public loading: boolean = false;
  private subscriptions: Subscription[] = [];
  public branchId: number;
  public supplierId: number | undefined = undefined;

  // STRUCTURAL VARIABLES
  public isOrderSummaryVisible: boolean = true;
  public isRouteVisible: boolean = true;

  // public isTransferStepOptional: boolean = true;
  public isTransferStepOptional: boolean = false;

  // public isPaymentStepOptional: boolean = true;
  public isPaymentStepOptional: boolean = false;

  // PAYMENT RELATED VARIABLES
  public paymentMethodsArray: any[] = [];
  public cardIsSelected: boolean = false;
  public cashIsSelected: boolean = false;
  public depositIsSelected: boolean = false;
  public offerRequestIsSelected: boolean = false;
  public cardToDriverIsSelected: boolean = false;
  public isContinueButtonEnabled: boolean = true;
  public offerPrice: string = '';
  public finalPrice: string = '';
  public depositPercentage: number;
  public depositFixed: number;
  public depositPrice: string = '';
  public depositPriceRaw: number;
  public oneWayOfferRequestForced: boolean = false;
  public offerId: string;
  public postTransferResponse: any;
  public postTransferResolved: boolean = false;
  public fetchStripeClientSecretResponse: any;
  public paymentIntentClientSecret: any = '';
  public transferId: number;
  public clientSecretRetrieved: boolean = false;
  public paymentMobileMode: boolean = false;
  // public creditCardGateway: any;
  // public connectedAccount: any;
  public connectedAccount: any = {};
  public publishableApiKey: any;

  // FORM VARIABLES
  public quoteForm: FormGroup;
  public transferForm: FormGroup;
  public paymentForm: FormGroup;

  // GOOGLE AUTOCOMPLETE/ PLACES VARIABLES
  private restrictedCountry: string = 'GR';
  private mykonosCenter = { lat: 37.437723450046164, lng: 25.376394797618932 };
  private athensCenter = { lat: 37.983810, lng: 23.727539 };
  // Create a bounding box with sides ~10km away from the center point
  private defaultBounds2 = {
    north: this.mykonosCenter.lat + 0.10,
    // north: this.athensCenter.lat + 0.10,
    south: this.mykonosCenter.lat - 0.10,
    // south: this.athensCenter.lat - 0.10,
    east: this.mykonosCenter.lng + 0.10,
    // east: this.athensCenter.lng + 0.10,
    west: this.mykonosCenter.lng - 0.10,
    // west: this.athensCenter.lng - 0.10,
  };
  public options: any = {
    componentRestrictions: { country: this.restrictedCountry },
    // bounds: this.defaultBounds,
    // bounds: this.defaultBounds2,
    // radius: 15000,
    strictBounds: true,
    fields: ['geometry', 'formatted_address', 'place_id', 'name'],
    // types: ['address', 'geocode', 'establishment']
    // types: ['establishment']
  }

  // MAPS VARIABLES
  @ViewChild(GoogleMap) vehicleTypeMap!: GoogleMap;
  public mapOptions: google.maps.MapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    draggable: false
  }
  public markerFrom: any;
  public markerTo: any;
  public markers: any[] = [];
  public agmMarkers: any[] = [];
  private markerFromLat: number;
  private markerFromLng: number;
  private markerToLat: number;
  private markerToLng: number;

  // DATE AND TIME FIELDS VARIABLES
  public minDate: Date = new Date();
  public availableHoursArray: string[] = [];
  private nowFuture: any = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  private isTodaySelected: boolean = false;
  private sameDayHourStep: number = 2;
  public dateView: string;
  public datePost: string;
  public sameDayMinutesMargin: number = 120;

  // PHONE FIELD VARIABLES
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Greece, CountryISO.Germany, CountryISO.France];
  @ViewChild('phoneInput') phoneInput!: ElementRef;
  public mobile: any;
  public e164Number: string;

  // RESPONSIVENESS VARIABLES
  public getScreenWidth: any;
  public getScreenHeight: any;
  public isTransferWhiteSpace: boolean;
  public isMediumSpace: boolean;
  public isSaverSpace: boolean;
  public selectedStep: string = '';
  public previousStep: string = '';
  public isWhiteSpace: boolean;
  public space: any;
  @ViewChild('spaceLeft') spaceLeft: ElementRef;
  @ViewChild('spaceRight') spaceRight: ElementRef;

  public xsTransferStep: boolean = false;
  public smTransferStep: boolean = false;
  public mdTransferStep: boolean = false;

  public isTest: boolean = false;

  public from_latitude: string;
  public from_longitude: string;
  public to_latitude: string;
  public to_longitude: string;

  public routeSubscription!: Subscription;

  public supplierPinnedAddress: string | undefined = undefined;
  private supplierPinnedAddressLat: number;
  private supplierPinnedAddressLng: number;
  public pinnedAddressMode: boolean = false;
  public isFromFieldDisabled: boolean = false;
  public isToFieldDisabled: boolean = false;

  @ViewChild('fromField', { read: ElementRef, static: false }) fromField: ElementRef;

  public vehicleTypeForm: FormGroup;
  public isQuoteFormReady: boolean = false;
  public isVehicleTypeFormReady: boolean = false;
  public isVehicleTypeStepOptional: boolean = false;
  // public isVehicleTypeStepOptional: boolean = true;
  public slag: string;
  public isSecondStepAvailable: boolean = false;
  public vehicleTypesArray: any[] = [];
  public initialFrom: string;
  public initialTo: string;
  public initialTime: string;
  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('step1') step1: MatStep;
  public selectedVehicleType: any;
  public selectedVehicleId: number = -1;
  public vehicleTypeListLoading: boolean = false;
  public noVehiclesFlag: boolean = false;
  public noLogo: boolean = false;
  public logoImageReady: boolean = false;

  public titleState = ['', '', '', ''];
  public emptyState = ['', '', '', ''];
  public fullstate = ["Quote", "Vehicles", "Transfer", "Payment"];

  public logo_url: any;
  public logoImage1: any;
  public logoImage2: any;

  private vehiclesCounter: number;
  public vehiclesReady: boolean = false;

  public isOfferRequested: boolean = true;

  public bookNowisPressed: boolean = false;

  public discountPercentage: number = 0;

  public discountedPrice: any = 0;

  public suggestedList: any[] = [];
  private firstRadioChoice: string = '';

  public filteredFromResults: Observable<any[]>;
  public filteredToResults: Observable<any[]>;
  public filteredFromResults2: Observable<any[]>;
  public filteredToResults2: Observable<any[]>;

  public autocompleteResults: any[];

  private iAmOnTransferTab: boolean;

  //Images ViewChild
  @ViewChild('logoElement') logoElement: ElementRef;
  @ViewChild('vehicleElement') vehicleElement: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;

  //Logo Variables
  @HostBinding("style.--stepMarginer") stepMarginer: string = '';

  //Paint it Variables
  @HostBinding("style.--bgColor") bgColor: string = '';
  @HostBinding("style.--fontColor") fontColor: string = '';
  @HostBinding("style.--elementColor") elementColor: string = '';

  @ViewChild('fromInput', { read: MatAutocompleteTrigger }) triggerAutocompleteInput: MatAutocompleteTrigger;
  @ViewChild('toInput', { read: MatAutocompleteTrigger }) triggerAutocompleteInput1: MatAutocompleteTrigger;
  @ViewChild('fromInput1', { read: MatAutocompleteTrigger }) triggerAutocompleteInput2: MatAutocompleteTrigger;
  @ViewChild('toInput1', { read: MatAutocompleteTrigger }) triggerAutocompleteInput3: MatAutocompleteTrigger;

  constructor(
    private formBuilder: FormBuilder,
    private websiteWidgetService: WebsiteWidgetService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private el: ElementRef,
    private domSanitizer: DomSanitizer) {
    // const functionName: string = `constructor`;
    // const logPath: string = `/${this.componentName}/${functionName}()`;
  }

  ngOnInit(): void {
    const lifecycleName: string = `ngOnInit`;
    const logPath: string = `/${this.componentName}/${lifecycleName}()`;
    // console.log(`${logPath}/ @Website minDate`, this.minDate);

    this.subscriptions.push(this.activatedRoute.params.subscribe((params) => {
      // console.log(`${logPath}/@Website params`, params);

      this.slag = params.slag;
      // console.log(`${logPath}/@Website slag G979`, this.slag);

      //  let colorer = 'linear-gradient(180deg, #857244 40%, #502C2C 100%)';
      //  setTimeout(() => {
      //   this.paintItBlack(colorer, 'white', '#CEB257');
      // },4000);


      // Widget User Initialized here
      this.initializeWebsiteUserLogin(`widget_user@dga.gr`, `123456`).subscribe(
        (response: any) => {
          // console.log(`${logPath}/@Website - response`, response);
          this.fetchWidgetInfo(this.slag);
          // this.fetchPaymentMethods();

        },
        (error: any) => {
          console.log(`${logPath}/@Website - error`, error);
        }
      );

    }));


    this.selectedStep = 'Quote'
    this.onWindowResize();

    this.makeWidgetLg();
    this.iAmOnTransferTab = true;

    // this.setUpMobileScreens();
    this.calculateAvailableHours(false);
    this.quoteFormSetup();
    this.transferFormSetup();
    // this.paymentFormSetup();
  }

  ngAfterViewInit() {
    // Trigger load event manually if the image is cached and already loaded
    if (this.logoElement) {
      if (this.logoElement && this.logoElement.nativeElement.complete) {
        this.onLogoLoad();
      }
    }
    if (this.vehicleElement && this.vehicleElement.nativeElement.complete) {
      this.onImageLoad();
    }
  }

  ngOnDestroy(): void {
    const lifecycleName: string = `ngOnDestroy`;
    const logPath: string = `/${this.componentName}/${lifecycleName}()`;
    // console.log(`${logPath}/ @Website`);

    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public swapFromToFields2() {
    const functionName: string = `swapFromToFields2`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website`);

    let _from: string;
    let _to: string;
    let _fromLat: number;
    let _fromLng: number;
    let _toLat: number;
    let _toLng: number;
    let mode: string | undefined = undefined;

    _from = this.quoteForm.get('from')!.value;
    console.log(`${logPath}/ @Website - _from`, _from);
    // to holds the set value
    _from === null && (mode = 'to');

    _to = this.quoteForm.get('to')!.value;
    console.log(`${logPath}/ @Website - _to`, _to);
    // from holds the set value
    _to === null && (mode = 'from');

    console.log(`${logPath}/ @Website - quoteForm.get('from')!.value`, this.quoteForm.get('from')!.value);
    console.log(`${logPath}/ @Website - this.quoteForm.get('to')!.value`, this.quoteForm.get('to')!.value);

    this.quoteForm.get('from')?.setValue(_to);
    this.quoteForm.get('to')?.setValue(_from);

    console.log(`${logPath}/ @Website - quoteForm.get('from')!.value`, this.quoteForm.get('from')!.value);
    console.log(`${logPath}/ @Website - this.quoteForm.get('to')!.value`, this.quoteForm.get('to')!.value);

    if (mode === 'to') {
      this.markerFromLat = this.supplierPinnedAddressLat;
      this.markerFromLng = this.supplierPinnedAddressLng;
    } else if (mode === 'from') {
      this.markerToLat = this.supplierPinnedAddressLat;
      this.markerToLng = this.supplierPinnedAddressLng;
    } else {
      _fromLat = this.markerFromLat;
      _fromLng = this.markerFromLng;
      _toLat = this.markerToLat;
      _toLng = this.markerToLng;

      this.markerFromLat = _toLat;
      this.markerFromLng = _toLng;
      this.markerToLat = _fromLat;
      this.markerToLng = _fromLng;
    }

    Object.keys(this.quoteForm.controls).forEach((key) => {
      const control = this.quoteForm.controls[key];
      // console.log(`${logPath}/@Website - key`, key);
      // console.log(`${logPath}/@Website - quoteForm.errors`, this.quoteForm.errors);
      control.setErrors(null);
    });

    this.quoteForm.markAsPristine();
    this.quoteForm.markAsUntouched();

    this.fromField.nativeElement.classList.remove('mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick');

    // console.log(`${logPath}/@Website - quoteForm.get('from').status`, this.quoteForm.get('from')!.status);
  }

  public swapFromToFields() {
    const functionName: string = `swapFromToFields`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website`);

    let _to: string;
    let _from: string;
    let _dummyLat: number;
    let _dummyLng: number;

    this.isFromFieldDisabled ? (

      // from --> to
      this.isFromFieldDisabled = false,
      this.isToFieldDisabled = true,

      this.supplierPinnedAddress !== undefined && (

        this.quoteForm.get('from')?.setValue(undefined),
        this.quoteForm.get('to')!.value !== undefined && (
          _to = this.quoteForm.get('to')!.value,
          this.quoteForm.get('from')?.setValue(_to)
        ),
        this.quoteForm.get('to')?.setValue(this.supplierPinnedAddress)

      ),

      _dummyLat = this.markerToLat,
      _dummyLng = this.markerToLng,

      this.markerToLat = this.supplierPinnedAddressLat,
      this.markerToLng = this.supplierPinnedAddressLng,

      this.markerFromLat = _dummyLat,
      this.markerFromLng = _dummyLng

    ) : (

      // to --> from
      this.isFromFieldDisabled = true,
      this.isToFieldDisabled = false,

      this.supplierPinnedAddress !== undefined && (

        this.quoteForm.get('to')?.setValue(undefined),
        this.quoteForm.get('from')?.value !== undefined && (
          _from = this.quoteForm.get('from')?.value,
          this.quoteForm.get('to')?.setValue(_from)
        ),
        this.quoteForm.get('from')?.setValue(this.supplierPinnedAddress)

      ),

      _dummyLat = this.markerFromLat,
      _dummyLng = this.markerFromLng,

      this.markerFromLat = this.supplierPinnedAddressLat,
      this.markerFromLng = this.supplierPinnedAddressLng,

      this.markerToLat = _dummyLat,
      this.markerToLng = _dummyLng
    );

    Object.keys(this.quoteForm.controls).forEach((key) => {
      const control = this.quoteForm.controls[key];
      // console.log(`${logPath}/@Website - key`, key);
      // console.log(`${logPath}/@Website - quoteForm.errors`, this.quoteForm.errors);
      control.setErrors(null);
    });

    this.quoteForm.markAsPristine();
    this.quoteForm.markAsUntouched();

    this.fromField.nativeElement.classList.remove('mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick');

    // console.log(`${logPath}/@Website - quoteForm.get('from').status`, this.quoteForm.get('from')!.status);
  }

  private fetchSupplierPinnedAddress(_branchId: number, _supplierId: number) {
    const functionName: string = `fetchSupplierPinnedAddress`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website - _branchId`, _branchId);
    console.log(`${logPath}/ @Website - _supplierId`, _supplierId);

    this.websiteWidgetService.getSupplierPinnedAddress(_branchId, _supplierId).subscribe(
      (response: any) => {
        console.log(`${logPath}/ @Website - response`, response);

        // this.supplierPinnedAddress = response.results[0].formatted_address;
        this.supplierPinnedAddress = response.buisness_name,
          console.log(`${logPath}/@Website - supplierPinnedAddress`, this.supplierPinnedAddress)

        // this.supplierPinnedAddress !== undefined && this.quoteForm.get('from')?.setValue(this.supplierPinnedAddress);
        this.supplierPinnedAddress !== undefined && this.quoteForm.get('to')?.setValue(this.supplierPinnedAddress);

        this.supplierPinnedAddressLat = response.results[0].geometry.location.lat;
        this.supplierPinnedAddressLng = response.results[0].geometry.location.lng;

        this.markerToLat = response.results[0].geometry.location.lat;
        this.markerToLng = response.results[0].geometry.location.lng;

        console.warn(`${logPath}/ @Website markerFromLat`, this.markerFromLat);
        console.warn(`${logPath}/ @Website markerFromLng`, this.markerFromLng);
      },
      (error: any) => {
        console.log(`${logPath}/ @Website - error`, error);
      }
    );
  }

  private fetchNewSupplierPinnedAddress(_address: string, _lat: number, _long: number) {
    const functionName: string = `fetchSupplierPinnedAddress`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website - _address`, _address);
    console.log(`${logPath}/ @Website - _latitude`, _lat);
    console.log(`${logPath}/ @Website - _langitude`, _long);

    // this.supplierPinnedAddress = response.results[0].formatted_address;
    this.supplierPinnedAddress = _address;
    console.log(`${logPath}/@Website - supplierPinnedAddress`, this.supplierPinnedAddress)

    // this.supplierPinnedAddress !== undefined && this.quoteForm.get('from')?.setValue(this.supplierPinnedAddress);
    this.supplierPinnedAddress !== undefined && this.quoteForm.get('to')?.setValue(this.supplierPinnedAddress);

    this.supplierPinnedAddressLat = _lat;
    this.supplierPinnedAddressLng = _long;

    // this.markerFromLat = response.results[0].geometry.location.lat;
    // this.markerFromLng = response.results[0].geometry.location.lng;
    this.markerToLat = _lat;
    this.markerToLng = _long;
  }

  private initializeWebsiteUserLogin(_email: string, _password: string) {
    const functionName: string = `initializeWebsiteUserLogin`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website -_email, _password`, _email, _password);

    const subject = new Subject();

    let sub = this.loginService.getDeviceTokenRequest(
      ObjectHelper.only({ email: _email, password: _password }, ['email', 'password'])
    );
    sub.subscribe((response: any) => {
      console.log(`${logPath}/ ### 1 @Website getDeviceTokenRequest - resolved`, response);
      this.loginService.setDeviceToken(response.plain_text_token);
      subject.next();
      subject.complete();
    }, (response: HttpErrorResponse) => {
      console.log(`${logPath}/ @Website getDeviceTokenRequest - rejected`, response);
      let errorMessage = 'Oops something went wrong';
      if (response.status === 401) {
        errorMessage = 'Wrong credentials';
      }
      // this.snackBar.open(errorMessage, '', {duration: 3000});
      // this.formLoading(false);
    });
    return subject;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(iComeFromTourTab?: boolean) {
    if (window.innerWidth > 600) {
      if (this.selectedStep == 'Quote') {
        this.titleState = this.emptyState;
      }
      else {
        this.titleState = this.fullstate;
      }
    }
    const functionName: string = `onWindowResize`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website - selectedStep`, this.selectedStep);

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    if (this.iAmOnTransferTab == true) {
      this.makeWidgetLg();
    }
    else
      if (this.selectedStep === 'Quote') {
        this.isTransferWhiteSpace = false;
        (this.getScreenWidth < 460) && (this.isWhiteSpace = true, this.space = this.getScreenWidth / 12);
        // (this.getScreenWidth <= 460) && (this.isWhiteSpace = true, this.space = this.getScreenWidth / 12);
        (this.getScreenWidth >= 460 && this.getScreenWidth < 560) &&
          (this.isWhiteSpace = true, this.space = this.getScreenWidth / 10);
        (this.getScreenWidth >= 560 && this.getScreenWidth < 660) && (this.isWhiteSpace = true, this.space = this.getScreenWidth / 8);
        (this.getScreenWidth >= 660 && this.getScreenWidth < 760) && (this.isWhiteSpace = true, this.space = this.getScreenWidth / 6);
        (this.getScreenWidth >= 760 && this.getScreenWidth < 860) && (this.isWhiteSpace = true, this.space = this.getScreenWidth / 4);
        (this.getScreenWidth >= 860 && this.getScreenWidth < 960) && (this.isWhiteSpace = true, this.space = this.getScreenWidth / 4);
        (this.getScreenWidth >= 960) &&
          (this.isWhiteSpace = true, this.space = this.getScreenWidth / 4);
        this.xsTransferStep = false;
        //   console.log('G979 offset', document.getElementById("elevator")?.offsetTop);
        // const offsetTop = document.getElementById("elevator")?.offsetTop;
        // const marginToTop = offsetTop - window.scrollY;
        // console.log('G979 Margin to top:', marginToTop);

      }
      else if (this.selectedStep === 'Transfer' || this.selectedStep === `Vehicles`) {
        this.makeWidgetLg();
      }
      else if (this.selectedStep === 'Payment') {
        this.isWhiteSpace = false;
        (this.getScreenWidth < 912) && (this.isTransferWhiteSpace = false, this.paymentMobileMode = true);
        (this.getScreenWidth >= 912) && (this.isTransferWhiteSpace = true, this.paymentMobileMode = false);
        this.xsTransferStep = false;
      }

    //I-Frame Message Listener
    window.parent.postMessage({ message: "getAppData", value: this.selectedStep }, "*");
  }

  public onTabChange(_event: MatTabChangeEvent) {
    switch (_event.index) {
      /// tours & activities
      case 0:
        this.iAmOnTransferTab = true;
        this.makeWidgetLg();
        break;
      ///  transfers
      case 1:
        this.iAmOnTransferTab = false;
        this.onWindowResize();
        break;
    }
  }

  public makeWidgetLg() {
    this.isWhiteSpace = false;
    (this.getScreenWidth < 912) && (
      this.isTransferWhiteSpace = false,
      (this.getScreenWidth <= 599) ? (this.xsTransferStep = true, this.isOrderSummaryVisible = false, this.isTest = true) : (this.xsTransferStep = false, this.isOrderSummaryVisible = true, this.isTest = false)
    );
    (this.getScreenWidth >= 912) && (this.isTransferWhiteSpace = true,
      this.isOrderSummaryVisible = true, this.isTest = false);
  }

  public onSelectionChange(_event: any) {
    const functionName: string = `onSelectionChange`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`Event GV979`,  _event.previouslySelectedIndex);
    this.pickLabel(_event);
    if (_event.previouslySelectedIndex == 0) {
      this.previousStep = "Quote";
      if (_event.selectedIndex == 0) {
        this.selectedStep = "Quote";
      }
      else if (_event.selectedIndex == 1) {
        this.isSecondStepAvailable ? (this.selectedStep = 'Vehicles') : (this.selectedStep = 'Transfer');
      }
      else if (_event.selectedIndex == 2) {
        this.isSecondStepAvailable ? (this.selectedStep = 'Transfer') : (this.selectedStep = 'Payment');
      }
      else {
        this.selectedStep = 'Payment';
      }
    }
    // _event.selectedIndex === 2 ? (this.isWhiteSpace = false) : (this.isWhiteSpace = true);
    // _event.selectedIndex === 1 ? (this.isWhiteSpace = false) : (this.isWhiteSpace = true);
    // _event.selectedIndex === 3 ? (this.isWhiteSpace = false) : (this.isWhiteSpace = true);
    // _event.selectedIndex === 0 ? (this.isTransferWhiteSpace = false) : (this.isTransferWhiteSpace = true);
    this.selectedStep == "Quote" ? (this.isTransferWhiteSpace = false) : (this.isTransferWhiteSpace = true);
    this.selectedStep !== "Quote" ? (this.isWhiteSpace = false) : (this.isWhiteSpace = true);

    this.onWindowResize();

    if ((this.previousStep === "Transfer" || this.previousStep === "Payment") && this.selectedStep === "Quote") {
      this.oneWayOfferRequestForced = false;
      this.offerRequestIsSelected = false;
      this.paymentMethodsArray.forEach((paymentMethod: any, index: number) => {
        console.log(`${logPath}/ @Website paymentMethod`, paymentMethod);

        if (paymentMethod.paymentMethod === 'Cash') {
          this.cashIsSelected = true;
          this.firstRadioChoice = 'Cash';
          console.log(`${logPath}/ @Website cashIsSelected`, this.cashIsSelected);
        } else {
          if (paymentMethod.paymentMethod === 'CreditCard') {
            this.cardIsSelected = true;
            this.firstRadioChoice = 'Card';
            console.log(`${logPath}/ @Website cardIsSelected`, this.cardIsSelected);
          } else {
            if (paymentMethod.paymentMethod === 'Deposit') {
              this.cardIsSelected = true;
              this.firstRadioChoice = 'Deposit';
              console.log(`${logPath}/ @Website depositSelected`, this.depositIsSelected);
            }
          }
        }
      })
    }
    if (_event.previouslySelectedIndex == 0) {
      this.previousStep = "Quote";
      if (_event.selectedIndex == 0) {
        this.selectedStep = "Quote";
      }
      else if (_event.selectedIndex == 1) {
        this.isSecondStepAvailable ? (this.selectedStep = 'Vehicles') : (this.selectedStep = 'Transfer');
      }
      else if (_event.selectedIndex == 2) {
        this.isSecondStepAvailable ? (this.selectedStep = 'Transfer') : (this.selectedStep = 'Payment');
      }
      else {
        this.selectedStep = 'Payment';
      }
    }
  }

  private calculateAvailableHours(_isToday: boolean): void {
    const functionName: string = `calculateAvailableHours`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website - _isToday`, _isToday);

    this.availableHoursArray.length > 0 &&
      this.availableHoursArray.splice(0, this.availableHoursArray.length);

    const now: any = moment();
    console.log(`${logPath}/@Website - now`, now.format());

    // console.log(`${logPath}/ @Website nowFuture`, this.nowFuture.format());

    // let nowToday: any = moment().add(this.sameDayHourStep, 'hour').set({minute:0,second:0,millisecond:0});
    let nowToday: any = moment().add(this.sameDayMinutesMargin, 'minutes').set({ second: 0, millisecond: 0 });
    console.log(`${logPath}/@Website - nowToday`, nowToday.format());

    let roundParameter: any = nowToday.minutes();
    console.log(`${logPath}/@Website - roundParameter`, roundParameter);

    if (roundParameter < 10) {
      // console.log(`${logPath}/@Website - roundParameter is less than 10`, roundParameter);
      nowToday = moment().add(this.sameDayMinutesMargin, 'minutes').set({ minute: 10, second: 0, millisecond: 0 });

    } else if (roundParameter < 20) {
      // console.log(`${logPath}/@Website - roundParameter is less than 20`, roundParameter);
      nowToday = moment().add(this.sameDayMinutesMargin, 'minutes').set({ minute: 20, second: 0, millisecond: 0 });

    } else if (roundParameter < 30) {
      // console.log(`${logPath}/@Website - roundParameter is less than 30`, roundParameter);
      nowToday = moment().add(this.sameDayMinutesMargin, 'minutes').set({ minute: 30, second: 0, millisecond: 0 });

    } else if (roundParameter < 40) {
      // console.log(`${logPath}/@Website - roundParameter is less than 40`, roundParameter);
      nowToday = moment().add(this.sameDayMinutesMargin, 'minutes').set({ minute: 40, second: 0, millisecond: 0 });

    } else if (roundParameter < 50) {
      // console.log(`${logPath}/@Website - roundParameter is less than 50`, roundParameter);
      nowToday = moment().add(this.sameDayMinutesMargin, 'minutes').set({ minute: 50, second: 0, millisecond: 0 });

    } else {
      // console.log(`${logPath}/@Website - roundParameter is less than 60`, roundParameter);
      nowToday = moment().add((this.sameDayMinutesMargin + 60), 'minutes').set({ minute: 0, second: 0, millisecond: 0 });
    }

    const tomorrow: any = moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    console.log(`${logPath}/@Website - tomorrow`, tomorrow.format());

    let timeFragment: any;
    _isToday ? timeFragment = nowToday : timeFragment = this.nowFuture;

    while (moment(tomorrow).subtract(1, 'minutes') > moment(timeFragment)) {
      this.availableHoursArray.push(moment(timeFragment).format('HH:mm'));
      timeFragment = moment(timeFragment).add(10, 'minutes').format();
      // console.log(`${logPath}/ @Website timeFragment`, timeFragment);
    }
  }

  private quoteFormSetup(): void {
    const functionName: string = `quoteFormSetup`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website`);

    this.quoteForm = this.formBuilder.group({
      from: [undefined, [Validators.required]],
      to: [undefined, [Validators.required]],
      date: [undefined, [Validators.required]],
      time: [undefined, [Validators.required]],
      passengers: [1, [Validators.required]],
      luggage: [0, [Validators.required]],
    })

    this.subscriptions.push(
      this.quoteForm.statusChanges.subscribe(this.quoteFormStatusChanged.bind(this))
    );

    this.filteredFromResults = this.quoteForm.controls['from'].valueChanges.pipe(
      startWith(''),
      switchMap(address => {
        if (address) {
          return from(this.filterResults(address));
        } else {
          return of(this.suggestedList.slice());
        }
      })
    );

    this.filteredToResults = this.quoteForm.controls['to'].valueChanges.pipe(
      startWith(''),
      switchMap(address => {
        if (address) {
          return from(this.filterResults(address));
        } else {
          return of(this.suggestedList.slice());
        }
      })
    );
  }

  public quoteFormStatusChanged(status: any): void {
    const functionName: string = `quoteFormStatusChanged`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website quoteForm.status`, status);
    console.log(`${logPath}/ @Website quoteForm.dirty`, this.quoteForm.dirty);
    console.log(`${logPath}/ @Website quoteForm.value`, this.quoteForm.value);

    console.log(`${logPath}/@Website - pinnedAddressMode`, this.pinnedAddressMode);

    if (this.pinnedAddressMode) {
      this.quoteForm.markAsPristine();
      this.quoteForm.markAsUntouched();

      Object.keys(this.quoteForm.controls).forEach((key) => {
        const control = this.quoteForm.controls[key];
        console.log(`${logPath}/@Website - quoteForm.errors`, this.quoteForm.errors);
        control.setErrors(null);
      });
    }

    // (this.quoteForm.dirty && this.quoteForm.valid) ? this.isSubmitButtonDisabled = false : this.isSubmitButtonDisabled = true;
    // (this.quoteForm.dirty && this.quoteForm.valid) ? this.isContinueButtonEnabled = true : this.isContinueButtonEnabled = false;
  }

  private vehicleTypeFormSetup(): void {
    const functionName: string = `vehicleTypeFormSetup`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    this.vehicleTypeForm = this.formBuilder.group({
      from: [undefined, [Validators.required]],
      to: [undefined, [Validators.required]],
      date: [undefined, [Validators.required]],
      time: [undefined, [Validators.required]],
      passengers: [1, [Validators.required]],
      luggage: [0, [Validators.required]],
    })
    this.subscriptions.push(
      this.vehicleTypeForm.statusChanges.subscribe(this.vehicleTypeFormStatusChanged.bind(this))
    );

    this.vehicleTypeForm.patchValue(this.quoteForm.value);
    console.log(`${logPath}/ @Website - vehicleTypeForm.value`, this.vehicleTypeForm.value);

    this.initialFrom = this.vehicleTypeForm.get('from')?.value;
    this.initialTo = this.vehicleTypeForm.get('to')?.value;
    this.initialTime = this.vehicleTypeForm.get('time')?.value;

    this.filteredFromResults2 = this.vehicleTypeForm.controls['from'].valueChanges.pipe(
      startWith(''),
      switchMap(address => {
        if (address) {
          return from(this.filterResults(address));
        } else {
          return of(this.suggestedList.slice());
        }
      })
    );

    this.filteredToResults2 = this.vehicleTypeForm.controls['to'].valueChanges.pipe(
      startWith(''),
      switchMap(address => {
        if (address) {
          return from(this.filterResults(address));
        } else {
          return of(this.suggestedList.slice());
        }
      })
    );

    this.isVehicleTypeFormReady = true;
  }

  public vehicleTypeFormStatusChanged(status: any): void {
    const functionName: string = `vehicleTypeFormStatusChanged`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website vehicleTypeForm.status`, status);
    console.log(`${logPath}/ @Website vehicleTypeForm.dirty`, this.vehicleTypeForm.dirty);
    console.log(`${logPath}/ @Website vehicleTypeForm.value`, this.vehicleTypeForm.value);

    console.log(`${logPath}/@Website - pinnedAddressMode`, this.pinnedAddressMode);

    if (this.pinnedAddressMode) {
      this.vehicleTypeForm.markAsPristine();
      this.vehicleTypeForm.markAsUntouched();

      Object.keys(this.vehicleTypeForm.controls).forEach((key) => {
        const control = this.vehicleTypeForm.controls[key];
        // console.log(`${logPath}/@Website - vehicleTypeForm.errors`, this.quoteForm.errors);
        control.setErrors(null);
      });
    }

    // (this.vehicleTypeForm.dirty && this.vehicleTypeForm.valid) ? this.isSubmitButtonDisabled = false : this.isSubmitButtonDisabled = true;
    // (this.vehicleTypeForm.dirty && this.vehicleTypeForm.valid) ? this.isContinueButtonEnabled = true : this.isContinueButtonEnabled = false;
  }

  public checkFormValidity(): boolean {
    const functionName: string = `checkFormValidity`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/@Website - vehicleTypeForm.valid`, this.vehicleTypeForm.valid);

    let isValid: boolean = false;
    if (this.vehicleTypeForm !== undefined) {
      (this.vehicleTypeForm.valid && !this.vehicleTypeForm.dirty) && (isValid = true);
    }
    return isValid;
  }

  public checkFormState(): boolean {
    const functionName: string = `checkFormValidity`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/@Website - vehicleTypeForm.valid`, this.vehicleTypeForm.valid);

    let isValid: boolean = false;
    if (this.vehicleTypeForm !== undefined) {
      (this.vehicleTypeForm.valid && this.vehicleTypeForm.dirty) && (isValid = true);
    }
    return isValid;
  }

  private transferFormSetup(): void {
    const functionName: string = `transferFormSetup`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website`);

    this.transferForm = this.formBuilder.group({
      passport: [undefined],
      first_name: [undefined, [Validators.required]],
      last_name: [undefined, [Validators.required]],
      email: [undefined, [Validators.required, Validators.email]],
      voucher: [undefined],
      // mobile: [undefined, [Validators.required, Validators.pattern(this.phoneRegEx)]],
      mobile: [undefined, [Validators.required]],
      childSeats: [0],
      special_instructions: [undefined]
    });
    this.subscriptions.push(
      this.transferForm.statusChanges.subscribe(this.transferFormStatusChanged.bind(this))
    );
  }

  public transferFormStatusChanged(status: any): void {
    const functionName: string = `transferFormStatusChanged`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website transferForm.status`, status);
    console.log(`${logPath}/ @Website transferForm.dirty`, this.transferForm.dirty);
    console.log(`${logPath}/ @Website transferForm.value`, this.transferForm.value);

    this.mobile = this.transferForm.controls['mobile'].value;
    // console.log(`${logPath}/ @Website mobile`, this.mobile);

    this.mobile !== null && (this.e164Number = this.mobile.e164Number);
    // console.log(`${logPath}/ @Website e164Number`, this.e164Number);

    // (this.transferForm.dirty && this.transferForm.valid) ? this.isSubmitButtonDisabled = false : this.isSubmitButtonDisabled = true;
  }

  private paymentFormSetup(): void {
    const functionName: string = `paymentFormSetup`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website paymentMethodsArray[0]`, this.paymentMethodsArray[0].paymentMethod);

    this.paymentForm = this.formBuilder.group({
      // payment_method: ['Cash', [Validators.required]]
      payment_method: [this.paymentMethodsArray[0].paymentMethod, [Validators.required]]
    });
    this.subscriptions.push(
      this.paymentForm.statusChanges.subscribe(this.paymentFormStatusChanged.bind(this))
    );
  }

  public paymentFormStatusChanged(status: any): void {
    const functionName: string = `paymentFormStatusChanged`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website paymentForm.status`, status);
    console.log(`${logPath}/ @Website paymentForm.dirty`, this.paymentForm.dirty);
    console.log(`${logPath}/ @Website paymentForm.value`, this.paymentForm.value);

    // (this.paymentForm.dirty && this.paymentForm.valid) ? this.isSubmitButtonDisabled = false : this.isSubmitButtonDisabled = true;
  }

  public onCountryChange(_event: any): void {
    const functionName: string = `onCountryChange`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website _event`, _event);
    // console.log(`${logPath}/ @Website transferForm.get('mobile').value`, this.transferForm.get('mobile')!.value);

    this.transferForm.get('mobile')!.patchValue('');
  }

  public formLoading(loading: boolean): void {
    const functionName: string = `formLoading`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website`);
    if (loading) {
      this.quoteForm.disable();
    } else {
      this.quoteForm.enable();
    }
  }

  public handleFromAddressChange(address: Address, _formGroup: FormGroup): void {
    const functionName: string = `handleFromAddressChange`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website - address`, address);
    console.log(`${logPath}/ @Website - _formGroup`, _formGroup);
    console.log(`${logPath}/ @Website - address.formatted_address`, address.formatted_address);
    console.log(`${logPath}/ @Website - address.name`, address.name);
    console.log(`${logPath}/ @Website - address.geometry.location.lat()`, address.geometry.location.lat());
    console.log(`${logPath}/ @Website - address.geometry.location.lng()`, address.geometry.location.lng());

    // this.quoteForm.controls['from'].patchValue(address.formatted_address);
    // this.quoteForm.controls['from'].patchValue(address.name);
    // _formGroup.controls['from'].patchValue(address.name);
    // _formGroup.controls['from'].patchValue(address.formatted_address);
    _formGroup.controls['from'].patchValue(this.formatAddress(address));
    this.markerFromLat = address.geometry.location.lat();
    this.markerFromLng = address.geometry.location.lng();
    console.warn(`${logPath}/ @Website markerFromLat`, this.markerFromLat);
    console.warn(`${logPath}/ @Website markerFromLng`, this.markerFromLng);
  }

  public handleFromAddressChange2(_selectedResult: any, _formGroup: FormGroup): void {
    const functionName: string = `handleFromAddressChange`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    console.log(`${logPath}/ @Website - address `, _selectedResult);

    _formGroup.controls['from'].patchValue(_selectedResult.address);

    this.markerFromLat = _selectedResult.latitude;
    this.markerFromLng = _selectedResult.longitude;
    console.warn(`${logPath}/ @Website markerFromLat`, this.markerFromLat);
    console.warn(`${logPath}/ @Website markerFromLng`, this.markerFromLng);
    // console.log(`${logPath}/ @Website - _formGroup`, _formGroup);
  }

  public handleToAddressChange(address: Address, _formGroup: FormGroup): void {
    const functionName: string = `handleToAddressChange`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website - address`, address);
    console.log(`${logPath}/ @Website - _formGroup`, _formGroup);
    console.log(`${logPath}/ @Website address.formatted_address`, address.formatted_address);
    console.log(`${logPath}/ @Website address.name`, address.name);
    console.log(`${logPath}/ @Website address.geometry.location.lat()`, address.geometry.location.lat());
    console.log(`${logPath}/ @Website address.geometry.location.lng()`, address.geometry.location.lng());

    // this.quoteForm.controls['to'].patchValue(address.formatted_address);
    // this.quoteForm.controls['to'].patchValue(address.name);
    // _formGroup.controls['to'].patchValue(address.name);
    // _formGroup.controls['to'].patchValue(address.formatted_address);
    _formGroup.controls['to'].patchValue(this.formatAddress(address));
    this.markerToLat = address.geometry.location.lat();
    this.markerToLng = address.geometry.location.lng();
  }

  public handleToAddressChange2(_selectedResult: any, _formGroup: FormGroup): void {
    const functionName: string = `handleToAddressChange2`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    console.log(`${logPath}/ @Website - address `, _selectedResult);

    _formGroup.controls['to'].patchValue(_selectedResult.address);

    this.markerToLat = _selectedResult.latitude;;
    this.markerToLng = _selectedResult.longitude;
  }

  public dateChanged(_event: any, _formGroup: FormGroup): void {
    const functionName: string = `dateChanged`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website quoteForm.get('time').value`, this.quoteForm.get('time')!.value);
    console.log(`${logPath}/ @Website quoteForm.get('date').value`, this.quoteForm.get('date')!.value);

    console.log(`${logPath}/@Website - _event`, _event);
    console.log(`${logPath}/@Website - _formGroup`, _formGroup);

    // this.dateView = moment(this.quoteForm.get('date')!.value).format('DD MMMM, ddd');
    this.dateView = moment(_formGroup.get('date')!.value).format('DD MMMM, ddd');
    // this.datePost = moment(this.quoteForm.get('date')!.value).format('YYYY-MM-DD');
    this.datePost = moment(_formGroup.get('date')!.value).format('YYYY-MM-DD');
    console.warn(`${logPath}/ @Website dateView`, this.dateView);
    console.warn(`${logPath}/ @Website datePost`, this.datePost);

    // (moment(this.quoteForm.get('date')!.value).toISOString() === this.nowFuture.toISOString()) ?
    (moment(_formGroup.get('date')!.value).toISOString() === this.nowFuture.toISOString()) ?
      // (this.quoteForm.get('time')!.patchValue(undefined), this.calculateAvailableHours(true), this.isTodaySelected = true) :
      (_formGroup.get('time')!.patchValue(undefined), this.calculateAvailableHours(true), this.isTodaySelected = true) :
      (this.isTodaySelected && this.calculateAvailableHours(false));
  }

  public editLuggagePieces(_action: string, _formGroup: FormGroup): void {
    const functionName: string = `editLuggagePieces`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website quoteForm.controls['luggage'].value`, this.quoteForm.controls['luggage'].value);
    console.log(`${logPath}/@Website - _action`, _action);
    console.log(`${logPath}/@Website - _formGroup`, _formGroup);


    // let _luggagePieces: number = this.quoteForm.controls['luggage'].value;
    let _luggagePieces: number = _formGroup.controls['luggage'].value;
    _action === 'increase' ?
      (
        _luggagePieces += 1
      ) :
      (
        (_luggagePieces > 0) && (_luggagePieces -= 1)
      );
    // this.quoteForm.controls['luggage'].patchValue(_luggagePieces);
    _formGroup.controls['luggage'].patchValue(_luggagePieces);

    _formGroup.markAsDirty();

    // console.log(`${logPath}/ @Website quoteForm.controls['luggage'].value`, this.quoteForm.controls['luggage'].value);
  }

  public editPassengers(_action: string, _formGroup: FormGroup): void {
    const functionName: string = `editPassengers`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website quoteForm.controls['passengers'].value`, this.quoteForm.controls['passengers'].value);
    console.log(`${logPath}/@Website - _action`, _action);
    console.log(`${logPath}/@Website - _formGroup`, _formGroup);

    // let _passengers: number = this.quoteForm.controls['passengers'].value;
    let _passengers: number = _formGroup.controls['passengers'].value;
    _action === 'increase' ?
      (
        _passengers += 1
      ) :
      (
        (_passengers > 1) && (_passengers -= 1)
      );
    // this.quoteForm.controls['passengers'].patchValue(_passengers);
    _formGroup.controls['passengers'].patchValue(_passengers);

    _formGroup.markAsDirty();

    // console.log(`${logPath}/ @Website quoteForm.controls['passengers'].value`, this.quoteForm.controls['passengers'].value);
  }

  public editChildSeats(_action: string): void {
    const functionName: string = `editChildSeats`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website transferForm.controls['childSeats'].value`, this.transferForm.controls['childSeats'].value);

    let _childSeats: number = this.transferForm.controls['childSeats'].value;
    _action === 'increase' ?
      (
        _childSeats += 1
      ) :
      (
        (_childSeats > 0) && (_childSeats -= 1)
      );
    this.transferForm.controls['childSeats'].patchValue(_childSeats);
    this.transferForm.controls['childSeats'].markAsDirty;
    // console.log(`${logPath}/ @Website quoteForm.controls['childSeats'].value`, this.quoteForm.controls['childSeats'].value);
  }

  public toggleOrderSummaryVisibility(): void {
    const functionName: string = `toggleOrderSummaryVisibility`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website `);

    this.isOrderSummaryVisible = !this.isOrderSummaryVisible;
  }

  public toggleRouteVisibility(): void {
    const functionName: string = `toggleRouteVisibility`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website `);

    this.isRouteVisible = !this.isRouteVisible;
  }

  public moveAway(_event: any) {
    const functionName: string = `moveAway`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website _event`, _event);
  }

  private getBounds(markers: any[]): any {
    const functionName: string = `getBounds`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website markers`, markers);

    let north;
    let south;
    let east;
    let west;

    for (const marker of markers) {
      // set the coordinates to marker's lat and lng on the first run.
      // if the coordinates exist, get max or min depends on the coordinates.
      north = north !== undefined ? Math.max(north, marker.position.lat) : marker.position.lat;
      console.log(`${logPath}/ @Website north`, north);
      south = south !== undefined ? Math.min(south, marker.position.lat) : marker.position.lat;
      console.log(`${logPath}/ @Website south`, south);
      east = east !== undefined ? Math.max(east, marker.position.lng) : marker.position.lng;
      console.log(`${logPath}/ @Website east`, east);
      west = west !== undefined ? Math.min(west, marker.position.lng) : marker.position.lng;
      console.log(`${logPath}/ @Website west`, west);
    };

    const bounds = { north, south, east, west };
    return bounds;
  }

  private constructRequestBody(): FormData {
    const functionName: string = `constructRequestBody`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website`);

    const formData = new FormData();

    // extract keys of forms
    let quoteFormFieldsArray: string[] = [];
    Object.keys(this.quoteForm.controls).forEach(key => {
      console.log(`${logPath}/ @Website quoteForm.get(${key})?.valid`, this.quoteForm.get(key)?.valid);
      quoteFormFieldsArray.push(key)
    });
    console.log(`${logPath}/ @Website quoteFormFieldsArray`, quoteFormFieldsArray);

    let transferFormFieldsArray: string[] = [];
    Object.keys(this.transferForm.controls).forEach(key => {
      console.log(`${logPath}/ @Website transferForm.get(${key})?.valid`, this.transferForm.get(key)?.valid);
      transferFormFieldsArray.push(key)
    });
    console.log(`${logPath}/ @Website transferFormFieldsArray`, transferFormFieldsArray);

    let paymentFormFieldsArray: string[] = [];
    Object.keys(this.paymentForm.controls).forEach(key => {
      console.log(`${logPath}/ @Website paymentForm.get(${key})?.valid`, this.paymentForm.get(key)?.valid);
      paymentFormFieldsArray.push(key)
    });
    console.log(`${logPath}/ @Website paymentFormFieldsArray`, paymentFormFieldsArray);

    // construct request bodies
    let postQuoteRequestBody: PostTransferRequestBody = Object.assign(
      new PostTransferRequestBody(),
      ObjectHelper.only(this.quoteForm.value, quoteFormFieldsArray)
    );
    console.log(`${logPath}/ @Website postQuoteRequestBody`, postQuoteRequestBody);

    let postTransferRequestBody: PostTransferRequestBody = Object.assign(
      new PostTransferRequestBody(),
      ObjectHelper.only(this.transferForm.value, transferFormFieldsArray)
    );
    console.log(`${logPath}/ @Website postTransferRequestBody`, postTransferRequestBody);

    let postPaymentRequestBody: any = Object.assign(
      new PostTransferRequestBody(),
      ObjectHelper.only(this.paymentForm.value, paymentFormFieldsArray)
    );

    // let postPaymentRequestBody: any;
    // this.paymentMethodsArray.forEach((paymentMethod: any) => {
    //   console.log(`${logPath}/ @Website paymentMethod`, paymentMethod);
    //   paymentMethod.checked && (postPaymentRequestBody = paymentMethod.paymentMethod);
    // });
    // console.log(`${logPath}/ @Website postPaymentRequestBody`, postPaymentRequestBody);

    let dateAccessed: boolean = false;
    let timeAccessed: boolean = false;
    let date: string = '';
    let time: string = '';

    // Assign values to formData
    Object.entries(postQuoteRequestBody).forEach(([key, value], index) => {
      console.log(`${logPath}/@Website key`, key);
      console.log(`${logPath}/@Website value`, value);
      console.log(`${logPath}/@Website index`, index);

      (key !== 'date' && key !== 'time' && key !== 'luggage') && formData.append(`one_way[${key}]`, value);

      // luggages instead of luggage due to typo
      // key === 'luggage' && (formData.append(`one_way[${key}]`, value));
      key === 'luggage' && (formData.append(`one_way[luggages]`, value));

      key === 'date' && (date = moment(value).format('YYYY-MM-DD'), dateAccessed = true);
      key === 'date' && console.log(`${logPath}/ %c@Website date`, 'color: black; background: yellow; font-size: 10px', date);

      key === 'time' && (time = value, timeAccessed = true);
      key === 'time' && console.log(`${logPath}/ %c@Website time`, 'color: black; background: yellow; font-size: 10px', time);
    })

    if (dateAccessed && timeAccessed) {
      formData.append(`one_way[datetime]`, `${date} ${time}`);
    }

    let specialInstructions: string = ``;
    Object.entries(postTransferRequestBody).forEach(([key, value], index) => {
      console.log(`${logPath}/ @Website key`, key);
      console.log(`${logPath}/ @Website value`, value);
      console.log(`${logPath}/ @Website index`, index);

      (key !== 'passport' && key !== 'mobile' && key !== 'childSeats' && key !== 'special_instructions' && key !== 'voucher') && formData.append(`client[${key}]`, value);

      // PASSPORT
      key === 'passport' && formData.append(`one_way[${key}]`, value);

      // VOUCHER
      key === 'voucher' && formData.append(`one_way[${key}]`, value);

      // MOBILE
      key === 'mobile' && formData.append(`client[phone]`, value.e164Number);

      // CHILD SEATS
      if (key === 'childSeats') {
        formData.append(`one_way[child_seats]`, value);
        // if (this.transferForm.controls['childSeats'].dirty && this.transferForm.controls['childSeats'].value > 0) {
        if (this.transferForm.controls['childSeats'].value > 0) {
          specialInstructions = `Child seats: ${this.transferForm.controls['childSeats'].value}`;
        }
        console.log(`${logPath}/ @Website specialInstructions`, specialInstructions);
      };

      // SPECIAL INSTRUCTIONS
      if (key === 'special_instructions') {
        if (this.transferForm.controls['special_instructions'].dirty && this.transferForm.controls['special_instructions'].valid && this.transferForm.controls['special_instructions'].value !== null) {
          if (specialInstructions !== ``) {
            formData.append(`one_way[${key}]`, specialInstructions + '. \n' + value);
          } else {
            formData.append(`one_way[${key}]`, value);
          }
        } else {
          formData.append(`one_way[${key}]`, specialInstructions);
        }
      }
    })

    // Object.entries(postPaymentRequestBody).forEach(([key, value], index) => {
    //   console.log(`${logPath}/ @Website key`, key);
    //   console.log(`${logPath}/ @Website value`, value);
    //   console.log(`${logPath}/ @Website index`, index);

    //   formData.append(`one_way[${key}]`, value);
    // })

    console.log(`${logPath}/ @Website postPaymentRequestBody.payment_method`, postPaymentRequestBody.payment_method);
    // post transfer and thank you screen
    // postPaymentRequestBody.payment_method === 'Cash' && (
    //   // formData.append(`one_way[status]`, 'upcoming')
    //   formData.append(`one_way[payment_method]`, 'cache')
    // )

    formData.append(`one_way[from_latitude]`, `${this.from_latitude}`),
      formData.append(`one_way[from_longitude]`, `${this.from_longitude}`),
      formData.append(`one_way[to_latitude]`, `${this.to_latitude}`),
      formData.append(`one_way[to_longitude]`, `${this.to_longitude}`),

      // Vehicle Id
      this.selectedVehicleId !== -1 && (
        formData.append(`one_way[vehicle_type_id]`, `${this.selectedVehicleId}`)
      );

    // CASH
    this.cashIsSelected && (
      // formData.append(`one_way[payment_method]`, 'cash'),
      formData.append(`payment_method`, 'cash')
      // formData.append(`one_way[offer_id]`, `${this.offerId}`)
      // 17/11
      // formData.append(`offer_id`, `${this.offerId}`)
    );

    // CARD TO DRIVER
    this.cardToDriverIsSelected && (
      formData.append(`payment_method`, 'credit_card_to_driver')
      // 17/11
      // formData.append(`offer_id`, `${this.offerId}`)
    );

    // CREDIT CARD
    this.cardIsSelected && (
      // formData.append(`one_way[subtotal]`, '0'),
      // formData.append(`one_way[subtotal_status]`, 'paid'),
      // formData.append(`one_way[subtotal_method]`, 'card'),
      // formData.append(`one_way[advance_payment_method]`, 'cash'),
      // formData.append(`one_way[advance_payment]`, `${this.offerPrice}`),
      // formData.append(`one_way[advance_payment_status]`, 'unpaid'),
      // formData.append(`one_way[payment_method]`, 'card')

      // formData.append(`one_way[payment_method]`, 'card'),
      formData.append(`payment_method`, 'card')
      // formData.append(`one_way[offer_id]`, `${this.offerId}`)
      // 17/11
      // formData.append(`offer_id`, `${this.offerId}`)
    )

    // DEPOSIT
    // const total: number = +this.offerPrice;
    // const deposit: number = total * this.depositPercentage;
    // const subtotal: number = total - deposit;
    // const subtotal: number = total - this.depositPrice;
    this.depositIsSelected && (
      // formData.append(`one_way[subtotal]`, `${subtotal}`),
      // formData.append(`one_way[subtotal_status]`, 'unpaid'), 
      // formData.append(`one_way[subtotal_method]`, 'cash'),
      // formData.append(`one_way[advance_payment_method]`, 'card'),
      // formData.append(`one_way[advance_payment]`, `${deposit}`),
      // formData.append(`one_way[advance_payment]`, `${this.depositPrice}`),
      // formData.append(`one_way[advance_payment_status]`, 'paid'),
      // formData.append(`one_way[payment_method]`, 'deposit')

      // formData.append(`one_way[payment_method]`, 'deposit'),
      formData.append(`payment_method`, 'deposit')
      // formData.append(`one_way[offer_id]`, `${this.offerId}`)
      // 17/11
      // formData.append(`offer_id`, `${this.offerId}`)
    )

    // OFFER REQUEST
    this.offerRequestIsSelected && (
      // formData.append(`one_way[payment_method]`, 'offer_request'),
      formData.append(`payment_method`, 'offer_request')
      // formData.append(`one_way[offer_id]`, `${this.offerId}`)
      // 17/11
      // formData.append(`offer_id`, `${this.offerId}`)
    );

    //  OFFER ID
    //  this.offerId && (
    //   formData.append(`offer_id`, `${this.offerId}`)
    // );


    formData.append(`one_way[status]`, 'upcoming');
    // formData.append(`one_way[total_price]`, `${this.offerPrice}`);
    if (!this.oneWayOfferRequestForced) {
      if (this.cardIsSelected) {
        formData.append(`one_way[total_price]`, `${this.discountedPrice}`);
      }
      else {
        formData.append(`one_way[total_price]`, `${this.offerPrice}`);
      }
    }

    this.supplierId && (formData.append(`one_way[supplier_id]`, `${this.supplierId}`));

    // Display the key/value pairs
    for (var pair of formData.entries()) {
      console.log(`${logPath}/ %c@Website ${pair[0]} of formData:`, 'color: black; background: pink; font-size: 10px', pair[1]);
    }
    return formData;
  }

  private constructCostCalculationData(): any {
    const functionName: string = `constructCostCalculationData`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website quoteForm.status`, this.quoteForm.status);
    console.log(`${logPath}/ @Website quoteForm.dirty`, this.quoteForm.dirty);
    console.log(`${logPath}/ @Website quoteForm.value`, this.quoteForm.value);

    // console.warn(`${logPath}/ @Website datePost`, this.datePost);
    console.warn(`${logPath}/ @Website markerFromLat`, this.markerFromLat);
    console.warn(`${logPath}/ @Website markerFromLng`, this.markerFromLng);
    console.warn(`${logPath}/ @Website markerToLat`, this.markerToLat);
    console.warn(`${logPath}/ @Website markerToLng`, this.markerToLng);
    // console.warn(`${logPath}/ @Website quoteForm.controls['passengers'].value`, this.quoteForm.controls['passengers'].value);
    // console.warn(`${logPath}/ @Website quoteForm.controls['time'].value`, this.quoteForm.controls['time'].value);
    // console.warn(`${logPath}/ @Website this.datePost this.quoteForm.controls['time'].value`, `${this.datePost} ${this.quoteForm.controls['time'].value}`);

    let getCostCalculationData: GetCostCalculationData = {
      from_latitude: this.markerFromLat,
      from_longitude: this.markerFromLng,
      to_latitude: this.markerToLat,
      to_longitude: this.markerToLng,
      passengers: this.quoteForm.controls['passengers'].value,
      luggages: this.quoteForm.controls['luggage'].value,
      datetime: `${this.datePost} ${this.quoteForm.controls['time'].value}`
    };
    return getCostCalculationData;
  }

  public onContinueButtonClick() {
    const functionName: string = `onContinueButtonClick`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    this.selectedStep = "Vehicles";

    Object.keys(this.quoteForm.controls).forEach(field => {
      let control = this.quoteForm.get(field);
      control?.markAsTouched();
    });
    const postData: any = this.constructCostCalculationData();
    this.from_latitude = postData.from_latitude;
    this.from_longitude = postData.from_longitude;
    this.to_latitude = postData.to_latitude;
    this.to_longitude = postData.to_longitude;
    console.warn(`${logPath}/ @Website from_latitude`, this.from_latitude);
    console.warn(`${logPath}/ @Website from_longitude`, this.from_longitude);
    console.warn(`${logPath}/ @Website to_latitude`, this.to_latitude);
    console.warn(`${logPath}/ @Website to_longitude`, this.to_longitude);

    // console.warn(`${logPath}/ @Website branchId`, this.branchId);
    // console.warn(`${logPath}/ @Website postData`, postData);

    this.noVehiclesFlag = false;
    this.loading = true;
    this.vehicleTypeListLoading = true;
    this.oneWayOfferRequestForced = false;
    this.formLoading(this.loading);
    this.createMaps();
    this.websiteWidgetService.postTransferCost({ widget_slag: this.slag, one_way: this.constructCostCalculationData() }).subscribe(
      (response: any) => {
        // this.offerId = response.offer_id;
        console.log(`${logPath}/ @Website offerId`, this.offerId);
        console.log(`${logPath}/ @Response`, response);
        // condition for step2
        if (this.isSecondStepAvailable) {
          this.vehicleTypeFormSetup();
          this.loadVehiclesLogic(response);
          if (response.offers.response) {
            this.fetchOfferRequest(this.stepper.selectedIndex);
          }
          else {
            this.stepper.next();
          }
        }
        else {
          this.stepper.next();
          this.offerPrice = response.offer.response.calculation.total.toFixed(2);

          response.offer.response.calculation.deposit !== undefined && (
            this.depositPrice = response.offer.response.calculation.deposit.toFixed(2),
            this.depositPriceRaw = response.offer.response.calculation.deposit
          );

          if (this.discountPercentage > 0) {
            this.discountedPrice = +this.offerPrice - (+this.offerPrice) * (this.discountPercentage / 100);
            this.discountedPrice = this.discountedPrice.toString();
          }
          else {
            this.discountedPrice = this.offerPrice;
          }

          response.offer.response.calculation.offer_request_forced === true && (this.oneWayOfferRequestForced = true,
            this.onPaymentMethodChange({ value: 'Offer Request' }),
            this.paymentMethodsArray.forEach((paymentMethod: any, index: number) => {
              // paymentMethod !== 'Offer Request' && this.paymentMethodsArray.splice(0, 1);
              paymentMethod === 'Offer Request' && (paymentMethod.checked = true);
            })
          );

          // this.offerId = response.offer.offer_id;
          this.offerId = response.offer_id;
          console.log(`${logPath}/ @Website offerId`, this.offerId);

          this.vehicleTypeListLoading = false;
          this.loading = false;
          this.formLoading(this.loading);
        }
        if (window.innerWidth < 600) {
          this.titleState = this.emptyState;
        }
        else {
          this.titleState = this.fullstate;
        }

      },
      (error: any) => {
        console.log(`${logPath}/ @Website error`, error);
        this.loading = false;
        this.vehicleTypeListLoading = false;
        this.formLoading(this.loading);
        // notify user about the error
        // let errorMessage: string = 'Oops something went wrong';
        // this.snackBar.open(errorMessage, '', {duration: 3000});
      }
    );
  }

  private fetchVehicleTypeImage(_url: string, _index: number): void {
    const functionName: string = `fetchVehicleTypeImage`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/@Website - _url`, _url);
    this.loading = true;
    this.vehicleTypeListLoading = true;
    this.websiteWidgetService.getVehicleTypeImage(_url).subscribe(
      (response: any) => {
        console.log(`${logPath}/@Website - response`, response);

        const blobImage: any = response.body;
        const dummyProfilePicPath: any = <string>this.domSanitizer.bypassSecurityTrustUrl(ImageHelper.blobToUrl(blobImage));

        Object.defineProperty(this.vehicleTypesArray[_index], 'img', {
          value: dummyProfilePicPath,
          writable: true,
          enumerable: true,
          configurable: true,
        });

      },
      (error: any) => {
        console.log(`${logPath}/@NurseProfile - error`, error);
        this.loading = false;
        this.formLoading(this.loading);
        this.vehicleTypeListLoading = false;
      }
    );
  }

  private fetchLogoImage(_url: string): void {
    const functionName: string = `fetchLogoImage`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/@Website - _url`, _url);

    this.websiteWidgetService.getLogoImage(_url).subscribe(
      (response: any) => {
        console.log(`${logPath}/@Website - response`, response);
        this.logoImage1 = response.body;
        this.logoImage2 = <string>this.domSanitizer.bypassSecurityTrustUrl(ImageHelper.blobToUrl(this.logoImage1));
        this.logoImageReady = true;
      },
      (error: any) => {
        if (error.status === 400) {
          this.noLogo = true;
        }
        console.log(`${logPath}/@NurseProfile - error`, error);
        this.loading = false;
        this.vehicleTypeListLoading = false;
      }
    );
  }

  public onFindYourTransferClick(): void {
    this.noVehiclesFlag = false;
    const functionName: string = `onFindYourTransferClick`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/@Website - vehicleTypeForm.value`, this.vehicleTypeForm.value);

    this.vehicleTypeForm.markAsUntouched();
    this.vehicleTypeForm.markAsPristine();

    this.quoteForm.patchValue(this.vehicleTypeForm.value);
    console.log(`${logPath}/@Website - quoteForm.value`, this.quoteForm.value);

    this.initialFrom = this.vehicleTypeForm.get('from')?.value;
    this.initialTo = this.vehicleTypeForm.get('to')?.value;
    this.initialTime = this.vehicleTypeForm.get('time')?.value;

    this.oneWayOfferRequestForced = false;
    this.loading = true;
    this.vehicleTypeListLoading = true;
    this.formLoading(this.loading);
    this.vehiclesCounter = 0;
    this.createMaps();
    this.websiteWidgetService.postTransferCost({ widget_slag: this.slag, one_way: this.constructCostCalculationData() }).subscribe(
      (response: any) => {
        console.log(`${logPath}/ @Website - response`, response);
        this.loadVehiclesLogic(response);
        if (response.offers.response) {
          this.fetchOfferRequest(this.stepper.selectedIndex);
        }
      },
      (error: any) => {
        console.log(`${logPath}/ @Website - error`, error);

        if (error.error.message === 'Route not found') {
          this.vehicleTypesArray.splice(0, this.vehicleTypesArray.length);
        }

        this.loading = false;
        this.vehicleTypeListLoading = false;
        this.formLoading(this.loading);
        // notify user about the error
        // let errorMessage: string = 'Oops something went wrong';
        // this.snackBar.open(errorMessage, '', {duration: 3000});
      }
    );
  }

  public selectVehicleType(_vehicleType: any, _index: any): void {
    const functionName: string = `selectVehicleType`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/@Website - _vehicleType`, _vehicleType);
    console.log(`${logPath}/@Website - _index G931`, _index);

    this.step1.completed = true;

    this.selectedVehicleType = _vehicleType;
    this.selectedVehicleId = _vehicleType.offer.request.one_way.vehicle_type_id;
    console.log(`${logPath}/@Website - selectedVehicleType`, this.selectedVehicleType);

    this.offerId = _vehicleType.offer_id;
    console.log(`${logPath}/@Website - offerId`, this.offerId);

    this.offerPrice = _vehicleType.offer.response.calculation.total.toFixed(2);

    if (this.discountPercentage > 0) {
      this.discountedPrice = +this.offerPrice - (+this.offerPrice) * (this.discountPercentage / 100);
      this.discountedPrice = this.discountedPrice.toString();
    }
    else {
      this.discountedPrice = this.offerPrice;
    }

    _vehicleType.offer.response.calculation.deposit !== undefined && (
      this.depositPrice = _vehicleType.offer.response.calculation.deposit.toFixed(2),
      this.depositPriceRaw = _vehicleType.offer.response.calculation.deposit
    );

    _vehicleType.offer.response.calculation.offer_request_forced === true && (this.oneWayOfferRequestForced = true,
      this.onPaymentMethodChange({ value: 'Offer Request' }),
      this.paymentMethodsArray.forEach((paymentMethod: any, index: number) => {
        // paymentMethod !== 'Offer Request' && this.paymentMethodsArray.splice(0, 1);
        paymentMethod === 'Offer Request' && (paymentMethod.checked = true);
      })
    );

    // this.loading = false;
    // this.formLoading(this.loading);
    this.stepper.next();
  }

  public onContinueButtonClick2() {
    this.transferForm.controls['mobile'].markAsTouched();
    const functionName: string = `onContinueButtonClick2`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website quoteForm.value`, this.quoteForm.value);
    console.log(`${logPath}/ @Website transferForm.value`, this.transferForm.value);
    console.warn(`${logPath}/ @Website branchId`, this.branchId);
    console.log(`${logPath}/ @Website cashIsSelected`, this.cashIsSelected);
    console.log(`${logPath}/ @Website cardToDriverIsSelected`, this.cardToDriverIsSelected);
    console.log(`${logPath}/ @Website depositIsSelected`, this.depositIsSelected);

    this.cardIsSelected && (this.finalPrice = this.offerPrice);
    this.depositIsSelected && (this.finalPrice = this.depositPrice);

    const formData: any = this.constructRequestBody();
    for (var pair of formData.entries()) {
      console.log(`${logPath}/ %c@Website ${pair[0]} of formData: `, 'color: black; background: pink; font-size: 10px', pair[1]);
    }

    this.loading = true;
    this.formLoading(this.loading);

    this.websiteWidgetService.postTransfer(this.branchId, this.constructRequestBody()).subscribe(
      (response: any) => {
        this.postTransferResponse = response;
        this.transferId = response.transfers[0].id;
        console.log(`${logPath}/ @Website transferId`, response);
        if (this.cardIsSelected || this.depositIsSelected) {
          this.fetchStripeClientSecret(this.postTransferResponse.transfers[0].id);
        } else {
          // cash scenario
          // offer request scenario
        }
        this.loading = false;
        this.formLoading(this.loading);
        setTimeout(() => {
          this.postTransferResolved = true;
        }, 500);
      },
      (error: any) => {
        console.log(`${logPath}/ @Website error`, error);
        this.postTransferResolved = false;
        this.loading = false;
        this.formLoading(this.loading);

        // notify user about the error
        // let errorMessage: string = 'Oops something went wrong';
        // this.snackBar.open(errorMessage, '', {duration: 3000});
      }
    );
  }

  public fetchWidgetInfo(_slag: string): void {
    const functionName: string = `fetchWidgetInfo`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/@Website - _slag`, _slag);

    this.loading = true;
    // this.formLoading(this.loading);

    this.websiteWidgetService.getWidgetInfo(_slag).subscribe(
      (response: any) => {
        console.log(`${logPath}/@Website - response`, response);
        this.logo_url = response.widget_logo;
        this.suggestedList = response.addresses_suggestion;
        if (this.logo_url) {
          this.fetchLogoImage(this.logo_url);
        }
        this.branchId = response.branch_id;
        this.supplierId = response.supplier_id;
        if (response.calculation_method !== 'passenger') {
          this.isSecondStepAvailable = true;
          this.stepMarginer = '0px';
        }
        else {
          this.stepMarginer = '23px';
        }
        //PAYMENT METHODS FETCH
        response.payment_method_cash === 1 && this.paymentMethodsArray.push({ paymentMethod: 'Cash' });
        if (response.payment_method_card === 1) {
          this.paymentMethodsArray.push({ paymentMethod: 'Credit Card' });

          //DISCOUNT
          response.has_discount_card_payment &&
            (this.discountPercentage = response.discount_card_payment_percentage);
        }
        else {
          this.discountPercentage = 0;
        }
        // response.branch.offer_request === 1 && this.paymentMethodsArray.push({paymentMethod: 'Offer Request'});
        response.payment_method_card_driver === 1 && this.paymentMethodsArray.push({ paymentMethod: 'Credit card to the driver' });
        response.payment_method_offer === 1 && this.paymentMethodsArray.push({ paymentMethod: 'Offer Request' });
        this.depositPercentage = +response.payment_method_deposit_percentage;
        console.log(`${logPath}/ @Website depositPercentage`, this.depositPercentage);
        // this.depositFixed = +response.branch.deposit_fixed;
        console.log(`${logPath}/ @Website depositFixed`, this.depositFixed);
        (response.payment_method_deposit === 1 && !(this.depositPercentage === 0 && this.depositFixed === 0))
          && this.paymentMethodsArray.push({ paymentMethod: 'Deposit' });

        //PINNED ADDRESSES FETCH
        if (response.pin_address_latitude !== undefined && response.pin_address_longitude !== undefined) {
          if (this.supplierId !== undefined &&
            (+this.branchId === 49 &&
              (this.supplierId === 146 || this.supplierId === 147 || this.supplierId === 149 || this.supplierId === 150 || this.supplierId === 151))) {
            this.pinnedAddressMode = true;
            this.isToFieldDisabled = true;
            this.fetchNewSupplierPinnedAddress(response.pin_address_address, response.pin_address_latitude, response.pin_address_longitude);
          }
          else if (this.supplierId !== undefined) {
            this.pinnedAddressMode = true;
            this.fetchNewSupplierPinnedAddress(response.pin_address_address, response.pin_address_latitude, response.pin_address_longitude);
          }
        }

        //sooner minutes fetch
        this.sameDayMinutesMargin = response.sooner_minutes;
        console.log(`${logPath}/@Website - sameDayMinutesMargin`, this.sameDayMinutesMargin);
        console.log(`${logPath}/@Website - Discount Percentage`, this.discountPercentage);


        this.fetchPaymentMethods();

        this.loading = false;
        // this.formLoading(this.loading);
      },
      (error: any) => {
        console.log(`${logPath}/@Website - error`, error);
        this.loading = false;
        // this.formLoading(this.loading);
        // notify user about the error
        // let errorMessage: string = 'Oops something went wrong';
        // this.snackBar.open(errorMessage, '', {duration: 3000});
      }
    );
  }

  public postTransferCost(): void {
    const functionName: string = `postTransferCost`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website `);
  }

  public fetchPaymentMethods(): void {
    const functionName: string = `fetchPaymentMethods`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    // console.log(`${logPath}/ @Website `);

    this.loading = true;
    this.formLoading(this.loading);

    this.websiteWidgetService.getPaymentMethods(this.branchId).subscribe(
      (response: any) => {

        console.log(`${logPath}/@Website - response.branch.address_autocomplete_bounds`, response.branch.address_autocomplete_bounds);

        console.log(`${logPath}/@Website - response.branch.transfer_sooner_minutes`, response.branch.transfer_sooner_minutes);
        // this.sameDayMinutesMargin = response.branch.transfer_sooner_minutes;
        // console.log(`${logPath}/@Website - sameDayMinutesMargin`, this.sameDayMinutesMargin);
        if (!this.logo_url) {
          this.logo_url = response.branch.image;
          if (this.logo_url) {
            this.fetchLogoImage(this.logo_url);
          }
          else {
            this.logoImage2 = "./assets/img/logo/iconic_master_logo.png";
            this.logoImageReady = true;
          }
        }
        // this.fetchLogoImage(response.branch.image);

        this.loading = false;
        this.formLoading(this.loading);

        // this.options.bounds = response.branch.address_autocomplete_bounds;

        response.branch.address_autocomplete_bounds !== null ?
          (this.options.bounds = response.branch.address_autocomplete_bounds) :
          (this.options.bounds = { "north": 41.748986, "south": 34.802075, "east": 29.647743, "west": 19.247998 });

        console.log(`${logPath}/ @Website paymentMethodsArray`, this.paymentMethodsArray);

        let value: boolean = false;
        this.paymentMethodsArray.forEach((paymentMethod: any, index: any) => {
          index === 0 ? (value = true) : (value = false);
          this.oneWayOfferRequestForced === true && (value = true);
          Object.defineProperty(paymentMethod, 'checked', {
            value: value,
            writable: true,
            enumerable: true,
            configurable: true,
          })
          // console.log(`${logPath}/ @Website paymentMethod G979`, paymentMethod);
        })

        // console.log(`${logPath}/ @Website paymentMethod Array G979`, this.paymentMethodsArray);


        this.onPaymentMethodChange({ value: this.paymentMethodsArray[0].paymentMethod });
        // console.log(`${logPath}/ @Bug oneWayOfferRequestForced`, this.oneWayOfferRequestForced);
        // this.oneWayOfferRequestForced && this.onPaymentMethodChange({value: this.paymentMethodsArray[this.paymentMethodsArray.length-1].paymentMethod});
        console.log(`${logPath}/ @Website paymentMethodsArray`, this.paymentMethodsArray);

        if (response.credit_card_gateway !== null) {
          this.connectedAccount = response.credit_card_gateway.connected_account;
          this.publishableApiKey = response.credit_card_gateway.publishable_api_key;
        }
        this.paymentFormSetup();

        // this.loading = false;
        // this.formLoading(this.loading);
      },
      (error: any) => {
        console.log(`${logPath}/ @Website error`, error);
        this.loading = false;
        this.formLoading(this.loading);
        // notify user about the error
        // let errorMessage: string = 'Oops something went wrong';
        // this.snackBar.open(errorMessage, '', {duration: 3000});
      }
    );
  }

  public fetchStripeClientSecret(_transferId: number): void {
    const functionName: string = `fetchStripeClientSecret`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website `);

    this.loading = true;
    this.formLoading(this.loading);

    this.websiteWidgetService.getStripeClientSecret(this.branchId, _transferId).subscribe(
      (response: any) => {
        console.log(`${logPath}/ @Website response`, response);

        this.loading = false;
        this.formLoading(this.loading);

        this.fetchStripeClientSecretResponse = response;
        this.paymentIntentClientSecret = response.payment_intent.client_secret;
        console.log(`${logPath}/ @Website fetchStripeClientSecretResponse`, this.fetchStripeClientSecretResponse);
        console.log(`${logPath}/ @Website paymentIntentClientSecret`, this.paymentIntentClientSecret);
        this.clientSecretRetrieved = true;
        console.log(`${logPath}/ @Website clientSecretRetrieved`, this.clientSecretRetrieved);
        this.websiteWidgetService.intentClientSecretSubject.next(this.paymentIntentClientSecret);

        // this.loading = false;
        // this.formLoading(this.loading);
      },
      (error: any) => {
        console.log(`${logPath}/ @Website error`, error);
        this.loading = false;
        this.formLoading(this.loading);
        // notify user about the error
        // let errorMessage: string = 'Oops something went wrong';
        // this.snackBar.open(errorMessage, '', {duration: 3000});
      }
    );
  }

  public onPaymentMethodChange(_event: MatRadioChange | any): void {
    const functionName: string = `onPaymentMethodChange`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    // console.log(`${logPath}/ @Website _event`, _event);
    // console.log(`${logPath}/ @Website _event.value G979`, _event.value);

    (_event.value === 'Cash') && (this.cashIsSelected = true, this.cardIsSelected = false, this.depositIsSelected = false, this.offerRequestIsSelected = false, this.cardToDriverIsSelected = false);
    (_event.value === 'Credit Card') && (this.cardIsSelected = true, this.cashIsSelected = false, this.depositIsSelected = false, this.offerRequestIsSelected = false, this.cardToDriverIsSelected = false);
    (_event.value === 'Deposit') && (this.depositIsSelected = true, this.cashIsSelected = false, this.cardIsSelected = false, this.offerRequestIsSelected = false, this.cardToDriverIsSelected = false);
    (_event.value === 'Offer Request') && (this.offerRequestIsSelected = true, this.cashIsSelected = false, this.cardIsSelected = false, this.depositIsSelected = false, this.cardToDriverIsSelected = false);
    (_event.value === 'Credit card to the driver') && (this.cardToDriverIsSelected = true, this.cashIsSelected = false, this.cardIsSelected = false, this.depositIsSelected = false, this.offerRequestIsSelected = false);

    if (_event.value === 'Credit Card') {
      if (this.discountPercentage > 0) {
        this.discountedPrice = +this.offerPrice - (+this.offerPrice) * (this.discountPercentage / 100);
        this.discountedPrice = this.discountedPrice.toString();
      }
      else {
        this.discountedPrice = this.offerPrice;
      }
    }

    console.log(`${logPath}/ @Website cashIsSelected`, this.cashIsSelected);
    console.log(`${logPath}/ @Website cardIsSelected`, this.cardIsSelected);
    console.log(`${logPath}/ @Website depositIsSelected`, this.depositIsSelected);
    console.log(`${logPath}/ @Website offerRequestIsSelected`, this.offerRequestIsSelected);
    console.log(`${logPath}/ @Website cardToDriverIsSelected`, this.cardToDriverIsSelected);
  }

  private createMaps() {
    const functionName: string = `createMaps`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    this.markers = [];
    this.agmMarkers = [];
    this.markerFrom = {
      position: { lat: this.markerFromLat, lng: this.markerFromLng },
      title: 'test',
      icon: {
        url: "assets/img/markers/beach.png",
        fillColor: "#ffffff",
        fillOpacity: 0.1,
        strokeWeight: 1,
        strokeColor: "#ffffff",
        scale: 0.075
      }
    };
    this.markerTo = { position: { lat: this.markerToLat, lng: this.markerToLng } };
    this.markers.push(this.markerFrom);
    this.agmMarkers.push([this.markerFromLat, this.markerFromLng])
    this.markers.push(this.markerTo);
    this.agmMarkers.push([this.markerToLat, this.markerToLng]);
    const bounds = this.getBounds(this.markers);
  }

  public onLogoLoad() {
    const functionName: string = `onLogoLoad`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    this.logoImageReady = true;
    console.log(this.logoImageReady);
  }

  public onImageLoad() {
    const functionName: string = `onImageLoad`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    this.vehiclesCounter++;
    if (this.vehicleTypesArray.length === this.vehiclesCounter) {
      this.loading = false;
      this.formLoading(this.loading);
      this.vehicleTypeListLoading = false;
    }
  }

  private loadVehiclesLogic(response: any) {
    const functionName: string = `loadVehiclesLogic`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    if (response.offers.request) {
      this.vehicleTypesArray.splice(0, this.vehicleTypesArray.length);
      this.noVehiclesFlag = true;
      this.vehicleTypeListLoading = false;
      this.loading = false;
      this.formLoading(this.loading);
    }
    else {
      this.vehicleTypesArray = response.offers;
      this.noVehiclesFlag = false;
      this.vehiclesCounter = 0;
      if (this.vehicleTypesArray.length > 0) {
        this.vehicleTypesArray.forEach((vehicleType: any, index: number) => {
          this.fetchVehicleTypeImage(vehicleType.offer.response.vehicle_type_details.image_url, index);
        });
      }
    }
  }

  private paintItBlack(background: string, font: string, elements: string) {
    const functionName: string = `paintItBlack`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    this.bgColor = background;
    this.fontColor = font;
    this.elementColor = elements;
  }

  private fetchOfferRequest(action: number) {
    const functionName: string = `fetchOfferRequest`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    this.stepper.next();
    this.oneWayOfferRequestForced = true;
    this.step1.completed = true;
    if (action === 0) {
      this.stepper.linear = false;
      this.stepper.selectedIndex = 2;
      this.stepper.linear = true;
    }
    else {
      this.stepper.next();
    }
  }

  private pickLabel(_event: any) {
    const functionName: string = `pickLabel`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    if (window.innerWidth > 600) {
      this.previousStep = _event.previouslySelectedStep.label;
      this.selectedStep = _event.selectedStep.label;
    }
    else {
      if (_event.selectedIndex === 0) {
        this.selectedStep = "Quote";
      }
      else if (_event.selectedIndex === 1) {
        this.isSecondStepAvailable ? (this.selectedStep = 'Vehicles') : (this.selectedStep = 'Transfer');
      }
      else if (_event.selectedIndex === 2) {
        this.isSecondStepAvailable ? (this.selectedStep = 'Transfer') : (this.selectedStep = 'Payment');
      }
      else {
        this.selectedStep = 'Payment';
      }
    }
  }

  private formatAddress(_address: Address): string {
    const functionName: string = `formatAddress`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website - addressToFormat`, _address);
    console.log(`${logPath}/ @Website - type of formatted_address`, typeof (_address.formatted_address));
    console.log(`${logPath}/ @Website - type of name`, typeof (_address.name));
    if (_address.formatted_address.includes(_address.name)) {
      return (_address.formatted_address);
    } else {
      return (_address.name + ", " + _address.formatted_address);
    }
  }

  private async filterResults(searchQuery: string) {
    const functionName: string = `filterResults`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    const autocompleteService = new google.maps.places.AutocompleteService();
    const placesService = new google.maps.places.PlacesService(document.createElement('div'));

    const bounds = new google.maps.LatLngBounds(
      { lat: this.options.bounds.south, lng: this.options.bounds.west },
      { lat: this.options.bounds.north, lng: this.options.bounds.east }
    );

    if (searchQuery.length > 2) {
      const autocompleteResultsPromise = new Promise<any[]>((resolve, reject) => {
        autocompleteService.getPlacePredictions({
          input: searchQuery,
          componentRestrictions: { country: 'GR' },
          bounds: bounds,
        }, (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
            resolve(predictions);
          } else {
            reject('Failed to fetch autocomplete results');
          }
        });
      });

      try {
        const autocompleteResults: any[] = await autocompleteResultsPromise;

        let autoCompleteArray: (autoComplete | null)[] = await Promise.all(
          autocompleteResults.map(async (prediction) => {
            try {
              const placeDetails = await new Promise<any>((resolve, reject) => {
                placesService.getDetails({ placeId: prediction.place_id }, (place, status) => {
                  if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                  } else {
                    reject('Failed to fetch place details');
                  }
                });
              });

              const placeLocation = new google.maps.LatLng(
                placeDetails.geometry.location.lat(),
                placeDetails.geometry.location.lng()
              );

              if (bounds.contains(placeLocation)) {
                return {
                  title: '',
                  address: prediction.description,
                  icon: 'google-auto',
                  latitude: placeLocation.lat(),
                  longitude: placeLocation.lng(),
                };
              } else {
                return null;
              }
            } catch (error) {
              console.error('Error fetching place details:', error);
              return null;
            }
          })
        );

        let titleResults = this.suggestedList.filter(add =>
          add.title.toLowerCase().includes(searchQuery.toLowerCase()));

        let addResults = this.suggestedList.filter(add =>
          add.address.toLowerCase().includes(searchQuery.toLowerCase()));

        let mergedArray = [...new Set(titleResults.concat(addResults))];
        let finalArray = [...new Set(mergedArray.concat(autoCompleteArray.filter(item => item !== null)))];

        return finalArray;
      } catch (error) {
        console.error('Error fetching autocomplete results:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  public onInputClick(el: number): void {
    const functionName: string = `onInputClick`;
    const logPath: string = `/${this.componentName}/${functionName}()`;

    const elevatorElements = this.el.nativeElement.getElementsByClassName('website-widget-component');

    setTimeout(() => {
      if (elevatorElements.length > 0) {
        elevatorElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 250);

    if (el === 0) {
      this.triggerAutocompleteInput.openPanel();
    } else if (el === 1) {
      this.triggerAutocompleteInput1.openPanel();
    } else if (el === 2) {
      this.triggerAutocompleteInput2.openPanel();
    } else if (el === 3) {
      this.triggerAutocompleteInput3.openPanel();
    }
  }
}
