import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-title-with-divider',
  templateUrl: './title-with-divider.component.html',
  styleUrls: ['./title-with-divider.component.css']
})
export class TitleWithDividerComponent implements OnInit {
  @Input() title: String;

  constructor() { }

  ngOnInit(): void {
  }

}
