/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/01/17
 */

// Angular
import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app-routing.module';
import { ApplicationPipesModule } from '../shared/application-pipes/application-pipes.module';
// App
import { SharedModule } from '../shared/shared.module';
import { HeaderModule } from './header/header.module';
import { SidebarModule } from './sidebar/sidebar.module';

@NgModule({
  declarations: [],
  imports: [
    AppRoutingModule,
    ApplicationPipesModule,
    HeaderModule,
    SharedModule,
  ],
  exports: [
    HeaderModule,
    SidebarModule
  ]
})
export class CoreModule { }