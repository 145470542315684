/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2022/12/08
 */

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private serviceName: string = `AudioService`;
  public audio = new Audio('../../assets/audio/Phone_Ring_Echo-down_20_sec.mp3');
  public audioPlayPromise: Promise<any>;

  // service scoped boolean variable to identify if dialog asking user interaction is visible
  public dialogExists: boolean = false;

  // service scoped boolean variable to identify if audio is currently playing
  public isAudioPlayingNow: boolean;

  // service scoped boolean variable with sessionStorage extension to identify if the user 
  // has interacted with the page so that browser autoplay block policy is bypassed
  public isAudioPermissionGranted: boolean;

  // service scoped boolean variable to identify if there is a need for immediate call of play() after user interaction dialog
  public afterDialogNotificationPlay: boolean;

  // private service scoped number variable to hold the number of times audioPlayPromise has resulted to error for testing purposes
  private playNotificationSoundErrorCounter: number;

  constructor (
    private dialog: MatDialog,
  ) {
    const functionName: string = `constructor`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/`);

    this.dialogExists = false;
    this.isAudioPlayingNow = false;
    this.afterDialogNotificationPlay = false;

    if (sessionStorage.getItem('isAudioPermissionGranted') !== undefined) {
      this.isAudioPermissionGranted = sessionStorage.getItem('isAudioPermissionGranted') === 'true';
      console.log(`${logPath}/ @Audio sessionStorage - isAudioPermissionGranted: `, this.isAudioPermissionGranted);
    } else {
      this.isAudioPermissionGranted = false;
      console.log(`${logPath}/ @Audio sessionStorage - isAudioPermissionGranted: `, this.isAudioPermissionGranted);
    }

    if (sessionStorage.getItem('playNotificationSoundErrorCounter') !== undefined) {
      this.playNotificationSoundErrorCounter = Number(sessionStorage.getItem('playNotificationSoundErrorCounter'));
      console.log(`${logPath}/ @Audio sessionStorage - playNotificationSoundErrorCounter: `, this.playNotificationSoundErrorCounter);
    } else {
      this.playNotificationSoundErrorCounter = 0;
      console.log(`${logPath}/ @Audio sessionStorage - playNotificationSoundErrorCounter: `, this.playNotificationSoundErrorCounter);
    }
  }

  public stopNotificationSound(): void {
    const functionName: string = `stopNotificationSound`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/ @Audio`);

    // stop audio playback
    this.audio.loop = false;
    this.audio.currentTime = 0;
    this.audio.pause();
    this.isAudioPlayingNow = false;
    console.log(`${logPath}/ @Audio isAudioPlayingNow`, this.isAudioPlayingNow);
  }

  public playNotificationSound(_playSound: boolean = true): void {
    const functionName: string = `playNotificationSound`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ @Audio _playSound: `, _playSound);

    if (_playSound) {
      if (!this.isAudioPlayingNow) {
        this.audioPlayPromise = this.audio.play().then((result) => {
          console.log(`${logPath}/ @Audio result`, result);
          console.log(`${logPath}/ @Audio audioPlayPromise`, this.audioPlayPromise);

          this.audio.loop = true;
          // Autoplay started!
          this.isAudioPlayingNow = true;
          console.log(`${logPath}/ @Audio isAudioPlayingNow`, this.isAudioPlayingNow);

          this.isAudioPermissionGranted = true;
          console.log(`${logPath}/ @Audio isAudioPermissionGranted`, this.isAudioPermissionGranted);
          sessionStorage.setItem('isAudioPermissionGranted', JSON.stringify(this.isAudioPermissionGranted));

          this.afterDialogNotificationPlay && (this.afterDialogNotificationPlay = false);
          console.log(`${logPath}/ @Audio afterDialogNotificationPlay`, this.afterDialogNotificationPlay);

        }).catch((error) => {
          console.log(`${logPath}/ @Audio error`, error);
          console.log(`${logPath}/ @Audio audioPlayPromise`, this.audioPlayPromise);

          if (error.name === "NotAllowedError") {
            console.log(`${logPath}/ @Audio error.name`, error.name);
          }

          this.isAudioPermissionGranted = false;
          console.log(`${logPath}/ @Audio isAudioPermissionGranted`, this.isAudioPermissionGranted);
          sessionStorage.setItem('isAudioPermissionGranted', JSON.stringify(this.isAudioPermissionGranted));

          this.playNotificationSoundErrorCounter++;
          console.log(`${logPath}/ @Audio playNotificationSoundErrorCounter`, this.playNotificationSoundErrorCounter);
          sessionStorage.setItem('playNotificationSoundErrorCounter', JSON.stringify(this.playNotificationSoundErrorCounter));

          this.playNotificationSound(false);
        })
      } else {
        console.log(`${logPath}/ @Audio audio is already playing`);
      }
    } else {
      if (!this.dialogExists && !this.isAudioPermissionGranted) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          panelClass: 'appV0',
          width: '300px',
          data: {
            header: 'Sound',
            body: `Interact with the page to enable sound notifications`,
            negative: null,
          }
        });

        this.dialogExists = true;
        console.log(`${logPath}/ @Audio dialogExists`, this.dialogExists);

        dialogRef.afterClosed().subscribe(result => {
          this.dialogExists = false;
          console.log(`${logPath}/ @Audio dialogExists`, this.dialogExists);

          this.isAudioPermissionGranted = true;
          console.log(`${logPath}/ @Audio isAudioPermissionGranted`, this.isAudioPermissionGranted);
          sessionStorage.setItem('isAudioPermissionGranted', JSON.stringify(this.isAudioPermissionGranted));

          console.log(`${logPath}/ @Audio afterDialogNotificationPlay`, this.afterDialogNotificationPlay);
          if (this.afterDialogNotificationPlay) {
            this.playNotificationSound();
          }
        });
      }
    }
  }
}
