
export class ToursCategory {
    id: number;
    image: string;
    title: string;
    description: string;
    toursGroups: ToursGroups[];
    readonly objectType = ToursCategory;

    constructor(
        { id, image, title, description, toursGroups }: {
            id: number,
            image: string,
            title: string,
            description: string,
            toursGroups: ToursGroups[],
        }
    ) {
        this.id = id;
        this.image = image;
        this.title = title;
        this.description = description;
        this.toursGroups = toursGroups;
    }
}

export class ToursGroups {
    id: number;
    image: string;
    title: string;
    description: string;
    tours: Tours[];
    readonly objectType = ToursGroups;


    constructor(
        { id, image, title, description, tours }: {
            id: number,
            image: string,
            title: string,
            description: string,
            tours: Tours[],
        }
    ) {
        this.id = id;
        this.image = image;
        this.title = title;
        this.description = description;
        this.tours = tours;
    }
}

export class Tours {
    id: number;
    image: string;
    title: string;
    description: string;
    locations: ToursLocations[];
    readonly objectType = Tours;


    constructor(
        { id, image, title, description, locations }: {
            id: number,
            image: string,
            title: string,
            description: string,
            locations: ToursLocations[],
        }
    ) {
        this.id = id;
        this.image = image;
        this.title = title;
        this.description = description;
        this.locations = locations;
    }
}

export class ToursLocations {
    title: string;
    lat: number;
    long: number;

    constructor({ title, lat, long }: {
        title: string,
        lat: number,
        long: number,

    }) {
        this.title = title;
        this.lat = lat;
        this.long = long;
    }
}

