import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HotelSwitcherService } from 'src/app/core/hotel-switcher/hotel-switcher.service';
import { LoginService } from 'src/app/core/login/login.service';
import { Branch } from 'src/app/core/login/user-info.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsiteWidgetService {
  private serviceName: string = `WebsiteWidgetService`;
  public companyBranchInfo: Branch | null = this.hotelSwitcherService.getBranch();
  public companyAndBranchParameters: any = {
    // companyId: this.companyBranchInfo?.toString(),
    branchId: this.companyBranchInfo?.id.toString()
  }
  private postTransferEndpointUrl: string = `/api/v0/staff/branch/branchId/transfers`;
  private costCalculationEndpointUrl: string = `/api/v0/staff/branch/branchId/transfers/cost`;
  private getPaymentMethodsEndpointUrl: string = `/api/v0/staff/branch/branchId`;
  private getStripeClientSecretEndpointUrl: string = `/api/v0/website/branch/branchId/transfers/payments/stripe/payment?transfer_id=transferId`
  // private getSupplierPinnedAddressEndpointUrl: string = `/api/v0/staff/branch/branchId/suppliers/supplierId/pinned-address`
  private getSupplierPinnedAddressEndpointUrl: string = `/api/v0/widget-user/branch/branchId/suppliers/supplierId/pinned-address`;

  private getWidgetInfoEndpointUrl: string = `/api/v0/widget-user/widgets/info?slag=slagId`;
  private postTransferCostEndpointUrl: string = `/api/v0/widget-user/transfers/cost`; // TODO

  public intentClientSecretSubject: Subject<any> = new Subject<any>(); 


  constructor(
    private httpClient: HttpClient,
    private loginService: LoginService,
    private hotelSwitcherService: HotelSwitcherService
  ) { 
    const functionName: string = `constructor`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/ @Website`);
  }

  public getWidgetInfo(_slag: string): Observable<any> {
    const functionName: string = `getWidgetInfo`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/@Website - _slag`, _slag);

    return this.httpClient.get<any>(
      `${environment.apiUrl}${this.getWidgetInfoEndpointUrl.replace('slagId', _slag)}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'Application/json',
          'Authorization': this.loginService.getAuthHeader(),
        }),
      }
    ).pipe(map((response: any) => response.widget
      // console.log(`${logPath}/ @Website - response`, response);
      // return response;
    ));
  }

  public getPaymentMethods(_branchId: number): Observable<any> {
    const functionName: string = `getPaymentMethods`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/ @Website _branchId`, _branchId);

    return this.httpClient.get<any>(
      `${environment.apiUrl}${this.getPaymentMethodsEndpointUrl.replace('branchId', `${_branchId}`)}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'Application/json',
          'Authorization': this.loginService.getAuthHeader(),
        }),
      }
    ).pipe(map((response: any) => {
      // console.log(`${logPath}/ @Website - response`, response);
      return response;
    }));
  }

  private generateCostCalculationEndpointUrl(_branchId: number, _costCalculationFilter?: any): string {
    const functionName: string = `generateCostCalculationEndpointUrl`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ @Website _branchId, _costCalculationFilter`, _branchId, _costCalculationFilter);

    let _costCalculationEndpointUrl : string = this.costCalculationEndpointUrl.replace('branchId', `${_branchId}`);
    if (_costCalculationFilter) {
      Object.entries(_costCalculationFilter).forEach(([key, value], index) => {
        console.log(`${logPath}/ @Website key, value, index`, key, value, index);
        // _costCalculationEndpointUrl += `&${key}=${value}`
        index === 0 ? _costCalculationEndpointUrl += `?one_way[${key}]=${value}` : _costCalculationEndpointUrl += `&one_way[${key}]=${value}`;
      });
    }
    // console.log(`${logPath}/ @Website _costCalculationEndpointUrl`, _costCalculationEndpointUrl);
    return _costCalculationEndpointUrl;
  }

  public getCostCalculation(_branchId: number, _costCalculationFilter: any): Observable<any> {
    const functionName: string = `getCostCalculation`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ @Website _branchId`, _branchId);
    console.log(`${logPath}/ @Website _costCalculationFilter`, _costCalculationFilter);

    return this.httpClient.get<any>(
      `${environment.apiUrl}${this.generateCostCalculationEndpointUrl(_branchId, _costCalculationFilter)}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'Application/json',
          'Authorization': this.loginService.getAuthHeader(),
        }),
      }
    ).pipe(map((response: any) => {
      // console.log(`${logPath}/ @Website - response`, response);
      return response;
    }));
  }

  public postTransferCost(_postRequestBody: any): Observable<any> {
    const functionName: string = `postTransferCost`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/ @Website _postRequestBody`, _postRequestBody);

    return this.httpClient.post<any>(
      `${environment.apiUrl}${this.postTransferCostEndpointUrl}`,
      _postRequestBody,
      {
        headers: new HttpHeaders({
          'Authorization': this.loginService.getAuthHeader(),
        }),
      }
    ).pipe(map((response: any) => {
      // console.log(`${logPath}/ @Website - response`, response);
      return response;
    }));
  }

  public getVehicleTypeImage(_url: string): Observable<any> {
    const functionName: string = `getVehicleTypeImage`;
    const logPath: string = `/${this.serviceName}/${functionName}`;
    console.log(`${logPath}/@Website - _url`);

    return this.httpClient.get(
      environment.apiUrl + _url,
      {
        headers: new HttpHeaders({
          'Authorization': this.loginService.getAuthHeader(),
        }),
        responseType: 'blob',
        observe: 'response'
      }).pipe(map((response: HttpResponse<Blob>) => {
        console.log('ok');
        return response;
      }, catchError((response: Observable<any>) => {
        console.log('error')
        return response;
      })));
  }

  public getLogoImage(_url: string): Observable<any> {
    const functionName: string = `getLogoImage`;
    const logPath: string = `/${this.serviceName}/${functionName}`;
    console.log(`${logPath}/@Website - _url`);

    return this.httpClient.get(
      environment.apiUrl + _url,
      {
        headers: new HttpHeaders({
          'Authorization': this.loginService.getAuthHeader(),
        }),
        responseType: 'blob',
        observe: 'response'
      }).pipe(map((response: HttpResponse<Blob>) => {
        console.log('ok');
        return response;
      }, catchError((response: Observable<any>) => {
        console.log('error')
        return response;
      })));
  }

  public postTransfer(_branchId: number, _postRequestBody: any): Observable<any> {
    const functionName: string = `postTransfer`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/ @Website _postRequestBody`, _postRequestBody);

    return this.httpClient.post<any>(
      `${environment.apiUrl}${this.postTransferEndpointUrl.replace('branchId', `${_branchId}`)}`,
      _postRequestBody,
      {
        headers: new HttpHeaders({
          'Authorization': this.loginService.getAuthHeader(),
        }),
      }
    ).pipe(map((response: any) => {
      // console.log(`${logPath}/ @Website - response`, response);
      return response;
    }));
  }

  public getStripeClientSecret(_branchId: number, _transferId: number): Observable<any> {
    const functionName: string = `getStripeClientSecret`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/ @Website _branchId`, _branchId);
    // console.log(`${logPath}/ @Website _transferId`, _transferId);

    const endpoint: string = this.getStripeClientSecretEndpointUrl.replace('branchId', `${_branchId}`);
    return this.httpClient.get<any>(
      `${environment.apiUrl}${endpoint.replace('transferId', `${_transferId}`)}`,
      {
        headers: new HttpHeaders({
          'Authorization': this.loginService.getAuthHeader(),
        }),
      }
    ).pipe(map((response: any) => {
      // console.log(`${logPath}/ @Website - response`, response);
      return response;
    }));
  }

  public getSupplierPinnedAddress(_branchId: number, _supplierId: number): Observable<any> {
    const functionName: string = `getSupplierPinnedAddress`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/@Website - _branchId`, _branchId);
    console.log(`${logPath}/@Website - _supplierId`, _supplierId);

    const endpoint: string = this.getSupplierPinnedAddressEndpointUrl.replace('branchId', `${_branchId}`);
    return this.httpClient.get<any>(
      `${environment.apiUrl}${endpoint.replace('supplierId', `${_supplierId}`)}`,
      {
        headers: new HttpHeaders({
          'Authorization': this.loginService.getAuthHeader(),
        }),
      }
    ).pipe(map((data: any) => data.pinned_address));
  }
}
