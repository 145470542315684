<img src={{value.image}} class="tourImage">

<mat-grid-tile-footer class="tourFooter">

    <div class="displayInRow">
        <div>
            <h2 class="margin0">{{value.title}}</h2>
            <h4>{{value.description}}</h4>
        </div>
        <div class="merginAutoTopBottom">

            <button (click)="onClick(value)" mat-raised-button class="tourButton">{{buttonText}}</button>
        </div>

    </div>

</mat-grid-tile-footer>