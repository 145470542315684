<div class="website-widget-component" fxLayout="row" fxLayoutAlign="start stretch" fxFlex #master>

    <!-- SPACE -->
    <div class="space" fxFlex="2 1 space" *ngIf="isWhiteSpace" #spaceLeft></div>
    <div class="space-transfer" fxFlex="2 1 auto" *ngIf="isTransferWhiteSpace"></div>
    <!-- SPACE -->

    <!-- WEBSITE-WIDGET -->
    <div fxLayoutAlign="center center" [fxLayoutAlign.xs]="xsTransferStep ? 'start center' : 'center stretch'" fxLayoutAlign.sm="center stretch" *ngIf="noLogo || logoImageReady" class="website-widget" fxLayout="column" fxFlex="2 1 auto">

        <div [ngClass]="isWhiteSpace ? 'elevator-sm' : 'elevator-lg'" id="elevator" #elevator>

            <div [ngClass]="isWhiteSpace ? 'placeholder-sm' : 'placeholder-lg'" *ngIf="logoImageReady">
                <img class="logo-placeholder-image" [src]="logoImage2" alt="company-logo" #logoElement (load)="onLogoLoad()">
                <!-- <hr [ngClass]="isWhiteSpace ? 'hr-sm' : 'hr-lg'" *ngIf="logoImageReady"> -->
            </div>

            <mat-tab-group class="fix-center-tabs bold-tabs" mat-align-tabs="center" (selectedTabChange)="onTabChange($event)">
                <mat-tab label="Tours & Acitvites">
                    <app-tours-and-activities></app-tours-and-activities>
                </mat-tab>
                <mat-tab label="Transfers" style="display: none !important;">

                    <!-- MAT-STEPPER -->
                    <mat-stepper [linear]="true" [disableRipple]="true" #stepper class="stepper" (selectionChange)="onSelectionChange($event)">


                        <!-- MAT-STEP 1 -->
                        <mat-step [stepControl]="quoteForm" [label]="titleState[0]" (interacted)="moveAway($event)" aria-labelledby="Quote">

                            <div class="first-step-container" fxLayout="column" fxLayoutAlign="start stretch">

                                <!-- HEADER -->
                                <div class="header" fxLayout="row" fxLayoutAlign="start center">
                                    <p class="header-title quote-title" fxFlex>Book a Transfer</p>
                                    <!-- <div class="header-button" fxFlex fxLayout="row" fxLayoutAlign="end center">
                <span class="button-overlay" fxLayout="row" fxLayoutAlign="center center">
                  <mat-icon fxLayout="row" fxLayoutAlign="center center" style="font-size: 20px;">close</mat-icon>
                </span>
              </div> -->
                                    <div fxFlex fxLayout="column" fxLayoutAlign="start end">
                                        <!-- <span *ngIf="branchId !== undefined">branchId: {{branchId}}</span> -->
                                        <!-- <span *ngIf="supplierId !== undefined">supplierId: {{supplierId}}</span> -->
                                    </div>
                                </div>
                                <!-- HEADER -->

                                <!-- FORM -->
                                <form class="form-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex [formGroup]="quoteForm">

                                    <div class="field-container" fxLayout="row" fxLayoutAlign="start stretch" fxFlex style="margin-bottom: 0;">

                                        <!-- INPUTS -->
                                        <div class="inputs" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                            <!-- FROM -->
                                            <!-- <div class="field-title">
                    <span>From</span>
                  </div> -->
                                            <mat-form-field appearance="outline" class="from-field" *ngIf="!isFromFieldDisabled" #fromField>
                                                <mat-label class="field-label-quote">From</mat-label>
                                                <!-- <input
                      *ngIf="options.bounds"
                      matInput
                      formControlName="from"
                      ngx-google-places-autocomplete
                      [options]="options"
                      (onAddressChange)="handleFromAddressChange($event, quoteForm)"
                      class="from-input inputs-quote"
                    > -->
                                                <input matInput formControlName="from" class="from-input" [matAutocomplete]="auto" #fromInput (click)="onInputClick(0)">
                                                <mat-autocomplete #auto="matAutocomplete" class="autocomplete-panel" (optionSelected)="handleFromAddressChange2($event.option.value, quoteForm)">
                                                    <mat-option class="autocomplete-option" *ngFor="let result of filteredFromResults | async; let i = index" [value]="result">
                                                        <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
                                                            <img *ngIf="result.icon" src="assets/img/markers/{{result.icon!}}.svg" alt="icon" height="15" width="20">
                                                            <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxFlex style="margin-left: 1em;">
                                                                <span class="autocomplete-title">
                                <strong *ngIf="result.title">{{ result.title }}</strong>
                              </span>
                                                                <span class="autocomplete-metadata">
                                <span class="item-metastat">
                                  <small>{{result.address}}</small>
                                </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            <!-- FROM -->

                                            <!-- FALSE FROM -->
                                            <mat-form-field appearance="outline" class="from-field" *ngIf="isFromFieldDisabled">
                                                <mat-label class="field-label-quote">From</mat-label>
                                                <input matInput formControlName="from" class="from-input inputs-quote" readonly>
                                            </mat-form-field>
                                            <!-- FALSE FROM -->

                                            <!-- TO -->
                                            <!-- <div class="field-title">
                    <span>To</span>
                  </div> -->
                                            <mat-form-field appearance="outline" class="to-field" *ngIf="!isToFieldDisabled">
                                                <mat-label class="field-label-quote">To</mat-label>
                                                <!-- <input
                      *ngIf="options.bounds"
                      matInput
                      formControlName="to"
                      ngx-google-places-autocomplete
                      [options]="options"
                      (onAddressChange)="handleToAddressChange($event, quoteForm)"
                      class="to-input inputs-quote"
                    > -->
                                                <input matInput formControlName="to" class="to-input inputs-quote" [matAutocomplete]="auto" #toInput (click)="onInputClick(1)">
                                                <mat-autocomplete #auto="matAutocomplete" class="autocomplete-panel" (optionSelected)="handleToAddressChange2($event.option.value, quoteForm)">
                                                    <mat-option class="autocomplete-option" *ngFor="let result of filteredToResults | async; let i = index" [value]="result">
                                                        <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
                                                            <img src="assets/img/markers/{{result.icon!}}.svg" alt="icon" height="15" width="20">
                                                            <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxFlex style="margin-left: 1em;">
                                                                <span class="autocomplete-title">
                                <strong *ngIf="result.title">{{ result.title }}</strong>
                              </span>
                                                                <span class="autocomplete-metadata">
                                <span class="item-metastat">
                                  <small>{{result.address}}</small>
                                </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                            <!-- TO -->

                                            <!-- FALSE TO -->
                                            <mat-form-field appearance="outline" class="to-field" *ngIf="isToFieldDisabled">
                                                <mat-label class="field-label-quote">To</mat-label>
                                                <input matInput formControlName="to" class="to-input inputs-quote" readonly>
                                            </mat-form-field>
                                            <!-- FALSE TO -->

                                        </div>
                                        <!-- INPUTS -->

                                        <!-- BUTTON -->
                                        <div class="button" fxLayout="column" fxLayoutAlign="center center">

                                            <button (click)="swapFromToFields2()" fxLayout="column" fxLayoutAlign="center center" class="swap-button" *ngIf="!pinnedAddressMode || (!isToFieldDisabled && !isFromFieldDisabled)">
                    <mat-icon class="swapper" style="font-size: 30px; color: #3d85e3; color: #3d3d3d">swap_vert</mat-icon>
                  </button>

                                            <button (click)="swapFromToFields()" fxLayout="column" fxLayoutAlign="center center" class="swap-button" *ngIf="pinnedAddressMode && (isToFieldDisabled || isFromFieldDisabled)">
                    <mat-icon class="swapper" style="font-size: 30px; color: #3d85e3; color: #3d3d3d">swap_vert</mat-icon>
                  </button>

                                        </div>
                                        <!-- BUTTON -->

                                    </div>

                                    <!-- FIELD CONTAINER -->
                                    <div class="field-container" fxLayout="row" fxLayoutAlign="start stretch" fxFlex>

                                        <!-- DATE-CONTAINER -->
                                        <div class="date-container" fxLayout="column" fxLayoutAlign="start center" fxFlex>
                                            <div class="field-title">
                                                <span class="quote-spans">Date</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="form-field">
                                                <mat-label class="field-label-quote">Choose a date</mat-label>
                                                <input id="colorpicker" matInput [matDatepicker]="picker" [min]="minDate" formControlName="date" (dateChange)="dateChanged($event, quoteForm)" *ngIf="options.bounds">
                                                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                                <!-- <mat-error class="validation-error" *ngIf="quoteForm.get('date')?.errors?.required">Field is required</mat-error> -->
                                            </mat-form-field>
                                        </div>
                                        <!-- DATE-CONTAINER -->

                                        <!-- TIME-CONTAINER -->
                                        <div class="time-quote-container" fxLayout="column" fxLayoutAlign="start center" fxFlex>
                                            <div class="field-title">
                                                <span class="quote-spans">Time</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="form-field">
                                                <!-- <mat-label></mat-label> -->
                                                <mat-select formControlName="time" *ngIf="options.bounds">
                                                    <mat-option *ngFor="let hour of availableHoursArray" [value]="hour">
                                                        {{ hour }}
                                                    </mat-option>
                                                </mat-select>
                                                <!-- <mat-error class="validation-error" *ngIf="quoteForm.get('time')?.errors?.required">Field is required</mat-error> -->
                                            </mat-form-field>
                                        </div>
                                        <!-- TIME-CONTAINER -->

                                    </div>
                                    <!-- FIELD CONTAINER -->

                                    <!-- FIELD CONTAINER -->
                                    <div class="field-container" fxLayout="row" fxLayoutAlign="start stretch" fxFlex>

                                        <!-- PASSENGERS-CONTAINER -->
                                        <div class="passengers-container" fxLayout="column" fxLayoutAlign="start center" fxFlex>
                                            <div class="field-title">
                                                <span class="quote-spans">Passengers</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="form-field">
                                                <!-- <mat-label></mat-label> -->
                                                <div class="sign-container" fxLayout="row" fxLayoutAlign="start stretch">
                                                    <mat-icon class="icons-quote" (click)="editPassengers('decrease', quoteForm)">remove</mat-icon>
                                                    <input matInput formControlName="passengers" type="number" min="1" max="100" class="form-input inputs-quote" readonly *ngIf="options.bounds">
                                                    <mat-icon class="icons-quote" (click)="editPassengers('increase', quoteForm)">add</mat-icon>
                                                </div>
                                                <!-- <mat-error class="validation-error" *ngIf="quoteForm.get('passengers')?.errors?.required">Field is required</mat-error> -->
                                            </mat-form-field>
                                        </div>
                                        <!-- PASSENGERS-CONTAINER -->

                                        <!-- LUGGAGE-CONTAINER -->
                                        <div class="luggage-container" fxLayout="column" fxLayoutAlign="start center" fxFlex>
                                            <div class="field-title">
                                                <span class="quote-spans">Luggage</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="form-field">
                                                <!-- <mat-label></mat-label> -->
                                                <div class="sign-container" fxLayout="row" fxLayoutAlign="start stretch">
                                                    <mat-icon class="icons-quote" (click)="editLuggagePieces('decrease', quoteForm)">remove</mat-icon>
                                                    <input matInput formControlName="luggage" type="number" min="0" max="100" class="form-input inputs-quote" readonly *ngIf="options.bounds">
                                                    <mat-icon class="icons-quote" (click)="editLuggagePieces('increase', quoteForm)">add</mat-icon>
                                                </div>
                                                <!-- <mat-error class="validation-error" *ngIf="quoteForm.get('luggage')?.errors?.required">Field is required</mat-error> -->
                                            </mat-form-field>
                                        </div>
                                        <!-- LUGGAGE-CONTAINER -->

                                    </div>
                                    <!-- FIELD CONTAINER -->

                                </form>
                                <!-- FORM -->

                                <!-- BUTTON-CONTAINER -->
                                <div class="button-container" fxLayout="row" fxLayoutAlign="start stretch" fxFlex>
                                    <button mat-button class="get-price-button" type="button" fxFlex (click)="onContinueButtonClick()" [disabled]="!isContinueButtonEnabled">Next</button>
                                </div>
                                <!-- BUTTON-CONTAINER -->

                            </div>

                        </mat-step>
                        <!-- MAT-STEP -->

                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

                        <mat-step #step1 [label]="titleState[1]" [editable]="!oneWayOfferRequestForced" [optional]="isVehicleTypeStepOptional" *ngIf="isSecondStepAvailable" [completed]="false">

                            <!-- STEP-CONTAINER -->
                            <div class="step-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                <!-- CONTENT-CONTAINER -->
                                <div class="content-container" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxFlex>

                                    <!-- VEHICLE-TYPE-CONTAINER -->
                                    <div class="vehicle-type-container" [ngClass]="{'vehicle-type-container-mobile' : xsTransferStep}" fxLayout="column" fxLayoutAlign="start stretch" fxFlex fxFlexOrder="2" fxFlexOrder.sm="2" fxFlexOrder.xs="1">

                                        <div class="route-label" fxLayout="row" fxLayoutAlign="start stretch">
                                            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="20" style="padding: 5px;">
                                                <div fxLayout="row" fxLayoutAlign="center center" style="border-radius: 50%; height: 45px; width: 45px; padding: 5px; background-color: #fff; color: #3D85E3; box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.12), -1px 1px 1px 1px rgba(0, 0, 0, 0.12);">
                                                    <mat-icon class="route-icon">route</mat-icon>
                                                </div>
                                            </div>
                                            <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="40" style="padding: 5px;">

                                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                                    <mat-icon style="font-size: 16px; font-weight: 600;" fxLayout="row" fxLayoutAlign="center center">radio_button_unchecked</mat-icon>
                                                    <p>{{ initialFrom }}</p>
                                                </div>

                                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                                    <mat-icon style="font-size: 16px; font-weight: 600;" fxLayout="row" fxLayoutAlign="center center">location_on</mat-icon>
                                                    <p>{{ initialTo }}</p>
                                                </div>

                                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                                    <mat-icon style="font-size: 16px; font-weight: 600;" fxLayout="row" fxLayoutAlign="center center">event</mat-icon>
                                                    <p>{{ dateView }}</p>
                                                </div>

                                            </div>

                                            <div fxLayout="row" fxLayoutAlign="start end" fxFlex="40" style="padding: 5px;" id="watch-later">
                                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                                                    <mat-icon style="font-size: 16px; font-weight: 600;" fxLayout="row" fxLayoutAlign="center center">watch_later</mat-icon>
                                                    <p>{{ initialTime }}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="vehicle-type-list" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
                                            <div class="list-label " fxLayout="row" fxLayoutAlign="start center">
                                                <p>Search Results</p>
                                            </div>

                                            <!-- List -->
                                            <div class="list" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                                <!-- Loading Spinner -->
                                                <div class="spinner-container" fxLayout="column" fxLayoutAlign="center center" *ngIf="vehicleTypeListLoading" fxFlex>
                                                    <mat-spinner class="loading-spinner" [diameter]="85" [strokeWidth]="3" mode="indeterminate"></mat-spinner>
                                                </div>

                                                <!-- Error Message -->
                                                <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!vehicleTypeListLoading && noVehiclesFlag" fxFlex>
                                                    <p>No Vehicle Types found!</p>
                                                </div>

                                                <!-- Fix Container -->
                                                <!-- <div class="fix-container" *ngIf="!vehicleTypeListLoading && vehicleTypesArray.length !== 0" fxLayout="column" fxLayoutAlign="start stretch" fxFlex fxLayoutGap="10px" fxFlex> -->
                                                <div class="fix-container" [ngClass]="(!vehicleTypeListLoading && !noVehiclesFlag) ? 'show':'hide'" fxLayout="column" fxLayoutAlign="start stretch" fxFlex fxLayoutGap="10px" fxFlex>


                                                    <!-- Vehicle Type Card -->
                                                    <div class="vehicle-type-card" fxLayout="row" fxLayoutAlign="start stretch" *ngFor="let vehicleType of vehicleTypesArray; let i=index">

                                                        <!-- Image -->
                                                        <div class="vehicle-type-image">
                                                            <img class="image" [src]="vehicleType.img" alt="vehicle-type-image" #vehicleElement (load)="onImageLoad()">
                                                        </div>

                                                        <!-- Details -->
                                                        <div class="vehicle-type-details" fxLayout="column" fxLayoutAlign="start stretch" fxFlex fxLayoutGap="5px">
                                                            <p style="color: #2e2e33; font-size: 18px; font-weight: 500;">{{ vehicleType.offer.response.vehicle_type_details.title }}</p>
                                                            <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                                                                <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                                                    <p style="color: #858587; font-size: 12px; font-weight: 600;">{{ vehicleType.offer.response.vehicle_type_details.passengers }}</p>
                                                                    <mat-icon style="color: #858587; font-size: 16px; font-weight: 600;">people</mat-icon>
                                                                </div>
                                                                <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="5px">
                                                                    <p style="color: #858587; font-size: 12px; font-weight: 600;">{{ vehicleType.offer.response.vehicle_type_details.luggage }}</p>
                                                                    <mat-icon style="color: #858587; font-size: 16px; font-weight: 600;">luggage</mat-icon>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Price -->
                                                        <div class="vehicle-type-price">
                                                            <button class="select-vehicle-button" fxLayout="column" fxLayoutAlign="center center" fxFlex (click)="selectVehicleType(vehicleType, i)" [disabled]="!checkFormValidity()" [ngClass]="{'select-vehicle-button-disabled' : !checkFormValidity()}">
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" style="margin-left: auto;margin-right: auto;">
                              <mat-icon style="font-size: 18px;" fxLayout="row" fxLayoutAlign="center center">euro</mat-icon>
                              <p style="font-size: 18px; font-weight: 600;">{{ vehicleType.offer.response.calculation.total }}</p>
                              <!-- <p>{{ vehicleType.offer.response.calculation.vehicle_type_supply }}</p> -->
                            </div>
                            <p style="font-size: 14px; font-weight: 500;">BOOK NOW</p>
                          </button>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <!-- VEHICLE-TYPE-CONTAINER -->

                                    <!-- INITIAL FORM CONTAINER -->
                                    <div id="initializer" class="initial-form-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="40%" fxFlexOrder="1" fxFlexOrder.sm="1" fxFlexOrder.xs="2" style="overflow: auto;">
                                        <!-- fxLayoutGap="20px"
              fxLayoutGap.xs="20px"
              fxLayoutGap.sm="20px" -->
                                        <div class="vehicle-map-container" fxLayout="column" fxLayoutAlign="center center" fxFlex>
                                            <!-- <google-map class="map" [options]="mapOptions" [width]="'340'" [height]="'230'" #vehicleTypeMap>
                    <map-marker *ngFor="let marker of markers" [position]="marker.position"></map-marker>
                  </google-map> -->
                                            <agm-map id="vehicle-map" [fullscreenControl]='false' [streetViewControl]='false' [mapTypeControl]='false' [fitBounds]="true">
                                                <agm-marker *ngFor="let coordinate of agmMarkers; let i=index" [latitude]="coordinate[0]" [longitude]="coordinate[1]" [agmFitBounds]="true"></agm-marker>
                                            </agm-map>
                                        </div>
                                        <div class="initial-form" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                            <div *ngIf="!isVehicleTypeFormReady">
                                                <!-- loading effect -->
                                                <div class="spinner-container" fxLayout="column" fxLayoutAlign="center center" *ngIf="!postTransferResolved">
                                                    <mat-spinner class="loading-spinner" [diameter]="85" [strokeWidth]="3" mode="indeterminate"></mat-spinner>
                                                </div>
                                            </div>

                                            <!-- FORM -->
                                            <form class="fields-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex [formGroup]="vehicleTypeForm" *ngIf="isVehicleTypeFormReady" id="vehicle-type-form-container">

                                                <!-- FIELD BOX -->
                                                <div class="field-box" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                                    <!-- FROM -->
                                                    <div class="field-box-title">
                                                        <span>From</span>
                                                    </div>
                                                    <mat-form-field appearance="outline" class="from-field" *ngIf="!isFromFieldDisabled" #fromField style="margin-top: 0;">
                                                        <mat-label class="field-label">From</mat-label>
                                                        <!-- <input
                          *ngIf="options.bounds"
                          matInput
                          formControlName="from"
                          ngx-google-places-autocomplete
                          [options]="options"
                          (onAddressChange)="handleFromAddressChange($event, vehicleTypeForm)"
                          class="from-input"
                        > -->
                                                        <input matInput formControlName="from" class="from-input" [matAutocomplete]="auto" #fromInput1 (click)="onInputClick(2)">
                                                        <mat-autocomplete #auto="matAutocomplete" class="autocomplete-panel" (optionSelected)="handleFromAddressChange2($event.option.value, vehicleTypeForm)">
                                                            <mat-option class="autocomplete-option" *ngFor="let result of filteredFromResults2 | async; let i = index" [value]="result">
                                                                <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
                                                                    <img src="assets/img/markers/{{result.icon!}}.svg" alt="icon" height="15" width="20">
                                                                    <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxFlex style="margin-left: 1em;">
                                                                        <span class="autocomplete-title">
                                  <strong *ngIf="result.title">{{ result.title }}</strong>
                                </span>
                                                                        <span class="autocomplete-metadata">
                                  <span class="item-metastat">
                                    <small>{{result.address}}</small>
                                  </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <!-- FROM -->

                                                    <!-- FALSE FROM -->
                                                    <mat-form-field appearance="outline" class="from-field" *ngIf="isFromFieldDisabled" style="margin-top: 0;">
                                                        <!-- <mat-label class="field-label">From</mat-label> -->
                                                        <input matInput formControlName="from" class="from-input" readonly>
                                                    </mat-form-field>
                                                    <!-- FALSE FROM -->

                                                </div>
                                                <!-- FIELD BOX -->

                                                <!-- FIELD BOX -->
                                                <div class="field-box" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                                    <!-- TO -->
                                                    <div class="field-box-title">
                                                        <span>To</span>
                                                    </div>
                                                    <mat-form-field appearance="outline" class="to-field" *ngIf="!isToFieldDisabled">
                                                        <!-- <mat-label class="field-label">To</mat-label> -->
                                                        <!-- <input
                          *ngIf="options.bounds"
                          matInput
                          formControlName="to"
                          ngx-google-places-autocomplete
                          [options]="options"
                          (onAddressChange)="handleToAddressChange($event, vehicleTypeForm)"
                          class="to-input"
                        > -->
                                                        <input matInput formControlName="to" class="to-input" [matAutocomplete]="auto" #toInput1 (click)="onInputClick(3)">
                                                        <mat-autocomplete #auto="matAutocomplete" class="autocomplete-panel" (optionSelected)="handleToAddressChange2($event.option.value, vehicleTypeForm)">
                                                            <mat-option class="autocomplete-option" *ngFor="let result of filteredToResults2 | async; let i = index" [value]="result">
                                                                <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
                                                                    <img src="assets/img/markers/{{result.icon!}}.svg" alt="icon" height="15" width="20">
                                                                    <div fxFlex fxLayout="column" fxLayoutAlign="center start" fxFlex style="margin-left: 1em;">
                                                                        <span class="autocomplete-title">
                                  <strong *ngIf="result.title">{{ result.title }}</strong>
                                </span>
                                                                        <span class="autocomplete-metadata">
                                  <span class="item-metastat">
                                    <small>{{result.address}}</small>
                                  </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <!-- TO -->

                                                    <!-- FALSE TO -->
                                                    <mat-form-field appearance="outline" class="to-field" *ngIf="isToFieldDisabled">
                                                        <!-- <mat-label class="field-label">To</mat-label> -->
                                                        <input matInput formControlName="to" class="to-input" readonly>
                                                    </mat-form-field>
                                                    <!-- FALSE TO -->

                                                </div>
                                                <!-- FIELD BOX -->

                                                <!-- FIELD BOX -->
                                                <div class="field-box" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                                    <!-- FIELD CONTAINER -->
                                                    <div class="field-container" fxLayout="row" fxLayoutAlign="start stretch" fxFlex>

                                                        <!-- DATE-CONTAINER -->
                                                        <div class="date-container" fxLayout="column" fxLayoutAlign="start center" fxFlex>
                                                            <div class="field-box-title" style="margin-right: auto!important;margin-left: 10%;">
                                                                <span>Date</span>
                                                            </div>
                                                            <mat-form-field appearance="outline" class="form-field half-form-field">
                                                                <!-- <mat-label>Choose a date</mat-label> -->
                                                                <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="date" (dateChange)="dateChanged($event, vehicleTypeForm)" *ngIf="options.bounds">
                                                                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                <mat-datepicker #picker></mat-datepicker>
                                                                <!-- <mat-error class="validation-error" *ngIf="quoteForm.get('date')?.errors?.required">Field is required</mat-error> -->
                                                            </mat-form-field>
                                                        </div>
                                                        <!-- DATE-CONTAINER -->

                                                        <!-- TIME-CONTAINER -->
                                                        <div class="time-container" fxLayout="column" fxLayoutAlign="start center" fxFlex>
                                                            <div class="field-box-title" style="margin-right: auto!important;margin-left: 10%;">
                                                                <span>Time</span>
                                                            </div>
                                                            <mat-form-field appearance="outline" class="form-field half-form-field">
                                                                <!-- <mat-label></mat-label> -->
                                                                <mat-select formControlName="time" *ngIf="options.bounds">
                                                                    <mat-option *ngFor="let hour of availableHoursArray" [value]="hour">{{ hour }}</mat-option>
                                                                </mat-select>
                                                                <!-- <mat-error class="validation-error" *ngIf="quoteForm.get('time')?.errors?.required">Field is required</mat-error> -->
                                                            </mat-form-field>
                                                        </div>
                                                        <!-- TIME-CONTAINER -->

                                                    </div>
                                                    <!-- FIELD CONTAINER -->

                                                </div>
                                                <!-- FIELD BOX -->

                                                <!-- FIELD BOX -->
                                                <div class="field-box" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                                    <!-- FIELD CONTAINER -->
                                                    <div class="field-container" fxLayout="row" fxLayoutAlign="start stretch" fxFlex>

                                                        <!-- PASSENGERS-CONTAINER -->
                                                        <div class="passengers-container" fxLayout="column" fxLayoutAlign="start center" fxFlex>
                                                            <div class="field-box-title" style="margin-right: auto!important;margin-left: 10%;">
                                                                <span>Passengers</span>
                                                            </div>
                                                            <mat-form-field appearance="outline" class="form-field half-form-field">
                                                                <!-- <mat-label></mat-label> -->
                                                                <div class="sign-container" fxLayout="row" fxLayoutAlign="start stretch">
                                                                    <mat-icon (click)="editPassengers('decrease', vehicleTypeForm)">remove</mat-icon>
                                                                    <input matInput formControlName="passengers" type="number" min="1" max="100" class="form-input" readonly *ngIf="options.bounds">
                                                                    <mat-icon (click)="editPassengers('increase', vehicleTypeForm)">add</mat-icon>
                                                                </div>
                                                                <!-- <mat-error class="validation-error" *ngIf="quoteForm.get('passengers')?.errors?.required">Field is required</mat-error> -->
                                                            </mat-form-field>
                                                        </div>
                                                        <!-- PASSENGERS-CONTAINER -->

                                                        <!-- LUGGAGE-CONTAINER -->
                                                        <div class="luggage-container" fxLayout="column" fxLayoutAlign="start center" fxFlex>
                                                            <div class="field-box-title" style="margin-right: auto!important;margin-left: 10%;">
                                                                <span>Luggage</span>
                                                            </div>
                                                            <mat-form-field appearance="outline" class="form-field half-form-field">
                                                                <!-- <mat-label></mat-label> -->
                                                                <div class="sign-container" fxLayout="row" fxLayoutAlign="start stretch">
                                                                    <mat-icon (click)="editLuggagePieces('decrease', vehicleTypeForm)">remove</mat-icon>
                                                                    <input matInput formControlName="luggage" type="number" min="0" max="100" class="form-input" readonly *ngIf="options.bounds">
                                                                    <mat-icon (click)="editLuggagePieces('increase', vehicleTypeForm)">add</mat-icon>
                                                                </div>
                                                                <!-- <mat-error class="validation-error" *ngIf="quoteForm.get('luggage')?.errors?.required">Field is required</mat-error> -->
                                                            </mat-form-field>
                                                        </div>
                                                        <!-- LUGGAGE-CONTAINER -->

                                                    </div>
                                                    <!-- FIELD CONTAINER -->

                                                </div>
                                                <!-- FIELD BOX -->

                                            </form>
                                            <!-- FORM -->

                                            <!-- BUTTON-CONTAINER -->
                                            <div class="find-your-transfer-button-container" fxLayout="row" fxLayoutAlign="start stretch" fxFlex>
                                                <button mat-button class="get-price-button" type="button" fxFlex.sm (click)="onFindYourTransferClick()" fxLayout="row" fxLayoutAlign="center center" [disabled]="!checkFormState()">
                      <span fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="update-search-icon" [ngClass]="{'update-search-icon-disabled': !checkFormState()}">search</mat-icon>
                        <p style="margin-left: 5px; margin-bottom: 0;">Find Your Transfer</p>
                      </span>
                    </button>
                                            </div>
                                            <!-- BUTTON-CONTAINER -->

                                        </div>

                                    </div>
                                    <!-- INITIAL FORM CONTAINER -->

                                </div>
                                <!-- CONTENT-CONTAINER -->

                            </div>
                            <!-- STEP-CONTAINER -->

                        </mat-step>

                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
                        <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->

                        <!-- MAT-STEP 2 -->
                        <mat-step [stepControl]="transferForm" [label]="titleState[2]" [optional]="isTransferStepOptional">

                            <div class="step-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
                                <div class="content-container" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxFlex>

                                    <div class="booking-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex fxFlexOrder="1" fxFlexOrder.sm="1" fxFlexOrder.xs="2" [ngClass]="{'booking-container-mobile' : xsTransferStep}">

                                        <div class="book-header booker-container" fxLayout="row" fxLayoutAlign="start center" id="booking-header">
                                            <p class="book-title" fxFlex>Booking Details</p>
                                        </div>
                                        <mat-divider></mat-divider>

                                        <form class="form-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex [formGroup]="transferForm" id="booking-form-container" [style.min-height]="xsTransferStep && isOrderSummaryVisible ? '1100px' : '800px'">

                                            <div class="field-title">
                                                <span>Name</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="booking-form-field memer">
                                                <mat-label class="field-label">Name</mat-label>
                                                <input matInput formControlName="first_name" class="form-input">
                                            </mat-form-field>

                                            <div class="field-title">
                                                <span>Surname</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="booking-form-field memer">
                                                <mat-label class="field-label">Surname</mat-label>
                                                <input matInput formControlName="last_name" class="form-input">
                                            </mat-form-field>

                                            <div class="field-title">
                                                <span>Passport/ ID Number</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="booking-form-field memer">
                                                <mat-label class="field-label">Passport/ ID Number</mat-label>
                                                <input matInput formControlName="passport" class="form-input">
                                            </mat-form-field>

                                            <div class="field-title">
                                                <span>Voucher</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="booking-form-field memer">
                                                <mat-label class="field-label">Flight/ Train/ Ship Number</mat-label>
                                                <input matInput formControlName="voucher" class="form-input">
                                            </mat-form-field>

                                            <div class="field-title">
                                                <span>Email</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="booking-form-field memer">
                                                <mat-label class="field-label">Email</mat-label>
                                                <input matInput formControlName="email" class="form-input">
                                            </mat-form-field>

                                            <div class="field-title ">
                                                <span>Mobile Phone Number</span>
                                            </div>
                                            <ngx-intl-tel-input [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                                                [selectedCountryISO]="CountryISO.Greece" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" (countryChange)="onCountryChange($event)"
                                                #phoneInput formControlName="mobile" name="mobile" id="tel" class="form-input"></ngx-intl-tel-input>


                                            <div class="field-title">
                                                <span>Child Seats</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="form-field memer" style="margin-bottom: 18px;">
                                                <div class="sign-container" fxLayout="collumn" fxLayoutAlign="start stretch">
                                                    <mat-icon (click)="editChildSeats('decrease')">remove</mat-icon>
                                                    <input matInput formControlName="childSeats" type="number" min="0" max="100" class="form-input" readonly>
                                                    <mat-icon (click)="editChildSeats('increase')">add</mat-icon>
                                                </div>
                                            </mat-form-field>

                                            <div class="field-title">
                                                <span>Notes</span>
                                            </div>
                                            <mat-form-field appearance="outline" class="notes-form-field memer" [ngClass]="{'notes-form-field-mobile': xsTransferStep}" fxFlex>
                                                <mat-label class="field-label">Notes</mat-label>
                                                <textarea name="notes" rows="3" formControlName="special_instructions" matInput class="notes-input"></textarea>
                                            </mat-form-field>
                                        </form>
                                    </div>

                                    <div class="payment-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex *ngIf="xsTransferStep" fxFlexOrder.xs="3">

                                        <div class="header" fxLayout="row" fxLayoutAlign="start stretch">
                                            <!-- MOBILE 3rd STEP -->

                                            <div class="payment-header-container" fxLayout="row" fxLayoutAlign="start stretch" fxFlex="45%">
                                                <div class="header" fxLayout="row" fxLayoutAlign="start center">
                                                    <p class="header-title" style="font-size: 20px;" fxFlex *ngIf="!oneWayOfferRequestForced">Payment</p>
                                                    <p class="header-title" style="font-size: 20px;color: #3D85E3" fxFlex *ngIf="oneWayOfferRequestForced">Offer Request</p>
                                                </div>
                                            </div>
                                            <div class="payment-header-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex style="padding: 10px 0 0 0;" *ngIf="!oneWayOfferRequestForced">
                                                <span fxFlex fxLayout="row" fxLayoutAlign="end center" class="row" fxFlex>
                      <span class="total-price" fxLayout="row" fxLayoutAlign="end stretch" style="padding-right: 15px;">
                        <mat-icon [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" 
                        style="font-size: 28px; margin-right: 5px">euro</mat-icon>

                        <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="cashIsSelected || cardToDriverIsSelected" style="margin: 0;">{{offerPrice}}</p>
                        <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="depositIsSelected" style="margin: 0;">{{ depositPrice }}</p>
                        <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="cardIsSelected" style="margin: 0;">{{ discountedPrice }}</p>
                      </span>
                                                </span>
                                                <!-- <span fxLayoutAlign="end end" class="row" id="summary-info" style="padding-right: 15px;" *ngIf="cashIsSelected || cardIsSelected || depositIsSelected || cardToDriverIsSelected">Total price</span> -->
                                                <span fxLayoutAlign="end end" class="row" id="summary-info" style="padding-right: 15px;" *ngIf="cashIsSelected || cardIsSelected || depositIsSelected || cardToDriverIsSelected">Taxes & fees included</span>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                        <div class="form-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex id="quote-content">

                                            <!-- <div fxLayout="column" fxLayoutAlign="center end" fxFlex *ngIf="oneWayOfferRequestForced" 
                style="margin-left: 40%; margin-bottom: 10%; font-size: 32px; font-weight: 600; color: #3D85E3; padding-right: 15px;">
                  <p [style.visibility]="oneWayOfferRequestForced ? 'visible' : 'hidden'">Offer Request</p>
                </div> -->

                                            <div class="summary-content" id="summary-mobile" fxLayout="column" fxLayoutAlign="center stretch" fxFlex>
                                                <div class="section" fxLayout="column" fxLayoutAlign="start stretch" fxFlex [formGroup]="paymentForm">
                                                    <p class="header-title" fxFlex style="font-size: 14px; color: #2d3b4e80; font-weight: 700; padding: 0px 15px 10px 15px;" *ngIf="!oneWayOfferRequestForced">Payment Method</p>
                                                    <p fxFlex style="font-size: 15px;padding: 0px 15px 10px 15px;" *ngIf="oneWayOfferRequestForced">
                                                        For the exact transfer that you selected</p>
                                                    <p fxFlex style="font-size: 15px;padding: 0px 15px 10px 15px;" *ngIf="oneWayOfferRequestForced">
                                                        you will receive an offer from us soon.</p>

                                                    <mat-radio-group *ngIf="!oneWayOfferRequestForced" class="example-radio-group" formControlName="payment_method" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                                                        <mat-radio-button class="row" *ngFor="let paymentMethod of paymentMethodsArray" [value]="paymentMethod.paymentMethod" (change)="onPaymentMethodChange($event)" [disableRipple]="true" style="padding-left: 15px; font-weight: 700; color: #2d3b4e80;" [checked]="paymentMethod.checked">
                                                            {{paymentMethod.paymentMethod}}
                                                        </mat-radio-button>
                                                    </mat-radio-group>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="summary-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px" fxLayoutGap.xs="15px" fxLayoutGap.sm="15px" fxFlex="45%" fxFlexOrder="2" fxFlexOrder.sm="2" fxFlexOrder.xs="1">

                                        <div class="quote-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                            <div class="header" fxLayout="row" fxLayoutAlign="start center" [fxLayoutAlign.xs]="xsTransferStep && isOrderSummaryVisible ? 'start stretch' : 'start center'" [style.height]="xsTransferStep && isOrderSummaryVisible ? '95px' : '70px'">
                                                <p class="book-title" fxFlex *ngIf="!xsTransferStep">Order Summary</p>

                                                <div class="header-title-container" fxFlex fxLayout="column" fxLayoutAlign="center start" *ngIf="xsTransferStep">

                                                    <p class="header-title" fxFlex style="font-size: 20px;" fxLayout="column" fxLayoutAlign="center center">Order Summary</p>
                                                    <span fxLayoutAlign="end end" class="row" id="summary-info" style="padding-right: 15px;" *ngIf="xsTransferStep && isOrderSummaryVisible && !oneWayOfferRequestForced" style="color: rgba(43, 62, 78, 0.5); font-weight: 600; line-height: 18px;">
                        Total price <br> Taxes & fees included
                      </span>
                                                    <span fxLayoutAlign="end end" class="row" id="summary-info" style="padding-right: 15px;" *ngIf="xsTransferStep && isOrderSummaryVisible && oneWayOfferRequestForced" style="color: rgba(43, 62, 78, 0.5); font-weight: 600; line-height: 18px;">
                        Offer Request
                      </span>
                                                </div>

                                                <div class="header-button" fxFlex fxLayout="row" fxLayoutAlign="end center">

                                                    <span fxFlex fxLayout="row" fxLayoutAlign="end center" class="row" fxFlex *ngIf="xsTransferStep && !oneWayOfferRequestForced">
                        <span class="total-price" fxLayout="row" fxLayoutAlign="end stretch" style="padding-right: 10px; font-size: 24px;">
                          <mat-icon [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" style="font-size: 24px; margin-right: 5px" fxLayout="row" fxLayoutAlign="center center">euro</mat-icon>
                          <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="cashIsSelected || cardToDriverIsSelected" style="margin: 0;">{{offerPrice}}</p>
                          <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="depositIsSelected" style="margin: 0;">{{ depositPrice }}</p>
                          <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="cardIsSelected" style="margin: 0;">{{ discountedPrice }}</p>
                        </span>
                                                    </span>

                                                    <button class="button-overlay" id="order-summary-button" fxLayout="row" fxLayoutAlign="center center" (click)="toggleOrderSummaryVisibility()">
                        <mat-icon fxLayout="row" fxLayoutAlign="center center" style="font-size: 24px; font-weight: 600;" *ngIf="!isOrderSummaryVisible">expand_more</mat-icon>
                        <mat-icon fxLayout="row" fxLayoutAlign="center center" style="font-size: 24px; font-weight: 600;" *ngIf="isOrderSummaryVisible">expand_less</mat-icon>
                      </button>

                                                </div>
                                            </div>

                                            <mat-divider></mat-divider>

                                            <div class="form-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex *ngIf="isOrderSummaryVisible" id="quote-content">

                                                <div class="summary-content" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                                    <div class="section" fxLayout="row" fxLayoutAlign="start stretch" style="color: #2b3e4e80;" fxFlex>
                                                        <span fxFlex fxLayoutAlign="space-between center" class="row" id="summary-info">
                          <mat-icon class="summary-icons" style="color: #3D85E3; font-size: 20px; margin-right: 10px;" fxLayout="row" fxLayoutAlign="center center">event</mat-icon>
                          <p style="margin: 0; padding-right: 15px; font-size: 14px; font-weight: 600;">{{ dateView }}</p>
                          <mat-icon class="summary-icons" style="color: #3D85E3; font-size: 20px; margin-right: 10px;" fxLayout="row" fxLayoutAlign="center center">watch_later</mat-icon>
                          <p fxLayout="row" fxLayoutAlign="center center" style="margin: 0; font-size: 14px; font-weight: 600;">{{ quoteForm.controls['time']!.value }}</p>
                        </span>
                                                        <span fxFlex fxLayoutAlign="start center" class="row" id="summary-info"></span>
                                                        <span fxFlex fxLayoutAlign="end center" class="row">
                          <button mat-button matStepperPrevious type="button">Edit</button>
                        </span>
                                                    </div>

                                                    <div class="section" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="50%">
                                                        <span fxFlex fxLayoutAlign="start center" class="row" id="address-info">
                          <mat-icon class="summary-icons" style="font-size: 20px; color: #3D85E3; margin-right: 10px;">trip_origin</mat-icon>
                          <p>{{ quoteForm.controls['from']!.value }}</p>
                        </span>
                                                        <span fxFlex fxLayoutAlign="start center" class="row" id="address-info">
                          <mat-icon class="summary-icons" style="font-size: 20px; color: #3D85E3; margin-right: 10px;">location_pin</mat-icon>
                          <p>{{ quoteForm.controls['to']!.value }}</p>
                        </span>
                                                        <span *ngIf="selectedVehicleType !== undefined && !oneWayOfferRequestForced" fxFlex fxLayout="row" fxLayoutAlign="start center" id="address-info">
                          <mat-icon class="summary-icons" style="color: #3D85E3; font-size: 20px; margin-right: 10px;">directions_car</mat-icon>
                          <p style="font-size: 16px; font-weight: 500; color: #2d3b4e;">{{ selectedVehicleType.offer.response.vehicle_type_details.title }}</p>
                        </span>
                                                    </div>

                                                    <div class="section" fxLayout="row" fxLayoutAlign="start stretch" style="color: #2b3e4e80; margin-top: 15px;" fxFlex>

                                                        <div class="vehicle-type-image" *ngIf="selectedVehicleType !== undefined && !oneWayOfferRequestForced">
                                                            <img class="image-sml" [src]="selectedVehicleType.img" alt="vehicle-type-image">
                                                        </div>
                                                        <span fxFlex="15" fxLayout="row" fxLayoutAlign="space-around center" class="row">
                          <mat-icon class="summary-icons" style="font-size: 20px; color: #3D85E3; margin-left: 75px; margin-right: 10px">people</mat-icon>
                          <p style="font-size: 16px; font-weight: 500; color: #2d3b4e; margin-right: 20px">{{ quoteForm.controls['passengers']!.value }}</p>
                        </span>
                                                        <span fxFlex="15" fxLayout="row" fxLayoutAlign="space-around center" class="row">
                          <mat-icon class="summary-icons" style="font-size: 20px; color: #3D85E3; margin-left: 75px; margin-right: 10px">luggage</mat-icon>
                          <p style="font-size: 16px; font-weight: 500; color: #2d3b4e;">{{ quoteForm.controls['luggage']!.value }}</p>
                        </span>
                                                        <span fxFlex class="row"></span>
                                                    </div>

                                                </div>

                                                <mat-divider style="margin: 0 15px 0 15px;" *ngIf="xsTransferStep && isOrderSummaryVisible"></mat-divider>

                                                <div class="map-container" fxLayout="column" fxLayoutAlign="center center" fxFlex *ngIf="isRouteVisible && xsTransferStep && isOrderSummaryVisible">
                                                    <!-- <google-map class="map" [options]="mapOptions" [width]="'340'" [height]="'265'">
                      <map-marker *ngFor="let marker of markers" [position]="marker.position"></map-marker>
                    </google-map> -->
                                                    <agm-map id="vehicle-mobile-map" [fullscreenControl]='false' [streetViewControl]='false' [mapTypeControl]='false' [fitBounds]="true">
                                                        <agm-marker *ngFor="let coordinate of agmMarkers; let i=index" [latitude]="coordinate[0]" [longitude]="coordinate[1]" [agmFitBounds]="true"></agm-marker>
                                                    </agm-map>
                                                </div>

                                                <mat-divider style="margin: 0 15px 0 15px;" *ngIf="xsTransferStep && isOrderSummaryVisible"></mat-divider>

                                                <div class="map-container" fxLayout="row" fxLayoutAlign="start stretch" fxFlex *ngIf="xsTransferStep && isOrderSummaryVisible" style="padding: 10px 32px 10px 32px;">

                                                    <div class="header-title-container" fxFlex fxLayout="column" fxLayoutAlign="center start" *ngIf="xsTransferStep">

                                                        <!-- <span fxLayoutAlign="end end" class="row" id="summary-info" *ngIf="xsTransferStep && isOrderSummaryVisible" style="color: rgba(43, 62, 78, 0.5); font-weight: 600; line-height: 18px;">
                          Total price <br> Taxes & fees included
                        </span> -->
                                                        <span fxLayoutAlign="end end" class="row" id="summary-info" *ngIf="xsTransferStep && isOrderSummaryVisible" style="color: rgba(43, 62, 78, 0.5); font-weight: 600; line-height: 18px;" [style.visibility]="!offerRequestIsSelected ? 'hidden' : 'visible'">
                          Offer Request
                        </span>

                                                    </div>

                                                    <!-- <div class="header-button" fxFlex fxLayout="row" fxLayoutAlign="end center">

                        <span fxFlex fxLayout="row" fxLayoutAlign="end center" class="row" fxFlex *ngIf="xsTransferStep && isOrderSummaryVisible">
                          <span class="total-price" fxLayout="row" fxLayoutAlign="end stretch" style="padding-right: 10px; font-size: 24px;">
                            <mat-icon [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" 
                            style="font-size: 24px; margin-right: 5px" fxLayout="row" fxLayoutAlign="center center" 
                            *ngIf="!oneWayOfferRequestForced">euro</mat-icon>
                            <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="cashIsSelected || cardIsSelected || cardToDriverIsSelected">{{offerPrice}}</p>
                            <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="depositIsSelected">{{ depositPrice }}</p>
                          </span>
                        </span>

                      </div> -->

                                                </div>

                                            </div>

                                        </div>

                                        <div class="route-container" id="router1" fxLayout="column" fxLayoutAlign="start stretch" fxFlex *ngIf="!isTest">

                                            <div class="header" fxLayout="row" fxLayoutAlign="start center">
                                                <p class="book-title">Route</p>
                                                <p class="header-title" fxFlex style="color: #2b3e4e80; font-size: 14px; visibility: hidden;">27 km,33 min</p>

                                            </div>

                                            <mat-divider></mat-divider>

                                            <div class="form1s-container" fxLayout="column" fxLayoutAlign="center center" fxFlex id="map-container">
                                                <!-- <google-map id="map1" class="map" [options]="mapOptions" class="route-map">
                    <map-marker *ngFor="let marker of markers" [position]="marker.position"></map-marker>
                  </google-map> -->
                                                <agm-map id="route-map" [fullscreenControl]='false' [streetViewControl]='false' [mapTypeControl]='false' [fitBounds]="true">
                                                    <agm-marker *ngFor="let coordinate of agmMarkers; let i=index" [latitude]="coordinate[0]" [longitude]="coordinate[1]" [agmFitBounds]="true"></agm-marker>
                                                </agm-map>
                                            </div>

                                        </div>

                                        <div class="payment-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex *ngIf="!isTest">

                                            <div class="header" fxLayout="row" fxLayoutAlign="start stretch">

                                                <div class="payment-header-container" fxLayout="row" fxLayoutAlign="center stretch" fxFlex="45%">
                                                    <div class="header" fxLayout="row" fxLayoutAlign="start center">
                                                        <p class="book-title" *ngIf="!oneWayOfferRequestForced" fxFlex>Payment</p>
                                                        <p class="header-title" style="font-size: 20px;padding-left: 30px;color: #3D85E3" *ngIf="oneWayOfferRequestForced" fxFlex>Offer Request</p>
                                                    </div>
                                                </div>

                                                <div class="payment-header-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex style="padding: 10px 0 0 0;" *ngIf="!oneWayOfferRequestForced">
                                                    <span fxFlex fxLayout="row" fxLayoutAlign="end center" class="row" fxFlex>
                        <span class="total-price" fxLayout="row" fxLayoutAlign="end stretch" style="padding-right: 15px;">
                          <mat-icon [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" 
                          style="font-size: 28px; margin-right: 5px">euro</mat-icon>
                          <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="cashIsSelected || cardToDriverIsSelected" style="margin: 0;">{{offerPrice}}</p>
                          <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="depositIsSelected" style="margin: 0;">{{ depositPrice }}</p>
                          <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="cardIsSelected" style="margin: 0;">{{ discountedPrice }}</p>
                        </span>
                                                    </span>
                                                    <span fxLayoutAlign="end end" class="row" id="summary-info" style="padding-right: 15px;" *ngIf="cashIsSelected || cardIsSelected || depositIsSelected || cardToDriverIsSelected">Total price</span>
                                                    <span fxLayoutAlign="end end" class="row" id="summary-info" style="padding-right: 15px;" *ngIf="cashIsSelected || cardIsSelected || depositIsSelected || cardToDriverIsSelected">Taxes & fees included</span>
                                                </div>
                                            </div>

                                            <mat-divider style="margin-bottom: 15px;"></mat-divider>

                                            <div class="form-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex *ngIf="isOrderSummaryVisible" id="quote-content">

                                                <div fxLayout="column" fxLayoutAlign="center end" fxFlex *ngIf="oneWayOfferRequestForced" style="margin-left: 40%; margin-bottom: 10%; font-size: 32px; font-weight: 600; color: #3D85E3; padding-right: 15px;">
                                                    <!-- <p [style.visibility]="oneWayOfferRequestForced ? 'visible' : 'hidden'">Offer Request</p> -->
                                                    <!-- <p [style.visibility]="oneWayOfferRequestForced ? 'visible' : 'hidden'">Request</p> -->
                                                </div>

                                                <div class="summary-content" fxLayout="column" fxLayoutAlign="center stretch" fxFlex>

                                                    <div class="section" fxLayout="column" fxLayoutAlign="start stretch" fxFlex [formGroup]="paymentForm">
                                                        <p fxFlex style="font-size: 15px;padding: 0px 15px 10px 15px;margin-top: -15px;" *ngIf="oneWayOfferRequestForced">
                                                            For the exact transfer that you selected</p>
                                                        <p fxFlex style="font-size: 15px;padding: 0px 15px 10px 15px;" *ngIf="oneWayOfferRequestForced">
                                                            you will receive an offer from us soon.</p>

                                                        <mat-radio-group *ngIf="!oneWayOfferRequestForced" class="example-radio-group" formControlName="payment_method" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="15px">
                                                            <mat-radio-button class="row" *ngFor="let paymentMethod of paymentMethodsArray" [value]="paymentMethod.paymentMethod" (change)="onPaymentMethodChange($event)" [disableRipple]="true" style="padding-left: 15px; font-weight: 700; color: #2d3b4e80;" [checked]="paymentMethod.checked"
                                                                [style.display]="oneWayOfferRequestForced && paymentMethod.paymentMethod !== 'Offer Request' ? 'none' : ''">
                                                                {{paymentMethod.paymentMethod}}
                                                            </mat-radio-button>
                                                        </mat-radio-group>

                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div class="continue-button-container tra-pay" fxLayout="row" fxLayoutAlign="start stretch" fxFlex>
                                    <button mat-button matStepperNext class="get-price-button" type="button" fxFlex.sm (click)="onContinueButtonClick2()">Continue</button>
                                </div>

                            </div>

                        </mat-step>

                        <!-- MAT-STEP 3 -->
                        <mat-step [stepControl]="paymentForm" [label]="titleState[3]" [optional]="isPaymentStepOptional">

                            <!-- STEP-CONTAINER -->
                            <div class="step-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                <!-- CONTENT-CONTAINER -->
                                <div class="content-container" fxLayout="row" fxLayout.sm="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxFlex>

                                    <!-- BOOKING-CONTAINER -->
                                    <div class="booking-container" id="final-screen" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                        <!-- HEADER -->
                                        <div class="header" fxLayout="row" fxLayoutAlign="start center" id="booking-header">
                                            <p class="book-title" fxFlex>Payment</p>
                                        </div>
                                        <!-- HEADER -->

                                        <!-- MAT-DIVIDER -->
                                        <mat-divider></mat-divider>
                                        <!-- MAT-DIVIDER -->

                                        <div class="form-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                            <!-- CASH CONTAINER -->
                                            <div class="cash-container" *ngIf="cashIsSelected || cardToDriverIsSelected" fxLayout="column" fxLayoutAlign="center center" fxFlex>

                                                <!-- loading effect -->
                                                <div class="spinner-container" fxLayout="column" fxLayoutAlign="center center" *ngIf="!postTransferResolved">
                                                    <mat-spinner class="loading-spinner" [diameter]="85" [strokeWidth]="3" mode="indeterminate"></mat-spinner>
                                                </div>

                                                <div class="thank-you" fxLayout="column" fxLayoutAlign="center center" fxFlex *ngIf="postTransferResolved">
                                                    <span style="margin: 20px 0 10px 0;" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon style="font-size: 48px; color: #66CC00" fxLayout="column" fxLayoutAlign="center center">task_alt</mat-icon>
                      </span>
                                                    <span fxLayout="column" fxLayoutAlign="center center" style="margin: 20px 0 10px 0; font-size: 24px; font-weight: 500; color: #2d3b4e; text-align: center; line-height:24px;">
                        Your transfer has been <br> successfully registered.
                      </span>
                                                    <span fxLayout="column" fxLayoutAlign="center center" style="margin: 10px 0 10px 0; font-size: 24px; font-weight: 500; color: #2d3b4e;">
                        Thank you!
                      </span>
                                                </div>
                                            </div>

                                            <!-- OFFER REQUEST CONTAINER -->
                                            <div class="cash-container" *ngIf="offerRequestIsSelected" fxLayout="column" fxLayoutAlign="center center" fxFlex>

                                                <!-- loading effect -->
                                                <div class="spinner-container" fxLayout="column" fxLayoutAlign="center center" *ngIf="!postTransferResolved">
                                                    <mat-spinner class="loading-spinner" [diameter]="85" [strokeWidth]="3" mode="indeterminate"></mat-spinner>
                                                </div>

                                                <div class="thank-you" fxLayout="column" fxLayoutAlign="center center" fxFlex *ngIf="postTransferResolved">
                                                    <span style="margin: 20px 0 10px 0;" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon style="font-size: 48px; color: #66CC00" fxLayout="column" fxLayoutAlign="center center">task_alt</mat-icon>
                      </span>
                                                    <span style="margin: 20px 0 10px 0; font-size: 24px; font-weight: 500; color: #2d3b4e; text-align: center; line-height:24px;">
                        Your offer request has been<br> successfully registered.
                      </span>
                                                    <span style="margin: 10px 0 10px 0; font-size: 24px; font-weight: 500; color: #2d3b4e;">
                        We will contact you as soon as possible.
                      </span>
                                                    <span fxLayout="column" fxLayoutAlign="center center" style="margin: 10px 0 10px 0; font-size: 24px; font-weight: 500; color: #2d3b4e;">
                        Thank you!
                      </span>
                                                </div>
                                            </div>

                                            <!-- STRIPE CONTAINER -->
                                            <div class="stripe-container" *ngIf="cardIsSelected || depositIsSelected" fxFlex>
                                                <!-- <app-payment [clientSecret]="paymentIntentClientSecret" [amount]="offerPrice" [connectedAccount]="connectedAccount" [publishableApiKey]="publishableApiKey" *ngIf="clientSecretRetrieved"></app-payment> -->
                                                <app-payment [clientSecret]="paymentIntentClientSecret" [amount]="discountedPrice" [connectedAccount]="connectedAccount" [publishableApiKey]="publishableApiKey" [customerEmail]="transferForm.controls['email'].value" *ngIf="clientSecretRetrieved && cardIsSelected"></app-payment>
                                                <app-payment [clientSecret]="paymentIntentClientSecret" [amount]="finalPrice" [connectedAccount]="connectedAccount" [publishableApiKey]="publishableApiKey" [customerEmail]="transferForm.controls['email'].value" *ngIf="clientSecretRetrieved && depositIsSelected"></app-payment>
                                            </div>

                                        </div>

                                    </div>
                                    <!-- BOOKING-CONTAINER -->

                                    <!-- ORDER-SUMMARY-CONTAINER -->
                                    <div class="summary-order-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" fxLayoutGap.xs="20px" fxLayoutGap.sm="20px" fxFlex="40%" *ngIf="!paymentMobileMode">

                                        <!-- QUOTE-CONTAINER -->
                                        <div class="quote-container" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                            <!-- HEADER -->
                                            <div class="header" fxLayout="row" fxLayoutAlign="start center" id="quote-header">
                                                <p class="book-title" fxFlex>Order Summary</p>
                                            </div>
                                            <!-- HEADER -->

                                            <!-- MAT-DIVIDER -->
                                            <mat-divider></mat-divider>
                                            <!-- MAT-DIVIDER -->

                                            <!-- ORDER-SUMMARY-CONTENT -->
                                            <div class="summary-content" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>

                                                <!-- SECTION -->
                                                <div class="section" fxLayout="row" fxLayoutAlign="start stretch" style="color: #2b3e4e80;" fxFlex>
                                                    <span fxFlex fxLayoutAlign="start center" class="row" id="summary-info" style="font-size: 14px; font-weight: 600;">
                        <p style="margin: 0; font-size: 14px; padding-right: 5px">{{ transferForm.controls['first_name']!.value }}</p>
                        <p style="margin: 0; font-size: 14px;">{{ transferForm.controls['last_name']!.value }}</p>
                      </span>
                                                    <span fxFlex fxLayoutAlign="end center" class="row">
                        <button mat-button matStepperPrevious type="button">Edit</button>
                      </span>
                                                </div>
                                                <!-- SECTION -->

                                                <!-- SECTION -->
                                                <div class="section" fxLayout="row" fxLayoutAlign="start stretch" style="color: #2b3e4e80;" fxFlex>
                                                    <span fxFlex fxLayoutAlign="space-between center" class="row" id="summary-info">
                        <p style="margin: 0; font-size: 14px; padding-right: 5px">{{ this.e164Number }}</p>
                      </span>
                                                    <!-- <span fxFlex fxLayoutAlign="end center" class="row">
                        <button mat-button matStepperPrevious type="button">Edit</button>
                      </span> -->
                                                </div>
                                                <!-- SECTION -->

                                                <!-- SECTION -->
                                                <div class="section" fxLayout="row" fxLayoutAlign="start stretch" style="color: #2b3e4e80;" fxFlex>
                                                    <span fxFlex fxLayoutAlign="space-between center" class="row" id="summary-info">
                        <p style="margin: 0; font-size: 14px; padding-right: 5px">{{ transferForm.controls['email']!.value }}</p>
                      </span>
                                                    <!-- <span fxFlex fxLayoutAlign="end center" class="row">
                        <button mat-button matStepperPrevious type="button">Edit</button>
                      </span> -->
                                                </div>
                                                <!-- SECTION -->

                                                <!-- MAT-DIVIDER -->
                                                <mat-divider style="margin-top: 30px; margin-bottom: 30px;"></mat-divider>
                                                <!-- MAT-DIVIDER -->

                                                <!-- SECTION -->
                                                <div class="section" fxLayout="row" fxLayoutAlign="start stretch" style="color: #2b3e4e80;" fxFlex>
                                                    <span fxFlex fxLayoutAlign="space-between center" class="row" id="summary-info">
                        <p style="margin: 0; padding-right: 15px; font-size: 14px;">{{ dateView }}</p>
                        <p style="margin: 0; font-size: 14px;">{{ quoteForm.controls['time']!.value }}</p>
                      </span>
                                                    <!-- <span fxFlex fxLayoutAlign="start center" class="row" id="summary-info">{{ quoteForm.controls['time']!.value }}</span> -->
                                                    <span fxFlex fxLayoutAlign="start center" class="row" id="summary-info"></span>
                                                    <span fxFlex fxLayoutAlign="end center" class="row">
                        <button mat-button matStepperPrevious type="button">Edit</button>
                      </span>
                                                </div>
                                                <!-- SECTION -->

                                                <!-- SECTION -->
                                                <div class="section" fxLayout="column" fxLayoutAlign="start stretch" fxFlex="50%">
                                                    <span fxFlex fxLayoutAlign="start center" class="row" id="address-info">
                        <mat-icon style="font-size: 24px; color: #3D85E3; margin-right: 10px;">trip_origin</mat-icon>
                        {{ quoteForm.controls['from']!.value }}
                      </span>
                                                    <span fxFlex fxLayoutAlign="start center" class="row" id="address-info">
                        <mat-icon style="font-size: 24px; color: #3D85E3; margin-right: 10px;">location_pin</mat-icon>
                        {{ quoteForm.controls['to']!.value }}
                      </span>
                                                </div>
                                                <!-- SECTION -->

                                                <!-- SECTION -->
                                                <div class="section" fxLayout="row" fxLayoutAlign="start stretch" style="color: #2b3e4e80; margin-top: 15px;" fxFlex>
                                                    <!-- <span fxFlex="15" fxLayout="row" fxLayoutAlign="space-around center" class="row">
                        <mat-icon *ngIf="!(quoteForm.controls['passengers']!.value > 4)">directions_car</mat-icon>
                        <mat-icon *ngIf="quoteForm.controls['passengers']!.value > 4">airport_shuttle</mat-icon>
                      </span> -->
                                                    <span fxFlex="15" fxLayout="row" fxLayoutAlign="space-around center" class="row">
                        <mat-icon>people</mat-icon>
                        {{ quoteForm.controls['passengers']!.value }}
                      </span>
                                                    <span fxFlex="15" fxLayout="row" fxLayoutAlign="space-around center" class="row">
                        <mat-icon>luggage</mat-icon>
                        {{ quoteForm.controls['luggage']!.value }}
                      </span>
                                                    <span fxFlex class="row"></span>
                                                </div>
                                                <!-- SECTION -->

                                                <!-- MAT-DIVIDER -->
                                                <mat-divider style="margin-top: 30px; margin-bottom: 30px;"></mat-divider>
                                                <!-- MAT-DIVIDER -->

                                                <!-- SECTION -->
                                                <div class="section" fxLayout="row" fxLayoutAlign="start stretch" fxFlex>

                                                    <div class="left-box" fxLayout="column" fxLayoutAlign="start stretch" fxFlex></div>

                                                    <div class="right-box" fxLayout="column" fxLayoutAlign="start stretch" fxFlex *ngIf="!oneWayOfferRequestForced && !offerRequestIsSelected">
                                                        <span fxFlex fxLayout="row" fxLayoutAlign="end center" class="row">
                          <span class="total-price" fxLayout="row" fxLayoutAlign="start stretch" style="padding-right: 15px;">
                            <mat-icon style="font-size: 28px;">euro</mat-icon>
                            <!-- 41 -->
                            <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="cashIsSelected || cardToDriverIsSelected" style="margin: 0;">{{offerPrice}}</p>
                            <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="depositIsSelected" style="margin: 0;">{{ depositPrice }}</p>
                            <p [style.visibility]="offerRequestIsSelected ? 'hidden' : 'visible'" *ngIf="cardIsSelected" style="margin: 0;">{{ discountedPrice }}</p>
                          </span>
                                                        </span>
                                                        <span fxFlex fxLayoutAlign="end end" class="row" id="summary-info" style="padding-right: 15px; margin-top: 10px;" *ngIf="cashIsSelected || cardIsSelected || depositIsSelected || cardToDriverIsSelected">Total price</span>
                                                        <span fxFlex fxLayoutAlign="end end" class="row" id="summary-info" style="padding-right: 15px; margin-top: 5px;" *ngIf="cashIsSelected || cardIsSelected || depositIsSelected || cardToDriverIsSelected">Taxes & fees included</span>
                                                    </div>

                                                </div>
                                                <!-- SECTION -->

                                            </div>
                                            <!-- ORDER-SUMMARY-CONTENT -->

                                        </div>
                                        <!-- QUOTE-CONTAINER -->

                                    </div>
                                    <!-- ORDER-SUMMARY-CONTAINER -->

                                </div>
                                <!-- CONTENT-CONTAINER -->

                            </div>
                            <!-- STEP-CONTAINER -->

                            <!-- <ng-template matStepLabel>Payment</ng-template>
          <p>You are now done.</p>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button (click)="stepper.reset()">Reset</button>
          </div> -->

                        </mat-step>
                        <!-- MAT-STEP -->
                    </mat-stepper>
                    <!-- MAT-STEPPER -->
                </mat-tab>
            </mat-tab-group>
            <!-- MAT-Group -->

        </div>


    </div>
    <!-- WEBSITE-WIDGET -->

    <!-- SPACE -->
    <div class="space" fxFlex="2 1 space" *ngIf="isWhiteSpace" #spaceRight></div>
    <div class="space-transfer" fxFlex="2 1 auto" *ngIf="isTransferWhiteSpace"></div>
    <!-- SPACE -->

</div>