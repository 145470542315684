import { Component, OnInit } from '@angular/core';
import { Tours, ToursCategory, ToursGroups, ToursLocations } from '../models/tours-category.model';
import { TourState } from '../models/tours-stage.enum';

@Component({
  selector: 'app-tours-and-activities',
  templateUrl: './tours-and-activities.component.html',
  styleUrls: ['./tours-and-activities.component.css']
})
export class ToursAndActivitiesComponent implements OnInit {

  currentState: TourState = TourState.showTourCategories;
  TourState = TourState;

  selectedToursCategoryTitle: String;
  selectedToursGroupTitle: String;
  selectedTourTitle: String;

  selectedToursGroup: ToursGroups[];
  selectedTours: Tours[];
  selectedTourDetails: Tours;

  columnsToDispay: Number;


  // TODO 8a fygoyn me to endpoint
  toursCategories: ToursCategory[] = [];
  toursGroups: ToursGroups[] = [];
  tours: Tours[] = [];
  locations: ToursLocations[] = [];

  constructor() { }

  ngOnInit(): void {

    this.changeGridColumns();

    this.locations = [
      new ToursLocations({ title: "Location 1", lat: 37.993349, long: 23.725546 },),
      new ToursLocations({ title: "Location 2", lat: 37.972266, long: 23.754358 },),
    ];

    this.tours = [
      new Tours({ id: -1, title: "tour 1", description: "des 1", locations: this.locations, image: "https://assets.thehansindia.com/h-upload/2019/12/27/248830-worldtour.jpg" }),
      new Tours({ id: -1, title: "tour 2", description: "des 2", locations: this.locations, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVo0mFo2Wk6aWEb_2NmZSVQkimfEtmgIQeARpO6THB3w&s" }),
    ];

    this.toursGroups = [
      new ToursGroups({ id: -1, title: "Tour Group 1", description: "des 1", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMVqxysD-kJ-bk30WhgfGgruRhBHpGDZcjeQ&s", tours: this.tours }),
      new ToursGroups({ id: -1, title: "Tour Group 2", description: "des 2", image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWhVU0wjiy-hhd0bal0YXYsYeDOhS9s8HQ9Q&s", tours: this.tours }),
    ];

    this.toursCategories = [
      new ToursCategory({ id: -1, image: "https://a.cdn-hotels.com/gdcs/production191/d99/b5a1612c-1d10-4ab9-a5ca-8f4fd7f760d2.jpg", title: "Meteora Full day Tour", description: "from 520€", toursGroups: this.toursGroups }),
      new ToursCategory({ id: -1, image: "https://media.cntraveler.com/photos/6601df2df768e4e52d1ed287/4:3/w_2248,h_1686,c_limit/Four%20Season%20Yacht%20interiors_Four%20Seasons%20Yacht.jpg", title: "Yacht Rental", description: "from 2.500€", toursGroups: this.toursGroups }),
      new ToursCategory({ id: -1, image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlqXps7uJ3tGVv2rCG7GEWlDmFLwEp_UdluZR8pD7-aTg2fW5UXiFL6bHcwxgNdBRIYEs&usqp=CAU", title: "VIP Security and Bodyguard Services in Mykonos", description: "8hours disposal from 450€", toursGroups: this.toursGroups }),
    ]
  }



  public onViewClick = (args: ToursCategory | ToursGroups | Tours): void => {

    if (args.objectType === ToursCategory) {
      this.selectedToursCategoryTitle = args.title
      this.selectedToursGroup = (args as ToursCategory).toursGroups;
      this.currentState = TourState.showToursGroup;

    } else if (args.objectType === ToursGroups) {
      this.selectedToursGroupTitle = args.title
      this.selectedTours = (args as ToursGroups).tours;
      this.currentState = TourState.showTours;

    } else if (args.objectType === Tours) {
      console.log("tours");
      this.currentState = TourState.showToursDetails;
      this.selectedTourTitle = args.title
      this.selectedTourDetails = (args as Tours);
    }
  }

  public onBackClick = (args: any): void => {

    switch (this.currentState) {
      case TourState.showTourCategories:
        this.currentState = TourState.showTourCategories;
        break;

      case TourState.showToursGroup:
        this.currentState = TourState.showTourCategories;
        break;

      case TourState.showTours:
        this.currentState = TourState.showToursGroup;
        break;

      case TourState.showToursDetails:
        this.currentState = TourState.showTours;
        break;

      default:
        break;
    }

  }

  public onResize(event: any) {
    this.changeGridColumns();
  }


  private changeGridColumns() {

    let shouldIDisplayOneColumn = window.innerWidth <= 750;

    if (shouldIDisplayOneColumn) {
      this.columnsToDispay = 1;

    }
    else {
      this.columnsToDispay = 2;

    }

  }
}
