import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tours, ToursCategory, ToursGroups } from '../../models/tours-category.model';

@Component({
  selector: 'app-tour-card',
  templateUrl: './tour-card.component.html',
  styleUrls: ['./tour-card.component.css']
})
export class TourCardComponent implements OnInit {


  @Input() value: ToursCategory | ToursGroups | Tours;
  @Input() buttonText: String = "View";
  @Output() onViewClick = new EventEmitter<ToursCategory | ToursGroups | Tours>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(value: ToursCategory | ToursGroups | Tours) {
    this.onViewClick.emit(value);
  }

}

