/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2022/12/08
 */

import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationRedirectService {
  private serviceName: string = `NotificationRedirectService`;

  constructor(private router: Router, private ngZone: NgZone) {
    const functionName: string = `constructor`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    // console.log(`${logPath}/`);
  }

  public onNotificationClick(_type: any): void {
    const functionName: string = `onNotificationClick`;
    const logPath: string = `/${this.serviceName}/${functionName}()`;
    console.log(`${logPath}/ @fire type: ${_type}`);

    window.localStorage.removeItem('redirection');

    this.ngZone.run(() => {
      switch(_type) {
        case 'newRnbOrder': {
          // case 1
          this.router.navigate([`/orders/incoming`]);
          console.log(`${logPath}/ @fire orders/incoming redirection`);
          break;
        }
        // case 'booking': {
        //   // case 1
        //   this.router.navigate([`/booking/restaurants`]);
        //   console.log(`${logPath}/ ### booking/restaurants redirection`);
        //   break;
        // }
        // case 'bookingCanceled': {
        //   // case 2
        //   this.router.navigate([`/booking/restaurants`]);
        //   console.log(`${logPath}/ ### booking/restaurants redirection`);
        //   break;
        // }
        // case 'fitness': {
        //   // case 3
        //   this.router.navigate([`/booking/wellness/fitnesses`]);
        //   console.log(`${logPath}/ ### booking/wellness/fitnesses redirection`);
        //   break;
        // }
        // case 'fitnessCanceled': {
        //   // case 4
        //   this.router.navigate([`/booking/wellness/fitnesses`]);
        //   console.log(`${logPath}/ ### booking/wellness/fitnesses redirection`);
        //   break;
        // }
        // case 'spa': {
        //   // case 5
        //   this.router.navigate([`/booking/wellness/spas`]);
        //   console.log(`${logPath}/ ### booking/wellness/spas redirection`);
        //   break;
        // }
        // case 'spaCanceled': {
        //   // case 6
        //   this.router.navigate([`/booking/wellness/spas`]);
        //   console.log(`${logPath}/ ### booking/wellness/spas redirection`);
        //   break;
        // }
        // case 'yoga': {
        //   // case 7
        //   this.router.navigate([`/booking/wellness/yogas`]);
        //   console.log(`${logPath}/ ### booking/wellness/yogas redirection`);
        //   break;
        // }
        // case 'yogaCanceled': {
        //   // case 8
        //   this.router.navigate([`/booking/wellness/yogas`]);
        //   console.log(`${logPath}/ ### booking/wellness/yogas redirection`);
        //   break;
        // }
        // case 'newCheckIn': {
        //   // case 9
        //   this.router.navigate([`/web-checkin`]);
        //   console.log(`${logPath}/ ### web-checkin redirection`);
        //   break;
        // }
        // case 'order': {
        //   // case 10
        //   this.router.navigate([`/orders/incoming`]);
        //   console.log(`${logPath}/ ### orders/incoming redirection`);
        //   break;
        // }
        // case 'sunbedOrder': {
        //   // case 11
        //   this.router.navigate([`/sunbeds-orders/incoming`]);
        //   console.log(`${logPath}/ ### sunbeds-orders/incoming redirection`);
        //   break;
        // }
        // case 'message': {
        //   // case 12
        //   this.router.navigate([`/messages`]);
        //   console.log(`${logPath}/ ### messages redirection`);
        //   break;
        // }
        // case 'housekeepingRequest': {
        //   // case 13
        //   this.router.navigate([`/room-requests/housekeeping`]);
        //   console.log(`${logPath}/ ### room-requests/housekeeping redirection`);
        //   break;
        // }
        // case 'maintenanceRequest': {
        //   // case 14
        //   this.router.navigate([`/room-requests/maintenance`]);
        //   console.log(`${logPath}/ ### room-requests/maintenance redirection`);
        //   break;
        // }
        // case 'housekeeping_request_changed': {
        //   // case 15
        //   this.router.navigate([`/room-requests/housekeeping`]);
        //   console.log(`${logPath}/ ### room-requests/housekeeping redirection`);
        //   break;
        // }
        // case 'maintenance_request_changed': {
        //   // case 16
        //   this.router.navigate([`/room-requests/maintenance`]);
        //   console.log(`${logPath}/ ### room-requests/maintenance redirection`);
        //   break;
        // }
        // case 'room_status_changed': {
        //   // case 17
        //   this.router.navigate([`/room-requests/housekeeping`]);
        //   console.log(`${logPath}/ ### room-requests/housekeeping redirection`);
        //   break;
        // }
        // case 'rating': {
        //   // case 18
        //   this.router.navigate([`/ratings/reviews`]);
        //   console.log(`${logPath}/ ### ratings/reviews redirection`);
        //   break;
        // }
//////////////////// Comment out default case to fix redirection bug that occurs upon changing tab in browser. Temporary solution. ////////////////////////////
        // default : {
        //   this.router.navigate([`/`]);
        //   console.log(`${logPath}/ @fire home redirection`);
        //   break;
        // }
      }
    });

  }
}
