/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/01/17
 */

// Angular
import { NgModule } from '@angular/core';
import { AppRoutingModule } from 'src/app/app-routing.module';
// App
import { SharedModule } from 'src/app/shared/shared.module';
import { SidebarComponent } from './sidebar.component';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    AppRoutingModule,
    SharedModule,
    MaterialModule,
  ],
  exports: [SidebarComponent]
})
export class SidebarModule { }