import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateOrdinalsPipe } from './dateOrdinals.pipe';
import { FilterNamePipe } from './filter-name.pipe';



@NgModule({
  declarations: [
    DateOrdinalsPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    DateOrdinalsPipe,
  ]
})
export class ApplicationPipesModule { }
