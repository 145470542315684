import {Component, Input, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {StripeCardElementOptions, StripeElementsOptions, PaymentIntent} from '@stripe/stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Subscription } from 'rxjs';
import { WebsiteWidgetService } from '../services/website-widget.service';
// import Stripe from '@stripe/stripe-js';

async function example(_publishableApiKey: any, _connectedAccount: any, _clientSecret: any) {
  const stripe = await loadStripe(_publishableApiKey,{stripeAccount: _connectedAccount});
  console.log(`@dig stripe --> `, stripe);
  console.log(`@dig _clientSecret --> `, _clientSecret);
  const elements = stripe!.elements({clientSecret: _clientSecret});
}


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, OnDestroy, AfterViewInit {
  private componentName: string = `PaymentComponent`;
  @Input() public clientSecret: string;
  @Input() public amount: string;
  public subscriptions: Subscription[] = [];
  @Input() public publishableApiKey: any;
  @Input() public connectedAccount: any;
  @Input() public customerEmail: string;
  @ViewChild('payment-button') paymentButton!: ElementRef;
  public loading: boolean = false;
  paying = false;

  // @ViewChild(StripePaymentElementComponent)
  // paymentElement: StripePaymentElementComponent;
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  public stripe: any;
  public elements: any;
  public paymentElementOptions: any;
  public paymentElement: any;
  public emailAddress: string = `v.rallis@taxaki.com`;
  public errorMessageHidden: boolean = true;
  public errorMessage: string = ``;
  private productionReturnUrl: string = `https://transfer.iconicguest.com/successful-payment/stripe_payment_success_page.html`;
  // private stageReturnUrl: string = `https://booking.dev.dgac.gr/successful-payment/stripe_payment_success_page.html`;


  constructor(
    private websiteWidgetService: WebsiteWidgetService
  ) {
    const functionName: string = `constructor`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/ @Website`);

    // const clientSecret: any = this.testClientSecret;
  }

  ngOnInit(): void {
    const lifecycleName: string = `ngOnInit`;
    const logPath: string = `/${this.componentName}/${lifecycleName}()`;
    console.log(`${logPath}/ @Website - testClientSecret`, this.clientSecret);
    console.log(`${logPath}/ @Website - amount`, this.amount);

    this.setFormLoading(true);

    // this.stripe = Stripe('pk_test_77cINuYVGKKqOmmhbX7nnOAs00H3zRV8dz',{stripeAccount: 'acct_1MvahPGaS4DL199T'});
    this.stripe = Stripe(this.publishableApiKey, {stripeAccount: this.connectedAccount});

    example(this.publishableApiKey, this.connectedAccount, this.clientSecret);

    this.subscriptions.push(
      this.websiteWidgetService.intentClientSecretSubject.subscribe(
        (response: string) => {
          console.log(`${logPath}/ @Website - intentClientSecretSubject - response`, response);
          // this.clientSecret = response;
          // example(this.clientSecret);
          // example(response);
        }, 
        (error: any) => {
          console.log(`${logPath}/ @Website - intentClientSecretSubject - error`, error);
        }
      )
    );

    setTimeout(() => {
      this.setFormLoading(false);
    }, 1500);
  }

  ngOnDestroy(): void {
    const lifecycleName: string = `ngOnDestroy`;
    const logPath: string = `/${this.componentName}/${lifecycleName}()`;
    // console.log(`${logPath}/ @Website`);

    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  ngAfterViewInit() {
    const lifecycleName: string = `ngAfterViewInit`;
    const logPath: string = `/${this.componentName}/${lifecycleName}()`;
    // console.log(`${logPath}/ @Website `);

    const appearance = {
      theme: 'stripe',
      // variables: {
      //   colorPrimary: '#0570de',
      //   colorBackground: '#ffffff',
      //   colorText: '#30313d',
      //   colorDanger: '#df1b41',
      //   fontFamily: 'Ideal Sans, system-ui, sans-serif',
      //   spacingUnit: '2px',
      //   borderRadius: '4px',
      //   // See all possible variables below
      // }
    };
    // const elements = this.stripe.elements({clientSecret: this.testClientSecret, appearance});
    this.elements = this.stripe.elements({clientSecret: this.clientSecret, appearance});

    // const paymentElementOptions = {
    //   // paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna'],
    //   layout: "tabs",
    // };
    this.paymentElementOptions = {
      // paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna'],
      layout: "tabs",
    };

    // const paymentElement = elements.create("payment", paymentElementOptions);
    this.paymentElement = this.elements.create("payment", this.paymentElementOptions);
    this.paymentElement.mount("#payment-element");
  }

  public async onSubmit(_event: any) {
    const functionName: string = `onSubmit`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website emailAddress`, this.emailAddress);

    this.setFormLoading(true);
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: this.productionReturnUrl,
        // return_url: this.stageReturnUrl,
        receipt_email: this.customerEmail,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.showErrorMessage(error.message);
    } else {
      this.showErrorMessage("An unexpected error occurred.");
    }

    this.setFormLoading(false);
  }

  public showErrorMessage(_errorMessage: string) {
    const functionName: string = `showErrorMessage`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website - _errorMessage`, _errorMessage);

    // do smthng
    this.errorMessage = _errorMessage;
    this.errorMessageHidden = false;
  }

  public setFormLoading(_loadingState: boolean) {
    const functionName: string = `setFormLoading`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    console.log(`${logPath}/ @Website - _loadingState`, _loadingState);

    if (_loadingState) {
      this.loading = true;
      // Disable the button and show a spinner
    } else {
      this.loading = false;
    }
  }

}
