import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WebsiteWidgetComponent } from './website-widget.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { TransferFormComponent } from './transfer-form/transfer-form.component';
import { QuoteFormComponent } from './quote-form/quote-form.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { GoogleMapsModule } from '@angular/google-maps';
import { PaymentComponent } from './payment/payment.component';
import { AgmCoreModule } from '@agm/core';
import { ToursAndActivitiesComponent } from './tours-and-activities/tours-and-activities.component';
import { TourCardComponent } from './tours-and-activities/tour-card/tour-card.component';
import { MatIconModule } from '@angular/material/icon';
import { BookTourButtonComponent } from './tours-and-activities/book-tour-button/book-tour-button.component';
import { TitleWithDividerComponent } from './tours-and-activities/title-with-divider/title-with-divider.component';
import { TourPersonFieldsComponent } from './tours-and-activities/book-tour-button/tour-person-fields/tour-person-fields.component';

@NgModule({
  declarations: [
    WebsiteWidgetComponent,
    TransferFormComponent,
    QuoteFormComponent,
    PaymentFormComponent,
    PaymentComponent,
    ToursAndActivitiesComponent,
    TourCardComponent,
    BookTourButtonComponent,
    TitleWithDividerComponent,
    TourPersonFieldsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    NgxIntlTelInputModule,
    GoogleMapsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNLYv7GXmzngHf2Y-er9YovbMLyW7whfA',
      libraries: ['places', 'drawing', 'geometry']
    }),
    MatIconModule
  ],
  exports: [WebsiteWidgetComponent]
})
export class WebsiteWidgetModule { }
