<button mat-raised-button class="blueButton" (click)="onButtonClick()">Book Now</button>


<!-- Dialog template -->
<ng-template #bookTourDialog style="max-width: 100vw!important;">
    <mat-dialog-content [ngClass]="currentStepperIndex ==0 ? 'first-stepper-style' : ''" class="max-height-90vh">

        <mat-stepper [linear]="true" #stepper [disableRipple]="true" (selectionChange)="onStepperChange($event)">
            <mat-step label="Date">

                <!-- =-=-=-=-=-=-= Start of First Stepper =-=-=-=-=-=-= -->

                <h1 matDialogTitle class="dialogTitle">{{tour.title}}</h1>
                <hr>
                <div class="spaceHeight10px"></div>
                <h3 class="subTitle">Choose from the available dates</h3>
                <div class="calendarPicker">

                    <mat-calendar [(selected)]="selectedDate" [minDate]="startDate"></mat-calendar>
                </div>
                <div class="spaceHeight20px"></div>
                <h3 class="subTitle"> Select the number of persons </h3>
                <div class="displayRow">
                    <a class="round-button" [ngClass]="minPersons === personsAmount ? 'disableButton' : ''" (click)="removePersonAmount()">
                        <mat-icon class="marginTop3px">remove icon</mat-icon>
                    </a>
                    <h3 class="personAmount">{{personsTitle}}</h3>
                    <a class="round-button" [ngClass]="maxPersons === personsAmount ? 'disableButton' : ''" (click)="addPersonAmount()">
                        <mat-icon class="marginTop3px">add</mat-icon>
                    </a>
                </div>

                <hr>

                <mat-selection-list class="list-horizontal">
                    <mat-list-item *ngFor="let time of listOfTimes" (click)="onTimeSelect(time)">
                        <div class="timeContainer" [class.selectedTimeContainer]="time === selectedTime">{{time}}</div>

                    </mat-list-item>
                </mat-selection-list>
                <mat-dialog-actions align="end">
                    <button mat-raised-button class="blueButton" (click)="onFirstStepperContinueClick()">Continue</button>
                </mat-dialog-actions>

                <!-- =-=-=-=-=-=-= End of First Stepper =-=-=-=-=-=-= -->

            </mat-step>
            <mat-step label="Activity Details">
                <!-- =-=-=-=-=-=-= Start of Second Stepper =-=-=-=-=-=-= -->

                <div class="second-stepper-content ">
                    <!-- =-=-=-=-=-=-= Left column =-=-=-=-=-=-= -->
                    <div class="scroll-content left-column-percent">
                        <!-- =-=-=-=-=-=-= Booking Details =-=-=-=-=-=-= -->
                        <form class="form">

                            <h2>Booking Details</h2>
                            <div *ngFor="let person of tourPersons; let i = index">

                                <div *ngIf="i==tourPersons.length-1; then thenBlock else elseBlock"></div>
                                <ng-template #thenBlock>
                                    <h4>Additional Information </h4>
                                </ng-template>
                                <ng-template #elseBlock>
                                    <h4>Person {{i+1}}</h4>
                                </ng-template>
                                <app-tour-person-fields></app-tour-person-fields>

                            </div>


                            <mat-form-field class="full-width mat-form-field-black-border" appearance="outline">
                                <mat-label>Notes</mat-label>
                                <textarea style="resize: none;" matInput></textarea>
                            </mat-form-field>
                        </form>

                    </div>
                    <!-- =-=-=-=-=-=-= Right column =-=-=-=-=-=-= -->
                    <div class="right-column-percent">
                        <!-- =-=-=-=-=-=-= Order Summary =-=-=-=-=-=-= -->
                        <div class="show-box order-summary">
                            <div class="order-summary-header">
                                <h2>Order Summary</h2>
                                <button mat-button (click)="onEditClick()">Edit</button>
                            </div>

                            <div class="display-on-row">
                                <div class="display-on-row">
                                    <mat-icon class="icon-style">event</mat-icon>
                                    <p>{{ selectedDate | date:"dd MMM, EE"}}</p>
                                </div>
                                <div class="display-on-row padding-left-20px ">
                                    <mat-icon class="icon-style">schedule</mat-icon>
                                    <p>{{selectedTime}}</p>
                                </div>
                            </div>

                            <div class="display-on-row">

                                <mat-icon class="icon-style">note</mat-icon>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
                                    make a type specimen book.</p>
                            </div>

                            <div class="display-on-row">
                                <div class="display-on-row">
                                    <mat-icon class="icon-style">location_on</mat-icon>
                                    <p>{{tour.title}}</p>
                                </div>
                                <div class="display-on-row padding-left-20px ">
                                    <mat-icon class="icon-style">person</mat-icon>
                                    <p>{{tourPersons.length}}</p>
                                </div>
                            </div>
                        </div>

                        <!-- =-=-=-=-=-=-= Map =-=-=-=-=-=-= -->

                        <div class="tour-map-container show-box margin-top-20px ">
                            <h2 class="map-header">Meeting/Drop off point</h2>
                            <agm-map id="tour-map" [fullscreenControl]='false' [streetViewControl]='false' [mapTypeControl]='false' [fitBounds]="true">
                                <agm-marker *ngFor="let location of tour.locations; let i=index" [latitude]="location.lat" [longitude]="location.long" [agmFitBounds]="true"></agm-marker>
                            </agm-map>
                        </div>

                        <!-- =-=-=-=-=-=-= Payment =-=-=-=-=-=-= -->
                        <div class="show-box margin-top-20px payment-container">
                            <h2>Payment</h2>
                            <mat-radio-group aria-label="Select an option">
                                <div class="full-width">

                                    <mat-radio-button value="cash" class="width-50-percent">Cash</mat-radio-button>
                                    <mat-radio-button value="deposit" class="width-50-percent">Deposit</mat-radio-button>
                                </div>
                                <div class="full-width" style="margin: 12px 0;">

                                    <mat-radio-button value="card" class="width-50-percent">Credit Card</mat-radio-button>
                                    <mat-radio-button value="offerRequest" class="width-50-percent">Offer request</mat-radio-button>
                                </div>
                            </mat-radio-group>
                        </div>

                    </div>


                </div>


                <mat-dialog-actions align="end">
                    <button mat-raised-button class="blueButton" (click)="onSecondStepperContinueClick()">Continue</button>
                </mat-dialog-actions>
                <!-- =-=-=-=-=-=-= End of Second Stepper =-=-=-=-=-=-= -->

            </mat-step>
            <mat-step label="Payments">
                <!-- =-=-=-=-=-=-= Start of Third Stepper =-=-=-=-=-=-= -->

                <div>
                    <h1>3</h1>
                    <mat-dialog-actions align="end">
                        <button mat-raised-button class="blueButton" (click)="onThirdStepperContinueClick()">Continue</button>
                    </mat-dialog-actions>
                </div>
                <!-- =-=-=-=-=-=-= End of Third Stepper =-=-=-=-=-=-= -->

            </mat-step>
        </mat-stepper>
    </mat-dialog-content>

</ng-template>