export class TourPerson {
    name: string;
    lastname: string;
    phone: string;
    email: string;

    constructor({
        name,
        lastname,
        phone,
        email,
    }: {
        name: string;
        lastname: string;
        phone: string;
        email: string;
    }) {
        this.name = name;
        this.lastname = lastname;
        this.phone = phone;
        this.email = email;
    }

    static inti() {
        return new TourPerson({ name: "", lastname: "", phone: "", email: "", });
    }
}