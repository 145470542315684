import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Tours } from '../../models/tours-category.model';
import { MatStepper } from '@angular/material/stepper';
import { TourPerson } from '../../models/tour-person.model';
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-book-tour-button',
  templateUrl: './book-tour-button.component.html',
  styleUrls: ['./book-tour-button.component.css']
})
export class BookTourButtonComponent implements OnInit {

  @Input() tour: Tours;

  //? Einai to id to element
  @ViewChild('bookTourDialog', { static: true }) bookTourDialog: TemplateRef<any>;
  @ViewChild('stepper') private stepper: MatStepper;

  public selectedDate: Date = new Date();
  public startDate: Date = new Date();

  public maxPersons: number = 4;
  public minPersons: number = 1;

  public personsAmount: number = 1;
  public personsTitle: String = "1 person";

  public listOfTimes: String[] = ["11:00", "11:30", "12:00"];
  public selectedTime: String;

  public tourPersons: TourPerson[] = [TourPerson.inti()];

  public currentStepperIndex: number = 0;

  constructor(
    private dialog: MatDialog

  ) {
  }

  ngOnInit(): void {
  }

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, { panelClass: "test" });
  }

  public onButtonClick() {
    this.openDialogWithTemplateRef(this.bookTourDialog);
  }

  setPersonsTitle(amount: number) {
    if (amount == 1) {
      this.personsTitle = "1 person";

    } else {
      this.personsTitle = `${amount} persons`;
    }

  }

  addPersonAmount() {
    this.personsAmount++;
    this.tourPersons.push(TourPerson.inti());
    this.setPersonsTitle(this.personsAmount);
  }

  removePersonAmount() {
    this.personsAmount--;
    this.tourPersons.pop();
    this.setPersonsTitle(this.personsAmount);
  }

  onTimeSelect(time: String) {
    this.selectedTime = time;
  }


  public onFirstStepperContinueClick() {
    this.stepper.next();
  }

  public onSecondStepperContinueClick() {
    this.stepper.next();
  }

  public onThirdStepperContinueClick() {
    this.stepper.next();
  }

  public onEditClick() {
    this.stepper.previous();
  }


  public onStepperChange(_event: any) {
    this.currentStepperIndex = _event.selectedIndex;
  }

}

