<!-- =-=-=-=-=-=-=-=-=-=-=-= currentState != TourState.showToursDetails =-=-=-=-=-=-=-=-=-=-=-= -->
<mat-grid-list *ngIf='currentState != TourState.showToursDetails' cols="{{columnsToDispay}}" rowHeight="2:1" [gutterSize]="'10px'" class="tourGrip" (window:resize)="onResize($event)">

    <div *ngIf='currentState == TourState.showTourCategories'>
        <mat-grid-tile *ngFor="let category of toursCategories" class="tourCard">
            <app-tour-card [value]="category" class="fullWidthAndHeight" (onViewClick)="onViewClick($event)">
            </app-tour-card>
        </mat-grid-tile>
    </div>

    <div>
        <a (click)="onBackClick($event)">
            <div class="displayRow">
                <mat-icon aria-hidden="false" aria-label="Arrow left icon" class="showPointer">keyboard_arrow_left</mat-icon>
                <p class="boldLetters showPointer">Back</p>
            </div>

        </a>

        <div *ngIf='currentState == TourState.showToursGroup'>
            <h1 class="boldLetters">{{selectedToursCategoryTitle}}</h1>
            <mat-grid-tile *ngFor="let category of selectedToursGroup" class="tourCard">
                <app-tour-card [value]="category" class="fullWidthAndHeight" (onViewClick)="onViewClick($event)">
                </app-tour-card>
            </mat-grid-tile>
        </div>

        <div *ngIf='currentState == TourState.showTours'>
            <h1 class="boldLetters">{{selectedToursGroupTitle}}</h1>
            <mat-grid-tile *ngFor="let category of selectedTours" class="tourCard">
                <app-tour-card [value]="category" class="fullWidthAndHeight" (onViewClick)="onViewClick($event)" [buttonText]="'Book'">
                </app-tour-card>
            </mat-grid-tile>
        </div>

    </div>


</mat-grid-list>

<!-- =-=-=-=-=-=-=-=-=-=-=-= currentState == TourState.showToursDetails =-=-=-=-=-=-=-=-=-=-=-= -->
<div *ngIf='currentState == TourState.showToursDetails'>
    <a (click)="onBackClick($event)">
        <div class="displayRow padding10px">
            <mat-icon aria-hidden="false" aria-label="Arrow left icon" class="showPointer">keyboard_arrow_left</mat-icon>
            <p class="boldLetters showPointer">Back</p>
        </div>

    </a>

    <img src={{selectedTourDetails.image}} class="tourDetailsImage">


    <div class="displayRow spaceBetween paddingLeftRight20px ">
        <h1 class="boldLetters margin0">{{selectedTourTitle}}</h1>
        <app-book-tour-button [tour]="selectedTourDetails"></app-book-tour-button>
    </div>

    <div class="spaceHeight20px"></div>

    <div class="paddingLeftRight20px ">

        <p class="fontSize15">Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor
            viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing in.
            Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque consectetur
            vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.</p>

        <div class="spaceHeight10px"></div>

        <!-- =-=-=-=-=-=-=-=-=-= Highlights =-=-=-=-=-=-=-=-=-= -->
        <!-- TODO title kai paragrafos na ginoyn ena componet -->
        <app-title-with-divider title="Highlights"></app-title-with-divider>

        <div class="displayRow   ">
            <p class="width100PerCent"> </p>
            <p class="fontSize15">Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor
                viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing
                in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque
                consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.</p>
        </div>

        <!-- =-=-=-=-=-=-=-=-=-= Included =-=-=-=-=-=-=-=-=-= -->

        <app-title-with-divider title="Included"></app-title-with-divider>

        <div class="displayRow   ">
            <p class="width100PerCent"> </p>
            <p class="fontSize15">Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor
                viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing
                in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque
                consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.</p>
        </div>
        <!-- =-=-=-=-=-=-=-=-=-= Not Included =-=-=-=-=-=-=-=-=-= -->

        <app-title-with-divider title="Not Included"></app-title-with-divider>

        <div class="displayRow   ">
            <p class="width100PerCent"> </p>
            <p class="fontSize15">Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor
                viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing
                in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque
                consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.</p>
        </div>
        <!-- =-=-=-=-=-=-=-=-=-= Options =-=-=-=-=-=-=-=-=-= -->

        <app-title-with-divider title="Options"></app-title-with-divider>

        <div class="displayRow   ">
            <p class="width100PerCent"> </p>
            <p class="fontSize15">Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor
                viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing
                in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque
                consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.</p>
        </div>
        <!-- =-=-=-=-=-=-=-=-=-= Meeting Points =-=-=-=-=-=-=-=-=-= -->

        <app-title-with-divider title="Meeting Points"></app-title-with-divider>

        <div class="displayRow   ">
            <p class="width100PerCent"> </p>
            <p class="fontSize15">Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor
                viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing
                in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque
                consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.</p>
        </div>
        <!-- =-=-=-=-=-=-=-=-=-= Drop Off Points =-=-=-=-=-=-=-=-=-= -->

        <app-title-with-divider title="Drop Off Points"></app-title-with-divider>

        <div class="displayRow   ">
            <p class="width100PerCent"> </p>
            <p class="fontSize15">Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor
                viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing
                in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque
                consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.</p>
        </div>
        <!-- =-=-=-=-=-=-=-=-=-= Additional Information =-=-=-=-=-=-=-=-=-= -->

        <app-title-with-divider title="Additional Information"></app-title-with-divider>

        <div class="displayRow   ">
            <p class="width100PerCent"> </p>
            <p class="fontSize15">Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor
                viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.Lorem ipsum dolor sit amet consectetur. Quis malesuada condimentum adipiscing
                in. Ac nunc dolor in scelerisque elit risus cursus odio accumsan. Suscipit risus et massa eget. Volutpat natoque viverra porttitor sollicitudin cras maecenas porttitor viverra massa. Est dui urna ut sapien. Non quisque mattis a scelerisque
                consectetur vel mauris. Ullamcorper pretium vulputate consectetur faucibus proin vitae.</p>
        </div>

        <!-- =-=-=-=-=-=-=-=-=-= Map =-=-=-=-=-=-=-=-=-= -->

        <div class="tour-map-container">

            <agm-map id="tour-map" [fullscreenControl]='false' [streetViewControl]='false' [mapTypeControl]='false' [fitBounds]="true">
                <agm-marker *ngFor="let location of selectedTourDetails.locations; let i=index" [latitude]="location.lat" [longitude]="location.long" [agmFitBounds]="true"></agm-marker>
            </agm-map>
        </div>

        <!-- =-=-=-=-=-=-=-=-=-= Book Now =-=-=-=-=-=-=-=-=-= -->

        <div class="spaceHeight20px"></div>
        <div class="positionRelative">
            <app-book-tour-button [tour]="selectedTourDetails" class="centerButton"></app-book-tour-button>
        </div>

        <div class="spaceHeight40px"></div>
        <div class="spaceHeight40px"></div>

    </div>