<div class="payment-component" fxLayout="column" fxLayoutAlign="start stretch">

  <div id="payment-element" #paymentButton>.
    <!--Stripe.js injects the Payment Element-->
  </div>
  <div class="button-container" fxLayout="row" fxLayoutAlign="center center">
    <button class="pay-button" (click)="onSubmit($event)" fxLayout="row" fxLayoutAlign="center center" id="submit" [disabled]="loading" [ngClass]="{'pay-button-disabled': loading}">
      <!-- <div class="spinner hidden" id="spinner"></div> -->
      <!-- <span class="button-insight" fxLayout="row" fxLayoutAlign="center center"> -->
        <!-- <div class="spinner hidden" id="spinner"></div> -->

        <mat-spinner [diameter]="40" [strokeWidth]="3" [mode]="'indeterminate'" *ngIf="loading"></mat-spinner>

        <p class="button-text" *ngIf="!loading">Pay {{ amount }}</p>
        <mat-icon class="euro-icon" fxLayout="row" fxLayoutAlign="center center" *ngIf="!loading">euro</mat-icon>
        <p class="button-text" *ngIf="!loading">and book</p>

      <!-- </span> -->
    </button>
    <!-- <div id="payment-message" class="hidden"></div> -->
  </div>
  <div class="error-container" fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="!errorMessageHidden" class="error"> Payment error: {{ errorMessage }}</div>
  </div>

</div>
