/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/03/15
 */

// Angular
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Event as RouterEvent, NavigationStart, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
// Third party
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
// App
import { NotificationRedirectService } from './core/services/notification-redirect.service';
// import { NotificationService } from './pages/notifications/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy, OnInit, AfterViewInit {
  private componentName: string = `AppComponent`;
  public withSidenav: boolean = true;
  public withToolbar: boolean = true;
  private clickListener: () => void;
  private subscriptions: Subscription[] = [];
  public message: any;
  public flag: string;

  public constructor (
    private router: Router, 
    private notificationRedirectService: NotificationRedirectService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private activatedRoute: ActivatedRoute
    // private notificationService: NotificationService
  ) {
    const functionName: string = `constructor`;
    const logPath: string = `/${this.componentName}/${functionName}()`;
    // console.log(`${logPath}/`);
  }

  public ngOnInit() {
    const lifecycleName: string = `ngOnInit`;
    const logPath: string = `/${this.componentName}/${lifecycleName}()`;
    // console.log(`${logPath}/`);

    // this.subscriptions.push(
    //   this.router.events.pipe(
    //     filter((event: RouterEvent) => {
    //       return event instanceof NavigationStart
    //         || event instanceof NavigationEnd
    //       ;
    //     })
    //   ).subscribe((event: RouterEvent) => {
    //     console.log(`${logPath}/ Route event`, event);

    //     if (event instanceof NavigationEnd) {
    //       console.log(`${logPath}/ NavigationEnd.url`, event.url);

    //       if (event.url === '/loading' || event.url === '/login' || event.url === '/login/reset' || event.url === '/login/register' || event.url === '/website-widget') {
    //         this.withSidenav = this.withToolbar = false;
    //       } else {
    //         this.withSidenav = this.withToolbar = true;
    //       }
    //     }
    //   }),
    // );

    // document.addEventListener("visibilitychange", event => {
    //   const functionName = `document.addEventListener`;
    //   const logPath = `/${this.componentName}/${functionName}()`;
    //   console.log(`${logPath}/ @Firebase visibility change`, event);

    //   if (window.localStorage.getItem('redirection') !== null || window.localStorage.getItem('redirection') !== undefined) {
    //     console.log(`${logPath}/ @Firebase redirection value exists in localStorage`);
    //     this.notificationRedirectService.onNotificationClick(window.localStorage.getItem('redirection'));
    //     window.localStorage.removeItem('redirection');
    //   } else {
    //     console.log(`${logPath}/ @Firebase nope`);
    //     window.localStorage.removeItem('redirection');
    //   }
    // });

    // navigator.serviceWorker.onmessage = function(event: MessageEvent) {
    //   const functionName = `navigator.serviceWorker.onmessage`;
    //   const logPath = `/AppComponent/${functionName}()`;
    //   // console.log(`${logPath}/ @Firebase event: `, event);
    //   // console.log(`${logPath}/ @Firebase event.data: `, event.data);
    //   // console.log(`${logPath}/ @Firebase event.source: `, event.source);

    //   window.localStorage.setItem('redirection', event.data);
    // }
  }

  public ngAfterViewInit(): void {
    const lifecycleName: string = `ngAfterViewInit`;
    const logPath: string = `/${this.componentName}/${lifecycleName}()`;
    // console.log(`${logPath}/`);

    this.subscriptions.push(
      // this.notificationService.notificationListStatusSubject.subscribe(
      //   (response: any) => {
      //     if (response.isNotificationListOpen) {
      //       this.notificationService.clickListenerStatusSubject.next(true);
      //       setTimeout(() => {
      //         this.clickListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      //           // console.log(`click: `, event);
      //           this.notificationService.clickListenerActionSubject.next(true);
      //           this.clickListener();
      //         })
      //       }, 250);
      //     }
      //   }
      // )
    );
  }

  public ngOnDestroy(): void {
    const lifecycleName: string = `ngOnDestroy`;
    const logPath: string = `/${this.componentName}/${lifecycleName}()`;
    // console.log(`${logPath}/`);

    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
