import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.sentry.enabled) {
  Sentry.init({
    // dsn: "https://c02a131e906545c5a04a21251c2d521b@o66098.ingest.sentry.io/6179941",
    dsn: environment.sentry.dsn,
    // integrations: [
    //   new BrowserTracing({
    //     tracingOrigins: ["localhost", "https://yourserver.io/api"],
    //     routingInstrumentation: Sentry.routingInstrumentation,
    //   }),
    // ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(module => enableDebugTools(module.injector.get(ApplicationRef).components[0]))
  .catch(err => console.error(err));
