/**
 * @author Filippos A. Grapsas <fgrapsas@dga.gr>
 *
 * Created at 15/03/2022
 */

// Angular
import { Injectable } from '@angular/core';
// Third party
import { instanceToInstance } from 'class-transformer';
import { Subject } from 'rxjs';
// App lib
import { Logger } from '../../lib/logger/logger';
// App core
import { LoginService } from '../login/login.service';
import { Branch, Company, UserInfo } from '../login/user-info.model'

@Injectable({
  providedIn: 'root'
})
export class HotelSwitcherService {
  private readonly log: Logger = Logger.make(
    '/core/hotel-switcher/HotelSwitcherService'
  );
  // private hotel: Hotel | null;
  private branch: Branch | null;
  public getHotelNotificationSubject = new Subject<boolean>();
  // public getHotelSubject: Subject<Hotel> = new Subject<Hotel>();
  public getBranchSubject: Subject<Branch> = new Subject<Branch>();

  public constructor(private loginService: LoginService) {
    this.log.sub('constructor()').debug();
  }

  // public getHotel(): Hotel | null {
  //   const log: Logger = this.log.sub('getHotel()').debug({hotel: this.hotel});

  //   if (this.hotel) {
  //     log.debug('From singleton');
  //     // console.log(`### ${JSON.stringify(this.hotel)}`);
  //     this.getHotelSubject.next(this.hotel);
  //     return instanceToInstance(this.hotel);
  //   }

  //   const userInfo: UserInfo | null = this.loginService.getUserInfo();
  //   if (!userInfo) {
  //     log.debug('UserInfo not found - Cancel');
  //     return null;
  //   }

  //   const storedHotelId: string | null = window.localStorage.getItem('hotelId');
  //   log.debug('From localStorage', {storedHotelId});
  //   if (storedHotelId) {
  //     const hotelId: number = Number.parseInt(storedHotelId);
  //     let hotel: Hotel | undefined;
  //     userInfo.companies.find((company: Company) => {
  //       hotel = company.hotels.find((hotel: Hotel) => {
  //         return hotel.id === hotelId;
  //       });
  //       return hotel ? true : false;
  //     });
  //     if (hotel) {
  //       log.debug('From localStorage');
  //       this.setHotel(hotel, false);
  //       return instanceToInstance(this.hotel);
  //     }
  //   }

  //   if (!userInfo.companies[0].hotels[0]) {
  //     log.debug('No available hotel - Cancel');
  //     return null;
  //   }

  //   log.debug('Use first hotel');
  //   this.setHotel(userInfo?.companies[0].hotels[0], false);
  //   return instanceToInstance(this.hotel);
  // }

  public getBranch(): Branch | null {
    const log: Logger = this.log.sub('getHotel()').debug({hotel: this.branch});

    if (this.branch) {
      log.debug('From singleton');
      // console.log(`### ${JSON.stringify(this.hotel)}`);
      this.getBranchSubject.next(this.branch);
      return instanceToInstance(this.branch);
    }

    const userInfo: UserInfo | null = this.loginService.getUserInfo();
    if (!userInfo) {
      log.debug('UserInfo not found - Cancel');
      return null;
    }

    const storedBranchId: string | null = window.localStorage.getItem('branchId');
    log.debug('From localStorage', {storedBranchId});
    if (storedBranchId) {
      const branchId: number = Number.parseInt(storedBranchId);
      let branch: Branch | undefined;
      userInfo.companies.find((company: Company) => {
        branch = company.branches.find((branch: Branch) => {
          return branch.id === branchId;
        });
        return branch ? true : false;
      });
      if (branch) {
        log.debug('From localStorage');
        this.setBranch(branch, false);
        return instanceToInstance(this.branch);
      }
    }

    if (!userInfo.companies[0].branches[0]) {
      log.debug('No available hotel - Cancel');
      return null;
    }

    log.debug('Use first hotel');
    this.setBranch(userInfo?.companies[0].branches[0], false);
    return instanceToInstance(this.branch);
  }

  // public setHotel(hotel: Hotel, save: boolean = true): void {
  //   this.log.sub('setHotel()').debug({hotel, save});

  //   this.hotel = instanceToInstance(hotel);
  //   save && window.localStorage.setItem('hotelId', hotel.id.toString());
  // }

  public setBranch(branch: Branch, save: boolean = true): void {
    this.log.sub('setBranch()').debug({branch, save});

    this.branch = instanceToInstance(branch);
    // save && window.localStorage.setItem('hotelId', hotel.id.toString());
  }
}
