/**
 *  @author Billy Rallis <v.rallis@taxaki.com>
 *  Created at 2023/01/23
 */

// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
// App
import { LoadingComponent } from './loading.component';
// Third party
import { LottieModule } from 'ngx-lottie';

@NgModule({
  declarations: [
    LoadingComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    LottieModule,
  ],
  exports: [
    LoadingComponent
  ]
})
export class LoadingModule { }